//messages.js
export default {
  general: 'جنرال لواء',
  overview: 'نظرة عامة',
  customizer: 'مخصص',
  applications: 'تطبيقات',
  features: 'الميزات',
  components: 'المكونات',
  salesAndVisitStats: 'المبيعات والزيارات الإحصائيات',
  orderAndProjectStats: 'ترتيب & إحصائيات المشاريع',
  fitnessStats: 'إحصائيات اللياقة البدنية',
  supportAndUsefulWidgets: 'الدعم والحاجيات Useful',
  dashboard: 'لوحة القيادة',
  dashboardOverview: 'نظرة عامة على لوحة المعلومات',
  dashboardv1: 'لوحة القيادة V1',
  dashboardv2: 'لوحة القيادة V2',
  widgets: 'الحاجيات',
  horizontalMenu: 'القائمة الأفقية',
  pages: 'صفحات',
  gallery: 'صالة عرض',
  pricing1: 'التسعير 1',
  pricing2: 'التسعير 2',
  blank: 'فراغ',
  session: 'جلسة',
  signUp1: 'التسجيل 1',
  signUp2: 'تسجيل 2',
  login1: 'تسجيل الدخول 1',
  login2: 'تسجيل الدخول 2',
  lockScreen: 'اقفل الشاشة',
  uiElements: 'عناصر واجهة المستخدم',
  buttons: 'وصفت',
  cards: 'بطاقات',
  checkbox: 'مربع',
  carousel: 'دائري',
  chips: 'رقائق',
  datepicker: 'منتقي التاريخ',
  dialog: 'الحوار',
  grid: 'شبكة',
  input: 'إدخال',
  list: 'قائمة',
  menu: 'قائمة طعام',
  progress: 'تقدم',
  radio: 'راديو',
  select: 'تحديد',
  slider: 'المنزلق',
  snackbar: 'مطعم الوجبات الخفيفة',
  tabs: 'علامات التبويب',
  toolbar: 'شريط الأدوات',
  tooltip: 'تلميح',
  timepicker: 'Timepicker',
  forms: 'إستمارات',
  formValidation: 'التحقق من صحة النموذج',
  stepper: 'السائر',
  charts: 'الرسوم البيانية',
  vueChartjs: 'Vue Chartjs',
  vueEcharts: 'Vue Echarts',
  icons: 'الرموز',
  themify: 'Themify',
  material: 'مواد',
  tables: 'الجداول',
  standard: 'اساسي',
  slots: 'فتحات',
  selectable: 'اختيار',
  searchRow: 'صف البحث',
  maps: 'خرائط',
  googleMaps: 'خرائط جوجل',
  leafletMaps: 'خرائط النشرة',
  jvectorMap: 'خريطة Jvector',
  inbox: 'صندوق الوارد',
  users: 'المستخدمين',
  userProfile: 'ملف تعريفي للمستخدم',
  usersList: 'قائمة المستخدمين',
  calendar: 'التقويم',
  editor: 'محرر',
  quillEditor: 'محرر Quill',
  wYSIWYG: 'WYSIWYG',
  vue2Dragula: 'Vue2 دراغولا',
  dragAndDrop: 'اسحب وإسقاط',
  vueDraggable: 'فيو دراجبل',
  draggableResizeable: 'قابلة لتغيير الحجم',
  invoice: 'دردشة',
  todo: 'لكى يفعل',
  modules: 'وحدات',
  user: 'المستعمل',
  miscellaneous: 'متنوع',
  promo: 'الترويجي',
  connections: 'روابط',
  follow: 'إتبع',
  unfollow: 'الغاء المتابعة',
  basicValidation: 'التحقق الأساسي',
  validationWithSubmitAndclear: 'التحقق من صحة الإرسال وإرساله',
  submit: 'خضع',
  clear: 'واضح',
  step1: 'الخطوة 1',
  step2: 'الخطوة 2',
  step3: 'الخطوه 3',
  continue: 'استمر',
  cancel: 'إلغاء',
  totalEarnings: 'الأرباح الكلية',
  onlineRevenue: 'الإيرادات عبر الإنترنت',
  offlineRevenue: 'العائد غير المتصل بالإنترنت',
  marketingExpenses: 'تكاليف التسويق',
  newCustomers: 'زبائن الجدد',
  visitAndSalesStatistics: 'إحصاءات الزيارة والمبيعات',
  collapse: 'انهدام',
  reload: 'إعادة تحميل',
  close: 'قريب',
  bandwidthUsage: 'استخدام عرض النطاق الترددي',
  shoppingCart: 'عربة التسوق',
  totalDownloads: 'إجمالي التنزيلات',
  productSales: 'مبيعات المنتجات',
  projectStatus: 'حالة المشروع',
  averageSteps: 'متوسط الخطوات',
  todaysDistance: 'المسافة اليوم',
  todaysStep: 'خطوة اليوم',
  todaysGoal: 'هدف اليوم',
  calories: 'سعرات حراريه',
  weeklySummary: 'ملخص أسبوعي',
  supportTickets: 'تذاكر الدعم الفني',
  todoList: 'عمل قائمة',
  newsletterCampaign: 'حملة النشرة الإخبارية',
  currentVisitors: 'الزوار الحاليون',
  newMembers: 'أعضاء جدد',
  addNewBlog: 'إضافة مدونة جديدة',
  add: 'إضافة',
  logOut: 'الخروج',
  totalAppMemory: 'إجمالي ذاكرة التطبيق',
  totalMemoryUsed: 'مجموع الذاكرة المستخدمة',
  unreadMail: '12 بريد غير مقروء',
  feedback: 'ردود الفعل',
  flatButton: 'زر مسطح',
  raisedButton: 'زر أثار',
  gradientButton: 'زر التدرج',
  buttonDropdownVariants: 'متغيرات زر الانحدار',
  buttonToggle: 'زر تبديل',
  icon: 'أيقونة',
  normal: 'عادي',
  disabled: 'معاق',
  floating: 'يطفو على السطح',
  loaders: 'رافعات',
  sizing: 'التحجيم',
  outline: 'الخطوط العريضة',
  round: 'مستدير - كروي',
  block: 'منع',
  search: 'بحث',
  checkboxesBoolean: 'مربعات الاختيار منطقية',
  checkboxesArray: 'Checkboxes Array',
  checkboxesStates: 'خانات - الدول',
  checkboxesColors: 'خانات الاختيار - الألوان',
  switches: 'مفاتيح',
  default: 'افتراضي',
  customTransition: 'انتقال مخصص',
  customDelimiter: 'محدد مخصص',
  hideControls: 'إخفاء عناصر التحكم',
  hideDelimiters: 'إخفاء المحددات',
  colored: 'ملون',
  label: 'ضع الكلمة المناسبة',
  closeable: 'القابلة للإغلاق',
  datePickerHorizontal: 'تاريخ المنتقى أفقي',
  datePickerVertical: 'تاريخ المنتقى عمودي',
  datePickersInDialogAndMenu: 'تاريخ المنتقى ، في الحوار والقائمة',
  datePickersWithModal: 'تاريخ المنتقيين مع مشروط',
  datePickersFormattingDate: 'منتقي التاريخ - تاريخ التنسيق',
  simpleDialogs: 'حوارات بسيطة',
  withoutActivator: 'بدون المنشط',
  scrollable: 'للتمرير',
  listOfAllMaterialIcons: 'قائمة جميع المواد أيقونات',
  arrowsAndDirectionsIcons: 'ARROWS & DIRECTION ICONS',
  webAppIcons: 'WEB APP الرموز',
  CONTROLICONS: 'رموز التحكم',
  TEXTEDITOR: 'محرر النص',
  LAYOUTICONS: 'تأطير الرموز',
  BRANDICONS: 'رموز العلامة التجارية',
  newClients: 'عملاء جدد',
  recurringClients: 'عملاء متكررين',
  bounceRate: 'معدل الارتداد',
  pageViews: 'مشاهدات الصفحة',
  usefulWidgets: 'الحاجيات مفيدة',
  sale: 'تخفيض السعر',
  sales: 'مبيعات',
  invoices: 'الفواتير',
  referrals: 'الدعوات',
  serverLoad: 'تحميل الخادم',
  recentSale: 'بيع حديث',
  supportRequest: 'طلب دعم',
  productSale: 'بيع المنتج',
  viewAll: 'عرض الكل',
  barChart: 'شريط الرسم البياني',
  polarAreaChart: 'مخطط المنطقة القطبية',
  lineChart: 'خط الرسم البياني',
  radarChart: 'مخطط الرادار',
  doughnutChart: 'دونات الرسم البياني',
  bubbleChart: 'مخطط فقاعة',
  gradientLineChart: 'التدرج خط الرسم البياني',
  pieChart: 'مخطط دائري',
  funnelChart: 'مخطط مسار التحويل',
  loginNow: 'تسجيل الدخول الآن',
  createAccount: 'إصنع حساب',
  termsOfService: 'شروط الخدمة',
  bySigningUpYouAgreeTo: 'من خلال التوقيع فإنك توافق على',
  loginToAdmin: 'تسجيل الدخول إلى المسؤول',
  enterUsernameAndPasswordToAccessControlPanelOf:
    'أدخل اسم المستخدم وكلمة المرور للوصول إلى لوحة التحكم من',
  havingAnAccount: 'امتلاك حساب؟',
  quickLinks: 'روابط سريعة',
  plans: 'خطط',
  chooseThePlanThatWorksForYou: 'اختر الخطة التي تناسبك.',
  monthly: 'شهريا',
  yearlyGet2MonthExtra: 'سنوياً (احصل على شهرين إضافيين)',
  basic: 'الأساسية',
  pro: 'طليعة',
  advanced: 'المتقدمة',
  basicFree: 'أساسي (مجاني)',
  startToBasic: 'البدء في الأساسي',
  upgradeToPro: 'التطور للاحترافية',
  upgradeToAdvance: 'الترقية إلى الأمام',
  comparePlans: 'قارن الخطط',
  frequentlyAskedQuestions: 'أسئلة مكررة',
  defaultInput: 'الإدخال الافتراضي',
  activator: 'المنشط',
  hover: 'يحوم',
  menus: 'القوائم',
  indeterminate: 'غير محدد',
  sizeAndWidth: 'الحجم والعرض',
  rotate: 'استدارة',
  determinate: 'حاسم',
  buffer: 'متعادل',
  radiosDefault: 'أجهزة الراديو الافتراضية',
  radiosDirectionRow: 'اتجاه الراديو اتجاه',
  radiosDirectionColumn: 'عمود اتجاه الراديو',
  radiosColors: 'الراديو - الألوان',
  switchesColors: 'مفاتيح - ألوان',
  continuous: 'مستمر',
  discrete: 'منفصله',
  customColors: 'ألوان العرف',
  editableNumericValue: 'قيمة رقمية قابلة للتحرير',
  contextualSnackbar: 'سناكبار السياقية',
  showSnackbar: 'عرض سناكبار',
  centeredTabs: 'علامات التبويب توسيط',
  toolbarTabs: 'علامات تبويب شريط الأدوات',
  theDefaultColorToolbar: 'شريط الأدوات الافتراضي اللون',
  appBar: 'شريط التطبيق',
  appBarWithExtension: 'شريط التطبيق مع ملحق',
  visibility: 'رؤية',
  top: 'أعلى',
  right: 'حق',
  bottom: 'الأسفل',
  left: 'اليسار',
  toggle: 'تبديل',
  timePicker: 'منتقي الوقت',
  timePickerInDialogAndMenu: 'منتقي الوقت - في الحوار والقائمة',
  itemsAndHeaders: 'العناصر والرؤوس',
  selectableRows: 'صفوف للاختيار',
  headerCell: 'خلية العنوان',
  expand: 'وسعت',
  recentInvoice: 'الدردشة الأخيرة',
  previousInvoices: 'الدردشات السابقة',
  addNewItems: 'إضافة عناصر جديدة',
  addToDo: 'أضف الى القيام به',
  yes: 'نعم فعلا',
  ok: 'حسنا',
  activeUsers: 'المستخدمين النشطين',
  addNew: 'اضف جديد',
  readMore: 'اقرأ أكثر',
  assignNow: 'تعيين الآن',
  totalRequest: 'مجموع الطلب',
  new: 'الجديد',
  pending: 'قيد الانتظار',
  update: 'تحديث',
  updated10MinAgo: 'تم التحديث قبل 10 دقيقة',
  addNewCustomer: 'أضف زبون جديد',
  name: 'اسم',
  email: 'البريد الإلكتروني',
  editMember: 'تعديل العضو',
  scheduleDate: 'تاريخ الجدول الزمني',
  title: 'عنوان',
  newEmails: 'رسائل البريد الإلكتروني الجديدة',
  newEmail: 'بريد إلكتروني جديد',
  employeePayroll: 'موظف الرواتب',
  forMostOfTheUsers: 'بالنسبة لمعظم المستخدمين',
  choosePlan: 'اختر الخطة',
  mega: 'ميجا',
  master: 'رئيس',
  forDeveloper: 'للمطور',
  forLargeEnterprises: 'للمؤسسات الكبيرة',
  composeEmail: 'إنشاء البريد الإلكتروني',
  mailboxes: 'صناديق البريد',
  folders: 'المجلدات',
  inbox5: 'صندوق الوارد',
  draft: 'مشروع',
  starred: 'تألق',
  sentMessages: 'الرسائل المرسلة',
  spam: 'بريد مؤذي',
  delete: 'حذف',
  work: 'عمل',
  personal: 'الشخصية',
  manageFolders: 'إدارة المجلدات',
  contacts: 'جهات الاتصال',
  settings: 'إعدادات',
  themeOptions: 'خيارات الموضوع',
  darkMode: 'وضع الظلام',
  collapseSidebar: 'تصغير الشريط الجانبي',
  boxLayout: 'تخطيط مربع',
  rtlLayout: 'تخطيط Rtl',
  backgroundImage: 'الصورة الخلفية',
  sidebarFilters: 'مرشحات الشريط الجانبي',
  danger: 'خطر',
  primary: 'ابتدائي',
  warning: 'تحذير',
  success: 'نجاح',
  info: 'معلومات',
  dailySales: 'المبيعات اليومية',
  trafficChannel: 'قناة المرور',
  campaignPerformance: 'أداء الحملة',
  goToCampaign: 'الذهاب الى الحملة',
  fullScreen: 'تكبير الشاشة',
  ecommerce: 'التجارة الإلكترونية',
  shop: 'متجر',
  cart: 'عربة التسوق',
  total: 'مجموع',
  totalPrice: 'السعر الكلي',
  viewCart: 'عرض العربة',
  checkout: 'الدفع',
  apply: 'تطبيق',
  noItemsFound: 'لم يتم العثور على العناصر',
  billingDetails: 'تفاصيل الفاتورة',
  placeOrder: 'مكان الامر',
  addNewCard: 'أضف بطاقة جديدة',
  devicesShare: 'الأجهزة مشاركة',
  customerOverview: 'نظرة عامة على العملاء',
  reviews: 'التعليقات',
  weeklySales: 'مبيعات أسبوعية',
  recentOrders: 'الطلبيات الأخيرة',
  categorySales: 'مبيعات الفئة',
  webAnalytics: 'تحليلات الويب',
  topSelling: 'الأكثر مبيعا',
  social: 'اجتماعي',
  newPost: 'منشور جديد',
  publish: 'نشر',
  magazine: 'مجلة',
  editUser: 'تحرير العضو',
  addNewUser: 'إضافة مستخدم جديد',
  dark: 'داكن',
  light: 'ضوء',
  recentUsers: 'أحدث المستخدمين',
  activityAroundWorld: 'النشاط حول العالم',
  adsIncome: 'دخل الإعلانات',
  recentComments: 'احدث التعليقات',
  recentNotifications: 'اشعارات حديثة',
  messages: 'رسائل',
  edit: 'تصحيح',
  topAuthors: 'كبار المؤلفين',
  marketingCampaign: 'حملة تسويق',
  articles: 'مقالات',
  websiteTraffic: 'حركة المرور على الموقع',
  agency: 'وكالة',
  recent: 'الأخيرة',
  trending: 'الشائع',
  totalPageViews: 'إجمالي مرات مشاهدة الصفحة',
  impressions: 'الانطباعات',
  deviceSeparations: 'فصل الأجهزة',
  browserStatics: 'إحصائيات المتصفح',
  totalDownloading: 'إجمالي التنزيل',
  notifications: 'إخطارات',
  totalBookmarked: 'مجموع إشارة مرجعية',
  itemsDownloads: 'تنزيلات العناصر',
  itemsUploaded: 'العناصر التي تم تحميلها',
  totalAnalytics: 'مجموع التحليلات',
  hotKeywords: 'كلمات ساخنة',
  MaleUsers: 'المستخدمين الذكور',
  serverStatus: 'حالة الملقم',
  purchaseVuely: 'شراء Vuely',
  letsGetInTouch: 'دعونا الحصول على اتصال',
  maleUsers: 'المستخدمين الذكور',
  femaleUsers: 'المستخدمين من الإناث',
  androidUsers: 'مستخدمو Android',
  iOSUsers: 'مستخدمو iOS',
  advance: 'تقدم',
  routerAnimation: 'الموجه الرسوم المتحركة',
  salesAndEarning: 'المبيعات والربح',
  netProfit: 'صافي الربح',
  totalExpences: 'المصروفات الكلية',
  photos: 'الصور',
  adCampaignPerfomance: 'أداء الحملة الإعلانية',
  profitShare: 'حصة الأرباح',
  topSellingTheme: 'الموضوع الأعلى مبيعًا',
  newRequest: 'طلب جديد',
  followers: 'متابعون',
  mailbox: 'صندوق بريد',
  sent: 'أرسلت',
  birthdays: 'أعياد الميلاد',
  events: 'أحداث',
  newsletter: 'النشرة الإخبارية',
  lastMonth: 'الشهر الماضي',
  allTime: 'كل الوقت',
  composeNewEmail: 'إنشاء بريد إلكتروني جديد',
  activity: 'نشاط',
  message: 'رسالة',
  selectTheme: 'اختر نمطا',
  continueShopping: 'مواصلة التسوق',
  proceedToCheckout: 'باشرالخروج من الفندق',
  headerFilters: 'مرشحات الرأس',
  blog: 'مدونة',
  signUp: 'سجل',
  login: 'تسجيل الدخول',
  news: 'أخبار',
  topHeadlines: 'العناوين العليا',
  visitors: 'الزائرين',
  subscribers: 'مشتركين',
  twitterFeeds: 'خلاصات تويتر',
  extensions: 'ملحقات',
  imageCropper: 'صورة كروبر',
  videoPlayer: 'مشغل فديوهات',
  dropzone: 'Dropzone',
  baseConfig: 'التكوين الأساسي',
  audioTrackAndPlaysInline: 'الصوت المسار والمسرحيات مضمنة',
  hlsLive: 'HLS لايف',
  forgotPassword: 'هل نسيت كلمة المرور',
  resetPassword: 'إعادة ضبط كلمة المرور',
  backToSignIn: 'العودة إلى تسجيل الدخول',
  enterYourEmailToSendYouAResetLink:
    'أدخل البريد الإلكتروني الخاص بك لإرسال لك رابط إعادة تعيين',
  pleaseEnterYourPasswordToReset:
    'يرجى إدخال كلمة المرور الخاصة بك لإعادة الضبط',
  invoice: '請求書',
}
