import { getApiUrl } from '@/clients/_helpers/utils'

const URLs = {
  COMPANIES_BY_FREEE: getApiUrl('companies/freee'),
  COMPANIES_BY_CSV_IMPORT: getApiUrl('companies/import'),
  COMPANIES_CHECK_IMPORT: getApiUrl('companies/import/check'),
  COMPANY_LIST: getApiUrl('api/companylist/'),
  COMPANY: getApiUrl('companies/<company_id>'),
  COMPANIES: getApiUrl('companies'),
  COMPANY_PATTERNS: getApiUrl('company-patterns'),
  COMPANY_PATTERN: getApiUrl('company-patterns/<pattern_id>'),
  COMPANIES_BATCH: getApiUrl('companies/batch'),
  CURRENCY_CODE: getApiUrl('currency-codes'),
  LEARNING_INVOICE: getApiUrl('companies/learning-invoice'),

  FREEE_APPROVAL_TYPE: getApiUrl('freee/approval-type'),
  LEARNING_SETTINGS: getApiUrl('organizations/learning-settings'),
  CUSTOMER_APPROVAL_STAGE: getApiUrl('customers/<customer_id>/approval-stage'),
  INBOX_ITEMS: getApiUrl('api/inboxitems/'),
  INBOX_NEXT_APPROVAL_REQUESTS: getApiUrl(
    'inbox-items/<inbox_id>/next-approval'
  ),
  INVOICE_UPLOAD: getApiUrl('api/invoiceupload/'),
  INVOICE_REPORT: getApiUrl('api/invoicereport/'),
  INVOICE_EMAIL: getApiUrl('invoices/<local_id>/emails:send'),
  PAYMENT_DETAIL: getApiUrl('api/paymentdetail/'),
  PAYMENT_BULK_CHANGE_USER_BANK: getApiUrl('payment-bulk-change-user-bank'),
  RECURRING_INVOICE_SCHEDULES: getApiUrl('recurring-invoice-schedules'),
  RECURRING_INVOICE_SCHEDULE: getApiUrl(
    'recurring-invoice-schedules/<schedule_id>'
  ),
  TRANSFER_FEE: getApiUrl('transfer-fees/<transfer_fee_id>'),
  TRANSFER_FEES: getApiUrl('transfer-fees'),
  USER_BANK: getApiUrl('api/userbankinstance/'),
  INITIAL_SETTINGS: getApiUrl('initial-settings'),
  EMAILS: getApiUrl('emails'),
  EMAIL: getApiUrl('emails/<email_id>'),
  EMAIL_ATTACHMENT_URL: getApiUrl(
    'emails/<email_id>/attachments/<attachment_id>/url'
  ),
  USER_LIST: getApiUrl('api/userlist/'),
  USER_DELETE: getApiUrl('api/userdelete/'),
}

export default Object.freeze(URLs)
