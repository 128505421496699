import primaryTheme from 'Themes/primaryTheme'

// languages
export const languages = [
  {
    name: '日本語',
    icon: 'ja',
    locale: 'ja',
  },
  {
    name: 'English',
    icon: 'en',
    locale: 'en',
  },
  {
    name: 'French',
    icon: 'fr',
    locale: 'fr',
  },
  {
    name: 'Hebrew',
    icon: 'he',
    locale: 'he',
  },
  {
    name: 'Russian',
    icon: 'ru',
    locale: 'ru',
  },
  {
    name: 'Arabic',
    icon: 'ar',
    locale: 'ar',
  },
]

// sidebar background images
export const sidebarBackgroundImages = [
  {
    id: 1,
    url: '/static/img/sidebar-1.jpg',
  },
  {
    id: 2,
    url: '/static/img/sidebar-2.jpg',
  },
  {
    id: 3,
    url: '/static/img/sidebar-3.jpg',
  },
  {
    id: 4,
    url: '/static/img/sidebar-4.jpg',
  },
]

// sidebar filters
export const sidebarFilters = [
  {
    id: 1,
    class: 'sidebar-overlay-dark',
    name: 'message.dark',
  },
  {
    id: 2,
    class: 'sidebar-overlay-light',
    name: 'message.light',
  },
  {
    id: 3,
    class: 'sidebar-overlay-pink',
    name: 'message.danger',
  },
  {
    id: 4,
    class: 'sidebar-overlay-primary',
    name: 'message.primary',
  },
  {
    id: 5,
    class: 'sidebar-overlay-warning',
    name: 'message.warning',
  },
  {
    id: 6,
    class: 'sidebar-overlay-success',
    name: 'message.success',
  },
  {
    id: 7,
    class: 'sidebar-overlay-purple',
    name: 'message.info',
  },
]

// router animations
export const routerAnimations = [
  'fadeIn',
  'slideInUp',
  'slideInRight',
  'bounce',
  'lightSpeedIn',
  'pulse',
]

// themes
export const themes = [
  {
    id: 1,
    bgColor: 'bg-primary',
    theme: primaryTheme,
  },
]

// header filter
export const headerFilters = [
  {
    id: 1,
    class: 'primary',
  },
]
