import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class PaymentBulkChangeUserBankAPI extends APIClient {
  async put(token, data) {
    const payload = {
      data,
      url: URLs.PAYMENT_BULK_CHANGE_USER_BANK,
      method: 'PUT',
      headers: {
        Authorization: token,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(response.data.error)
      }
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }
}
export default new PaymentBulkChangeUserBankAPI()
