export function findCode(name, items) {
  try {
    if (!items) {
      return ''
    }
    const findObj = items.find((obj) => obj.name == name)
    return findObj ? findObj.code : ''
  } catch (err) {
    console.log(err)
    return ''
  }
}

export function findName(code, items) {
  try {
    if (!items) {
      return ''
    }
    const findObj = items.find((obj) => obj.code == code)
    return findObj ? findObj.name : ''
  } catch (err) {
    console.log(err)
    return ''
  }
}
