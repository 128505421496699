<template>
  <v-list>
    <v-list-item>
      <v-list-item-action style="min-width: 20px">
        <v-menu
          offset-x
          origin="right bottom"
          z-index="99"
          transition="slide-x-transition"
          nudge-right="30"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <s-icon icon="feather-user" size="2xl" />
            </span>
          </template>

          <v-list dense>
            <v-list-item
              v-for="x in setting"
              :key="x.title"
              @click="goToAccount"
            >
              <i class="mr-3 zmdi" :class="x.icon" />
              <span class="font-sm">{{ $t(x.title) }}</span>
            </v-list-item>
            <v-list-item v-for="x in logoff" :key="x.title" @click="logout">
              <i class="mr-3 zmdi" :class="x.icon" />
              <span class="font-sm">{{ $t(x.title) }}</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>
      <v-list-item-content>
        <v-menu
          offset-x
          origin="right bottom"
          z-index="99"
          transition="slide-x-transition"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <span class="pt-2 no-wrap" v-bind="attrs" v-on="on">
              ユーザ情報
            </span>
          </template>

          <v-list dense>
            <v-list-item
              v-for="x in setting"
              :key="x.title"
              @click="goToAccount"
            >
              <i class="mr-3 zmdi" :class="x.icon" />
              <span>{{ $t(x.title) }}</span>
            </v-list-item>
            <v-list-item v-for="x in logoff" :key="x.title" @click="logout">
              <i class="mr-3 zmdi" :class="x.icon" />
              <span>{{ $t(x.title) }}</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import Axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'

export default {
  data() {
    return {
      loading: false,
      hoverOrgs: false,
      setting: [
        {
          title: 'message.userData',
          icon: 'feather-user blue--text',
        },
      ],
      logoff: [
        {
          title: 'message.logOut',
          icon: 'feather-log-out red--text',
        },
      ],
      userOrganizations: [],
    }
  },
  computed: {
    companyName: {
      get() {
        this.$store.getters.getCompanyName
        return this.$store.state.user.companyName
      },
      set() {
        return this.$store.getters.getOrganizationName
      },
    },
  },
  mounted() {
    this.getOrganizations()
  },
  methods: {
    goToAccount() {
      this.$router.push('/account')
    },
    logout() {
      this.$store.dispatch('logOutUser')
    },
    signin(user_organization_id) {
      try {
        if (user_organization_id) {
          this.loading = true
          var apiUrl = this.$store.getters.apiSignInAnother
          var token = this.$store.getters.getAuthToken
          var envtype = this.$store.getters.getEnvType

          this.$store
            .dispatch('apiSignInAnother', {
              user_organization_id,
              envtype,
              apiUrl,
              token,
            })
            .then((response) => {
              if (response.data.error) {
                Snackbar.error(response.data.error)
              }
              this.loading = false
            })
        }
      } catch (e) {
        console.log(e)
      }
    },
    getOrganizations() {
      var url = this.$store.getters.apiGetUserOrganizations
      var token = this.$store.getters.getAuthToken
      var user_id = this.$store.getters.getUserID
      var user_organization_id = this.$store.getters.getUserOrganizationID

      var data = {
        user_id: user_id,
        user_organization_id: user_organization_id,
      }

      Axios.get(url, {
        headers: { Authorization: token },
        params: data,
      }).then((response) => {
        if (response.data.status == 'success') {
          this.userOrganizations = response.data.data
        } else {
          Snackbar.error(response.data.error)
        }
      })
    },
  },
}
</script>
