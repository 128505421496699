import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class InboxNextApprovalAPI extends APIClient {
  async get(token, params) {
    const payload = {
      url: URLs.INBOX_NEXT_APPROVAL_REQUESTS,
      method: 'GET',
      args: {
        inbox_id: params.inboxItemId,
      },
      headers: {
        Authorization: token,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(response.data.error)
      }
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }

  async put(token, params) {
    const payload = {
      url: URLs.INBOX_NEXT_APPROVAL_REQUESTS,
      method: 'PUT',
      args: {
        inbox_id: params.inboxItemId,
      },
      data: {
        approval_type: params.approvalType,
        max_approval_stage: params.maxApprovalStage,
        next_approve_users: params.nextApproveUsers,
      },
      headers: {
        Authorization: token,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(response.data.error)
      }
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }
}
export default new InboxNextApprovalAPI()
