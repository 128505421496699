import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class InitialSettingsAPI extends APIClient {
  async post(token, params) {
    const payload = {
      url: URLs.INITIAL_SETTINGS,
      method: 'POST',
      data: {
        setting_journal: params.setting_journal,
        setting_pay_journal: params.setting_pay_journal,
        setting_paid_by_customer: params.setting_paid_by_customer,
        setting_avoid_bank_holiday: params.setting_avoid_bank_holiday,
        setting_foreign_currency: params.setting_foreign_currency,
        approve_level: params.approve_level,
        pay_confirm_level: params.pay_confirm_level,
        send_approval_request: params.send_approval_request,
        setting: params.setting,
        definition: params.definition,
        journal_rules: params.journal_rules,
        setting_pay_day: params.setting_pay_day,
        setting_pay_month: params.setting_pay_month,
        user_bank_list: params.user_bank_list,
      },
      headers: {
        Authorization: token,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }
}
export default new InitialSettingsAPI()
