import Vue from 'vue'
import Nprogress from 'nprogress'
import router from '../../../router'
import axios from 'axios'
import Axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'
import UserBankAPI from '@/clients/user_bank/user_bank'
import {
  getUserBankFullText,
  getUserBankSimpleText,
} from '@/helpers/account/index'

const state = {}

const getters = {}

const actions = {
  editSession(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl
    const auth_token = data.auth_token
    return new Promise((resolve, reject) => {
      axios
        .post(
          apiUrl,
          {
            user_id: data.user_id,
            full_name: data.full_name,
            name: data.company_name,
            definition: data.definition,
            exclude_company_name: data.exclude_company_name,
            address_list: data.address_list,
            contact_number_list: data.contact_number_list,
            settlement_day: data.settlement_day,
            settlement_month: data.settlement_month,
            industry_id: data.industry_id,
            organization_id: data.organization_id,
            type: data.type,
          },
          {
            headers: { Authorization: auth_token },
          }
        )
        .then(function (response) {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          }
          resolve(response)
        })
        .catch(function (error) {})
    })
  },

  UpdateUserDefaultJournal(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl
    const auth_token = data.auth_token
    return new Promise((resolve, reject) => {
      Axios.post(
        apiUrl,
        {
          organization_id: data.organization_id,
          invoice_journal: data.invoice_journal,
          payment_journal: data.payment_journal,
          payment_journal_setting: data.payment_journal_setting,
          gensen_journal: data.gensen_journal,
          gensen_journal_setting: data.gensen_journal_setting,
          setting_journal: data.setting_journal,
          setting_journal_lock_mode: data.setting_journal_lock_mode,
          setting_pay_journal: data.setting_pay_journal,
          setting_paid_by_customer: data.setting_paid_by_customer,
          setting_avoid_bank_holidays: data.setting_avoid_bank_holidays,
          setting_foreign_currency: data.setting_foreign_currency,
          setting_pay_day: data.setting_pay_day,
          setting_pay_month: data.setting_pay_month,
          type: data.type,
        },
        {
          headers: { Authorization: auth_token },
        }
      )
        .then(function (response) {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          }
          resolve(response)
        })
        .catch(function (error) {})
    })
  },

  async getUserBankList(context) {
    const token = context.getters.getAuthToken
    const data = await UserBankAPI.get(token)
    let user_bank_list = data.user_bank_list
    user_bank_list.forEach((item) => {
      item.fulltext = getUserBankFullText(item)
      item.simpletext = getUserBankSimpleText(item)
    })
    return user_bank_list
  },

  async createUserBank(context, payload) {
    const token = context.getters.getAuthToken
    const data = await UserBankAPI.post(token, {
      bank_name: payload.bank_name,
      bank_branch_name: payload.bank_branch_name,
      bank_account_type: payload.bank_account_type,
      bank_account_number: payload.bank_account_number,
      bank_account_name: payload.bank_account_name,
      user_code: payload.user_code,
      default: payload.default,
    })
    return data
  },

  async updateUserBank(context, payload) {
    const token = context.getters.getAuthToken
    const data = await UserBankAPI.put(token, {
      id: payload.id,
      bank_name: payload.bank_name,
      bank_branch_name: payload.bank_branch_name,
      bank_account_type: payload.bank_account_type,
      bank_account_number: payload.bank_account_number,
      bank_account_name: payload.bank_account_name,
      user_code: payload.user_code,
      default: payload.default,
    })
    return data
  },

  async deleteUserBank(context, payload) {
    const token = context.getters.getAuthToken
    const data = await UserBankAPI.delete(token, {
      id: payload.id,
    })
    return data
  },

  sessionResetPassword(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl
    const auth_token = data.auth_token
    return new Promise((resolve, reject) => {
      axios
        .post(
          apiUrl,
          {
            current_password: data.current_password,
            new_password: data.new_password,
            confirm_new_password: data.confirm_new_password,
          },
          {
            headers: { Authorization: auth_token },
          }
        )
        .then(function (response) {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          }
          resolve(response)
        })
        .catch(function (error) {})
    })
  },
}
export default {
  state,
  getters,
  actions,
}
