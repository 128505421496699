import Vue from 'vue'
import Nprogress from 'nprogress'
import router from '../../../router'
import axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'

function errorNotify(msg) {
  Snackbar.error(msg)
}

const state = {}

const getters = {}

const actions = {
  deleteDepartment(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl
    const auth_token = data.auth_token
    return new Promise((resolve, reject) => {
      axios
        .delete(apiUrl, {
          data: { id: data.id },
          headers: { Authorization: auth_token },
        })
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  editDepartment(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl
    const auth_token = data.auth_token
    return new Promise((resolve, reject) => {
      axios
        .put(
          apiUrl,
          {
            id: data.id,
            name: data.name,
            user_organization_id: data.user_organization_id,
            organization_id: context.getters.getOrganizationID,
            code: data.code,
            status: data.status,
          },
          {
            headers: { Authorization: auth_token },
          }
        )
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  addDepartment(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl
    const auth_token = data.auth_token
    return new Promise((resolve, reject) => {
      axios
        .post(
          apiUrl,
          {
            name: data.name,
            user_organization_id: data.user_organization_id,
            organization_id: context.getters.getOrganizationID,
            code: data.code,
            status: data.status,
          },
          {
            headers: { Authorization: auth_token },
          }
        )
        .then(function (response) {
          if ('error' in response.data) {
            // errorNotify(response.data.error)
            resolve(response)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
}
export default {
  state,
  getters,
  actions,
}
