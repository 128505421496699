/**
 * Helpers Functions
 */
import axios from 'axios'
import moment from 'moment'
import { store } from '../store/store'
import jaconv from 'jaconv'
import Snackbar from 'Helpers/snackbar/index'
import { checkPrivilege } from 'Helpers/role/index'

// Get Date
export function getTheDate(timestamp, format) {
  let time = timestamp * 1000
  let formatDate = format ? format : 'MM-DD-YYYY'
  return moment(time).format(formatDate)
}

// Convert Date To Timestamp
export function convertDateToTimeStamp(date, format) {
  let formatDate = format ? format : 'YYYY-MM-DD'
  return moment(date, formatDate).unix()
}

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
  if (length == null) {
    length = 100
  }
  if (ending == null) {
    ending = '...'
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  } else {
    return str
  }
}

/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      alpha +
      ')'
    )
  }
  throw new Error('Bad Hex')
}

/**
 * Function to return currenr app layout
 */
export function getCurrentAppLayout(router) {
  let location = router.history.current.fullPath
  let path = location.split('/')
  return path[1]
}

// Clear local storage and cookie
export function clearSession() {
  localStorage.removeItem('user')
  var cookie_name = 'token_' + process.env.VUE_APP_ENV_TYPE
  document.cookie = cookie_name + '=1;domain=.sweeep.ai;path=/'
}

// Get local storage session
export function getLocalStorage() {
  try {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token
    let tokenData = parseJwt(token)
    var current_time = new Date().getTime() / 1000
    if (current_time > tokenData.exp) {
      return false
    }
  } catch (err) {
    return false
  }
  return true
}

// Get cookie storage session
export function getCookieStorage() {
  try {
    var cookie_name = 'token_' + process.env.VUE_APP_ENV_TYPE
    let mycookie = getCookie(cookie_name)
    localStorage.setItem('user', mycookie)
    let user = JSON.parse(localStorage.getItem('user'))
    let token = user.token
    let tokenData = parseJwt(token)
    var current_time = new Date().getTime() / 1000
    if (current_time > tokenData.exp) {
      clearSession()
      return false
    }
  } catch (err) {
    return false
  }
  return true
}

// Parse JWT

function b64DecodeUnicode(str) {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}

export function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}

// Get cookie
export function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }

  return ''
}

export function checkEnvironment(next) {
  let env_storage = store.getters.getUserEnvType
  let env_sys = store.getters.getEnvType
  next()
}
// Check authorization
export function checkAuthorization(next) {
  var adminUrls = [
    '/mycompany',
    '/user',
    '/ip-restriction',
    '/email-capture',
    '/freee',
  ]
  if (window.location.hostname != process.env.VUE_APP_SUBDOMAIN_APP) {
    next({ name: 'pagenotfound' })
  } else {
    if (!getLocalStorage() && !getCookieStorage()) {
      var nextUrl = window.location.pathname + window.location.search
      window.location.href =
        process.env.VUE_APP_SUBDOMAIN_ACCOUNTS_FULL +
        '/session/login?next=' +
        encodeURIComponent(nextUrl)
    }
  }
  if (adminUrls.includes(window.location.pathname) == true) {
    setTimeout(function () {
      const admin = checkPrivilege('settings:read')
      if (!admin) {
        // Not admin
        window.location.href =
          process.env.VUE_APP_SUBDOMAIN_APP_FULL + '/invoice'
      }
    }, 1000)
  }
}

export function checkCompanySettingsRoute(next, routeName) {
  let data = {}
  let url = store.getters.apiUserSessionFunction
  const auth_token = store.getters.getAuthToken
  url += '?user_organization_id=' + store.getters.getUserOrganizationID
  axios
    .get(url, {
      headers: {
        Authorization: auth_token,
      },
    })
    .then((response) => {
      if (response.data.status == 'success') {
        data = response.data.data.organization
        if (routeName == 'segment') {
          if (data.settings_segment) {
            next()
          } else {
            window.location.href =
              process.env.VUE_APP_SUBDOMAIN_APP_FULL + '/invoice'
          }
        }
        if (routeName == 'subaccount') {
          if (data.settings_subaccount) {
            next()
          } else {
            window.location.href =
              process.env.VUE_APP_SUBDOMAIN_APP_FULL + '/invoice'
          }
        }
        if (routeName == 'department') {
          if (data.settings_department) {
            next()
          } else {
            window.location.href =
              process.env.VUE_APP_SUBDOMAIN_APP_FULL + '/invoice'
          }
        }
        if (routeName == 'vendor') {
          if (data.settings_vendor) {
            next()
          } else {
            window.location.href =
              process.env.VUE_APP_SUBDOMAIN_APP_FULL + '/invoice'
          }
        }
        if (routeName == 'project') {
          if (data.settings_project) {
            next()
          } else {
            window.location.href =
              process.env.VUE_APP_SUBDOMAIN_APP_FULL + '/invoice'
          }
        }
      } else {
        Snackbar.error(response.data.error)
      }
    })
}

export function checkIp(next) {
  try {
    store.dispatch('action_initialiseStore', {}).then((response) => {
      if (response == 'success') {
        let url = store.getters.apiGetRestrictedIpUrl
        url += '?organization_id=' + store.getters.getOrganizationID
        axios
          .get(url, {
            headers: { Authorization: store.getters.getAuthToken },
          })
          .then((response) => {
            var ipList = response.data.data
            var ipResponse = response.data
            if (!response.data.db_status) {
              if (ipList !== undefined) {
                var apiUrl = store.getters.apiGetIpUrl
                axios
                  .get(apiUrl, {
                    headers: { Authorization: store.getters.getAuthToken },
                  })
                  .then((response) => {
                    if (ipList.includes(response.data.ip)) {
                      next()
                    } else if (
                      ipResponse.ip_admin.includes(response.data.ip) &&
                      ipResponse.user_status === 3
                    ) {
                      next()
                    } else {
                      axios
                        .put(
                          store.getters.apiIpRestrictionLogInstanceUrl,
                          {
                            ip_address: response.data.ip,
                            user_organization_id:
                              store.getters.getUserOrganizationID,
                          },
                          {
                            headers: {
                              Authorization: store.getters.getAuthToken,
                            },
                          }
                        )
                        .then(function (response) {
                          if ('error' in response.data) {
                            console.log(response.data.error)
                          }
                        })
                      next({ name: 'noaccess' })
                    }
                  })
              } else {
                checkEnvironment(next)
                next()
              }
            }
          })
      }
    })
  } catch (err) {
    console.log(err)
  }
}

export function sleep(milliseconds) {
  var start = new Date().getTime()
  for (var i = 0; i < 1e7; i++) {
    if (new Date().getTime() - start > milliseconds) {
      break
    }
  }
}

export function errorNotify(txt) {
  Snackbar.error(txt)
}

export function successNotify(txt) {
  Snackbar.success(txt)
}

export function createNotification(detail, type) {
  /*
   type{
     models.py Notification
     notification_type　を参照してください
   }
   */
  try {
    return new Promise((resolve) => {
      let apiUrl = store.getters.apiNotificationUrl
      axios
        .post(
          apiUrl,
          {
            notificationType: type,
            notificationDetail: detail,
          },
          { headers: { Authorization: store.getters.getAuthToken } }
        )
        .then((response) => {
          resolve(response)
        })
    })
  } catch (e) {
    console.log(e)
  }
}

export function checkInclude(text1, text2) {
  const checkTextIsInvalid = (text) => !text && text !== 0
  if (checkTextIsInvalid(text1) || checkTextIsInvalid(text2)) {
    return false
  }
  text1 = text1.toString()
  return jaconv
    .toHanAscii(jaconv.toHanKana(jaconv.toKatakana(text1.toLowerCase())))
    .includes(
      jaconv.toHanAscii(
        jaconv.toHanKana(jaconv.toKatakana(text2.toLowerCase()))
      )
    )
}

export function chunkArray(array, batchSize) {
  const chunkedArray = []
  for (let i = 0; i < array.length; i++) {
    const last = chunkedArray[chunkedArray.length - 1]
    if (!last || last.length === batchSize) {
      chunkedArray.push([array[i]])
    } else {
      last.push(array[i])
    }
  }
  return chunkedArray
}

export function quoteCommaAndNewline(text) {
  if (text.match(',') || text.match(/\r?\n/)) {
    return `"${text}"`
  } else {
    return text
  }
}
export function splitLine(line) {
  let line_split = line.split('')
  let in_duble_quates = false
  for (var i = 0; i < line_split.length; i++) {
    if (line_split[i] === '"') {
      line_split[i] = ''
      in_duble_quates = !in_duble_quates
    }
    if (line_split[i] === ',' && !in_duble_quates) {
      line_split[i] = '\n'
    }
  }
  return line_split.join('').split('\n')
}

export function readCSV(payload) {
  var request = new XMLHttpRequest()
  request.open('GET', payload.base64, false)
  request.overrideMimeType('text/plain; charset=Shift_JIS')
  request.send(null)
  var csvData = new Array()
  var jsonObject = request.responseText.split(/\r?\n|\r/)
  for (var i = 0; i < jsonObject.length; i++) {
    if (jsonObject[i].includes(',')) {
      csvData.push(splitLine(jsonObject[i]))
    }
  }
  return csvData
}

export function consoleLog(title, message, startDate = null) {
  try {
    const now = new Date()
    return now
    const Y = now.getFullYear()
    const M = now.getMonth() + 1
    const D = now.getDate()
    const h = ('0' + now.getHours()).slice(-2)
    const m = ('0' + now.getMinutes()).slice(-2)
    const s = ('0' + now.getSeconds()).slice(-2)
    const date = Y + '/' + M + '/' + D + ' ' + h + ':' + m + ':' + s

    let logMessage = '[' + date + '] ' + '[' + title + '] ' + message

    if (startDate) {
      var diffSeconds = (now.getTime() - startDate.getTime()) / 1000
      logMessage = logMessage + ' ' + diffSeconds + 's'
    }
    console.log(logMessage)
    return now
  } catch (e) {
    console.log(e)
  }
}

export function deepCopy(object) {
  return JSON.parse(JSON.stringify(object))
}

export function toggleZendesk() {
  function turnOnZendesk() {
    window.zEmbed.show()
  }
  function turnOffZendesk() {
    window.zEmbed.hide()
  }
  function checkEnableZendesk() {
    const isPreLoginPage =
      window.location.origin === process.env.VUE_APP_SUBDOMAIN_ACCOUNTS_FULL

    const disableZendeskRoutes = [
      /\/tempupload\/.+/,
      /\/pdf\/.+\/.+/,
      '/initial-settings',
    ]
    const currentPath = window.location.pathname
    const isDisablePage = disableZendeskRoutes.some((route) =>
      currentPath.match(route)
    )

    return !(isPreLoginPage || isDisablePage)
  }

  // ref https://developer.zendesk.com/embeddables/docs/widget/api
  // zE is golobal various
  zE(() => {
    const enable = checkEnableZendesk()
    if (enable) {
      turnOnZendesk()
    } else {
      turnOffZendesk()
    }
  })
}

export function isMapObject(value) {
  return value instanceof Object && !(value instanceof Array)
}

export function escapeText(text) {
  return text.replace(/[<>&"'`]/g, (match) => {
    const escape = {
      '<': '&lt',
      '>': '&gt',
      '&': '&amp',
      '"': '&quot',
      "'": '&#39',
      '`': '&#x60',
    }
    return escape[match]
  })
}

export function surroundUrlByTag(text) {
  return text.replace(
    /(http(s)?:\/\/[a-zA-Z0-9-.!'()*;/?:@&=+$,%#_]+)/gi,
    "<a href='$1' target='_blank' style='width:100%;'>$1</a>"
  )
}

export class InputStateDiscriminator {
  constructor() {
    this.queueCount = 0
  }
  input(text) {
    const timeout = 300
    return new Promise((resolve) => {
      this.queueCount++
      setTimeout(() => {
        this.queueCount--
        if (this.queueCount === 0) {
          resolve({ state: 'done', text })
        } else {
          resolve({ state: 'inputting', text })
        }
      }, timeout)
    })
  }
}

export const getDecimal = (numString) => {
  const decimal = (numString || '').split('.')[1]
  return (decimal && decimal.length) || 0
}
