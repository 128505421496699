import { confirmDialogBuilder } from 'Helpers/confirm/factory'
import InputDeleteDialog from 'Components/Dialog/InputDeleteDialog'

let promise = null
const confirm = async (props) => {
  //click:outside で falseが 返り 新規のダイアログが作成される
  if (promise) {
    await promise
  }
  promise = confirmDialogBuilder.create(props)
  const payload = await promise
  return payload
}

confirm.deleteWithInput = async (props) => {
  const defaultProps = {
    cancelText: 'キャンセル',
    deleteText: '削除',
  }
  if (promise) {
    await promise
  }
  promise = confirmDialogBuilder.create(
    { ...defaultProps, ...props },
    InputDeleteDialog
  )
  const payload = await promise
  return payload
}

export default confirm
