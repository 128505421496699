<template>
  <v-dialog v-model="dialog" :max-width="maxWidth">
    <v-card class="sweeep-dialog">
      <v-card-title v-text="title" />
      <v-card-text>
        <slot name="card-text">
          {{ message }}
          <br />
          {{ computedDeleteMessage }}
          <br />
        </slot>
        <v-text-field
          v-model="inputDelete"
          regular
          hide-details
          placeholder="DELETE"
          class="mt-2"
          tabindex=""
          dense
          outlined
          filled
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="loading"
          @click="
            cancel()
            dialog = false
          "
          v-text="cancelText"
        />
        <v-btn
          color="primary"
          :loading="loading"
          v-bind="{ disabled: inputDelete != 'DELETE' }"
          @click="submit()"
          v-text="deleteText"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: 'cancel',
    },
    deleteText: {
      type: String,
      default: 'delete',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: '650',
    },
    closeOnCancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      inputDelete: '',
    }
  },
  computed: {
    computedDeleteMessage: function () {
      return (
        'よい場合はDELETEと入力し、' +
        this.deleteText +
        'をクリックして下さい。'
      )
    },
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    cancel() {
      if (this.closeOnCancel) {
        this.inputDelete = ''
        this.close()
      }
      this.$emit('cancel')
    },
    submit() {
      this.inputDelete = ''
      this.$emit('submit')
    },
  },
}
</script>
