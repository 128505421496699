import Full from 'Container/Full'

// component
const Account = () => import('Views/account/Account')

// helpers
import { checkAuthorization, checkIp } from '../helpers/helpers.js'

export default {
  path: '/account',
  component: Full,
  meta: { requiresAuth: true },
  beforeEnter(to, from, next) {
    checkAuthorization(next)
    checkIp(next)
    next()
  },
  children: [
    {
      path: '',
      component: Account,
      meta: { title: 'ユーザ情報' },
    },
  ],
}
