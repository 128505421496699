import Full from 'Container/Full'

// component
const Application = () => import('Views/settings/Application')
const ApplicationAuthorize = () => import('Views/settings/ApplicationAuthorize')
const Settings = () => import('Views/settings/Settings')

// helpers
import { checkAuthorization, checkIp } from '../helpers/helpers.js'

export const applicationRoutes = {
  path: '/application',
  component: Full,
  meta: { requiresAuth: true },
  beforeEnter(to, from, next) {
    checkAuthorization(next)
    checkIp(next)
    next()
  },
  children: [
    {
      path: '',
      component: Settings,
      children: [
        {
          path: '',
          component: Application,
        },
      ],
    },
  ],
}

export const appAuthorizeRoutes = {
  // version
  path: '/oauth/v:id',
  component: Full,
  meta: { requiresAuth: true },
  beforeEnter(to, from, next) {
    checkAuthorization(next)
    checkIp(next)
    next()
  },
  children: [
    {
      path: 'authorize',
      component: ApplicationAuthorize,
    },
  ],
}
