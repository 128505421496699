<template>
  <base-dialog ref="dialog" title="注意" @click:outside="cancel()">
    <template #card-text>
      <p>{{ message }}</p>
      <div v-show="unapprovedInvoiceLocalIds.length > 0">
        以下の伝票No.の請求書は仕訳未承認です。<br />
        <span
          v-for="id in unapprovedInvoiceLocalIds"
          :key="`unapproved_invoice_${id}`"
        >
          <s-chip class="mt-2 mr-2"> ＃{{ id }} </s-chip>
        </span>
      </div>
    </template>
    <template #card-actions>
      <v-spacer />
      <v-btn class="mr-4" @click="cancel()"> キャンセル </v-btn>
      <v-btn
        v-if="!hideBtn"
        v-tooltip="'仕訳承認済み請求書データのみエクスポートを行います。'"
        color="primary"
        @click="submitApproved()"
      >
        承認済データ
      </v-btn>
      <v-btn color="primary" @click="submitAll()">
        {{ hideBtn ? 'エクスポート' : '全て' }}
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    unapprovedInvoiceLocalIds: {
      type: Array,
      default() {
        return []
      },
    },
    hideBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    open() {
      this.$refs.dialog.open()
    },
    cancel() {
      this.$emit('cancel')
    },
    submitAll() {
      this.$emit('submit:all')
      this.close()
    },
    submitApproved() {
      this.$emit('submit:approved')
      this.close()
    },
    close() {
      this.$nextTick(() => {
        this.$refs.dialog.close()
      })
    },
  },
}
</script>
