<template>
  <div style="display: flex">
    <template>
      <journal-chip :text="chip" :tooltip="tooltip" :editable="editable" />
    </template>
    <v-autocomplete
      v-if="editable"
      ref="input"
      v-model="item"
      v-tooltip="autoCompleteTooltip"
      :items="itemsToShow"
      dense
      filled
      outlined
      hide-details
      append-icon=""
      :rules="rules"
      :error="error"
      :clearable="editable"
      :disabled="disabled"
      :readonly="!editable"
      :placeholder="editable ? placeholder : ''"
      :background-color="backgroundColor"
      :filter="filerByNameCode"
      class="more-dense white-back select-absolute mr-3"
      :class="!editable ? 'reading' : 'py-1'"
      item-text="name"
      :item-value="displayNameKey"
      return-object
      :tabindex="tabindex"
      @focus="onFocus()"
      @blur="onBlur()"
      @input="$emit('input')"
      @change="onChange($event)"
    >
      <template slot="item" slot-scope="data">
        {{ getDisplayName(data) }}
      </template>
    </v-autocomplete>
    <div
      v-else
      style="
        font-size: 11px;
        font-weight: bold;
        padding-top: 2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 190px;
      "
      class="pl-4"
    >
      <span v-tooltip="autoCompleteTooltip">
        {{ item ? item.name : '' }}
      </span>
    </div>
  </div>
</template>

<script>
import { isMapObject } from 'Helpers/helpers'
import JournalChip from 'Components/Journal/JournalChip.vue'
import { getNameCode } from 'Helpers/invoice'

export default {
  components: {
    JournalChip,
  },
  model: {
    prop: 'value',
    event: 'select',
  },
  props: {
    value: {
      validator: (prop) => isMapObject(prop),
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    chip: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default() {
        return []
      },
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    displayNameKey: {
      type: String,
      default: 'name_code',
    },
    fieldName: {
      type: String,
      default: '',
    },
    showAutoCompleteTooltip: {
      type: Boolean,
      default: false,
    },
    disablesAutoComplete: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      focus: false,
      recentUseList: [],
      itemsIncludeRecentUse: [],
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        if (val) {
          this.$emit('select', val)
        } else {
          this.$emit('select', {})
        }
      },
    },
    backgroundColor() {
      return this.setBackGround(this.error, this.disabled)
    },
    autoCompleteTooltip() {
      if (this.showAutoCompleteTooltip) {
        return getNameCode(this.value) || null
      } else {
        return null
      }
    },
    itemsToShow() {
      // to reduce redering speed 13 % and reduce destorying speed about 30%
      if (this.focus && this.itemsIncludeRecentUse.length > 0) {
        return this.itemsIncludeRecentUse
      } else if (this.focus) {
        return this.items
      } else if (this.value && this.value.id) {
        return [this.value]
      } else {
        return []
      }
    },
  },
  methods: {
    setBackGround(error, disabled) {
      if (error) {
        return '#FCE6DE'
      } else if (disabled) {
        return '#D1D1D1'
      } else {
        return 'white'
      }
    },
    filerByNameCode(obj, query) {
      try {
        return obj.search_key.toLowerCase().includes(query.toLowerCase())
      } catch (e) {
        console.log(e)
      }
    },
    onFocus() {
      try {
        this.focus = true
        this.getRecentUseList(this.fieldName)
        this.createList()
      } catch (e) {
        console.log(e)
      }
    },
    onBlur() {
      //ショートカットキーを使用したタブ移動時にフォーカスを外す (マシンスペックに依存する)
      this.$nextTick(() => {
        this.$refs.input.blur()
        this.focus = false
      })
    },
    getRecentUseList(key) {
      try {
        let recentUse = JSON.parse(localStorage.getItem('recentUse'))
        if (recentUse === null || !(key in recentUse)) {
          this.recentUseList = []
        } else {
          this.recentUseList = recentUse[key]
        }
      } catch (e) {
        console.log(e)
      }
    },
    createList() {
      try {
        let tempList = []
        this.recentUseList.forEach((id) => {
          let obj = this.items.find((x) => x.id === id)
          if (obj) {
            const copyObj = JSON.parse(JSON.stringify(obj))
            copyObj[this.displayNameKey] =
              '[最新] ' + copyObj[this.displayNameKey]
            tempList.push(copyObj)
          }
        })
        tempList = tempList.concat(this.items)
        this.itemsIncludeRecentUse = tempList
      } catch (e) {
        console.log(e)
      }
    },
    onChange(event) {
      try {
        if (event) {
          this.setLocalStorageRecentUseList(this.fieldName, event.id)
        }
        this.$emit('change', event || {})
      } catch (e) {
        console.log(e)
      }
    },
    setLocalStorageRecentUseList(key, value) {
      try {
        let recentUse = JSON.parse(localStorage.getItem('recentUse'))
        if (recentUse === null) {
          recentUse = {}
          recentUse[key] = [value]
        } else if (!(key in recentUse)) {
          recentUse[key] = [value]
        } else {
          //既にキーがある場合、同じvalueがあるかチェック
          let arr = recentUse[key]
          const duplicateIndex = arr.indexOf(value)
          if (duplicateIndex !== -1) {
            // 同じvalueがある場合は削除（先頭に移動するため）
            arr.splice(duplicateIndex, 1)
          } else {
            if (arr.length >= 5) {
              //5以上あれば最後の値を削除
              arr.splice(4, 1)
            }
          }
          arr.unshift(value)
        }
        localStorage.setItem('recentUse', JSON.stringify(recentUse))
      } catch (e) {
        console.log(e)
      }
    },
    getDisplayName(data) {
      return data.item[this.displayNameKey]
        ? data.item[this.displayNameKey]
        : ''
    },
    makeElementFocused() {
      this.$refs.input.focus()
    },
  },
}
</script>
