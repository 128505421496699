import { toUnicode } from 'punycode'

// Sidebar Routers
// Access levels: 1 - Owner, 2 - Admin, 3 - Accountant
export const menus = {
  '': [
    {
      action: 'feather-inbox',
      title: 'message.inbox',
      path: '/inbox',
      active: false,
      exact: true,
      ability: 'inbox:read',
    },
    {
      action: 'feather-plus',
      title: 'message.upload',
      path: '/upload',
      active: false,
      exact: true,
      ability: 'invoice:upload',
    },
    {
      action: 'mdi mdi-email-receive-outline',
      title: 'message.titleEmail',
      path: '/email',
      active: false,
      exact: true,
      ability: 'email:read',
    },
    {
      action: 'feather-layers',
      title: 'message.invoice',
      path: '/invoice',
      active: false,
      exact: true,
      ability: 'invoice:read',
    },
    {
      action: 'feather-dollar-sign',
      title: 'message.payment',
      path: '/payment',
      active: false,
      exact: true,
      ability: 'payment:read',
    },
    {
      action: 'feather-bar-chart',
      title: 'message.report',
      path: '/report',
      active: false,
      exact: true,
      ability: 'report:read',
    },
    {
      action: 'feather-book',
      title: 'message.master',
      active: false,
      path: '/master',
      exact: true,
      ability: 'master:read',
    },
    {
      action: 'feather-settings',
      title: 'message.settings',
      path: '/settings',
      active: false,
      exact: true,
      ability: 'settings:read',
    },
  ],
}
