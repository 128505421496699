import Vue from 'vue'
import axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'
import UserListAPI from '@/clients/account/userList'
import UserDeleteAPI from '@/clients/account/userDelete'

function errorNotify(msg) {
  Snackbar.error(msg)
}

const state = {
  userAbilities: {},
  companyName: '',
  user_data: '',
  invoice_columns: '',
}

const getters = {
  getAuthToken: (state) => {
    return state.user_data.token
  },
  getUserEnvType: (state) => {
    try {
      return state.user_data.envtype
    } catch (e) {
      return ''
    }
  },
  getUserID: (state) => {
    return state.user_data.user_id
  },
  getOrganizationID: (state) => {
    return state.user_data.organization_id
  },
  getOrganizationName: (state) => {
    return state.user_data.organization_name
  },
  getUserOrganizationID: (state) => {
    return state.user_data.user_organization_id
  },
  getUserRole: (state) => {
    return state.user_data.user_role_id
  },
  getCompanyName: (state) => {
    let data = JSON.parse(localStorage.getItem('user'))
    if (data) {
      state.companyName = data.organization_name
    }
  },
  getInvoiceTableColumn: (state) => {
    let storageData = JSON.parse(localStorage.getItem('invtblcol'))
    let userStorage = JSON.parse(localStorage.getItem('user'))
    if (storageData) {
      let found = false
      let indexFound = null
      for (let x = 0; x < storageData.length; x++) {
        if (storageData[x].user.user_id) {
          if (storageData[x].user.user_id == userStorage.user_id) {
            found = true
            indexFound = x
          }
        }
      }
      if (found) {
        return storageData[indexFound].tbl_columns
      } else {
        return false
      }
    } else {
      return false
    }
  },
  getRequestHeaders: (state) => {
    return { headers: { Authorization: state.user_data.token } }
  },
  userAbilities: (state) => {
    return state.userAbilities
  },
}
const mutations = {
  initialiseStore(state) {
    if (localStorage.getItem('user')) {
      let data = JSON.parse(localStorage.getItem('user'))
      state.user_data = data
    }
  },
  changeCompanyname(state, data) {
    state.companyName = data
    let storageData = JSON.parse(localStorage.getItem('user'))
    if (storageData) {
      storageData.organization_name = data
      localStorage.setItem('user', JSON.stringify(storageData))
    }
  },
  setUserAbility(state, { key, level }) {
    Vue.set(state.userAbilities, key, level)
  },
  setUserRole(state, roleId) {
    state.user_data.user_role_id = roleId
    let storageData = JSON.parse(localStorage.getItem('user'))
    if (storageData) {
      storageData.user_role_id = roleId
      localStorage.setItem('user', JSON.stringify(storageData))
    }
  },
}

const actions = {
  action_initialiseStore() {
    return new Promise((resolve, reject) => {
      if (localStorage.getItem('user')) {
        let data = JSON.parse(localStorage.getItem('user'))
        state.user_data = data
        resolve('success')
      } else {
        resolve('false')
      }
    })
  },
  saveInvoiceTableColumn(context, payload) {
    try {
      let storageData = JSON.parse(localStorage.getItem('invtblcol'))
      let userStorage = JSON.parse(localStorage.getItem('user'))
      let user_index = null
      if (storageData) {
        for (let x = 0; x < storageData.length; x++) {
          if (storageData[x].user.user_id) {
            if (storageData[x].user.user_id == userStorage.user_id) {
              user_index = x
            }
          }
        }
        if (user_index != null) {
          storageData[user_index].tbl_columns = payload
          localStorage.setItem('invtblcol', JSON.stringify(storageData))
        } else {
          if (storageData.length == 5) {
            let next_input = null
            for (let x = 0; x < storageData.length; x++) {
              if (storageData[x].user) {
                if (storageData[x].user.last_insert == true) {
                  if (x == 4) {
                    //index -1 of storage length
                    next_input = 0
                  } else {
                    next_input = x + 1
                  }
                }
              }
            }
            for (let x = 0; x < storageData.length; x++) {
              storageData[x].user.last_insert = false
            }
            storageData[next_input].tbl_columns = payload
            storageData[next_input].user = {
              user_id: userStorage.user_id,
              last_insert: true,
            }
            localStorage.setItem('invtblcol', JSON.stringify(storageData))
          } else {
            for (let x = 0; x < storageData.length; x++) {
              storageData[x].user.last_insert = false
            }
            let jsonData = {
              tbl_columns: [],
              user: '',
            }
            jsonData['tbl_columns'] = payload
            jsonData['user'] = {
              user_id: userStorage.user_id,
              last_insert: true,
            }
            storageData.push(jsonData)
            localStorage.setItem('invtblcol', JSON.stringify(storageData))
          }
        }
      } else {
        let user_array = []
        let jsonData = {
          tbl_columns: [],
          user: '',
        }
        jsonData['tbl_columns'] = payload
        jsonData['user'] = {
          user_id: userStorage.user_id,
          last_insert: true,
        }
        user_array.push(jsonData)
        localStorage.setItem('invtblcol', JSON.stringify(user_array))
      }
    } catch (err) {
      let userStorage = JSON.parse(localStorage.getItem('user'))
      let user_array = []
      let jsonData = {
        tbl_columns: [],
        user: '',
      }
      jsonData['tbl_columns'] = payload
      jsonData['user'] = {
        user_id: userStorage.user_id,
        last_insert: true,
      }
      user_array.push(jsonData)
      localStorage.setItem('invtblcol', JSON.stringify(user_array))
    }
  },
  getDataUser() {
    let data = JSON.parse(localStorage.getItem('user'))
  },
  logOutUser: (state) => {
    return new Promise((resolve, reject) => {
      localStorage.removeItem('user')
      localStorage.removeItem('freee')
      var cookie_name = 'token_' + process.env.VUE_APP_ENV_TYPE
      document.cookie = cookie_name + '=1;domain=.sweeep.ai;path=/'
      window.location.href =
        process.env.VUE_APP_SUBDOMAIN_ACCOUNTS_FULL + '/session/login'
    })
  },
  addInvite(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl
    const auth_token = data.auth_token
    return new Promise((resolve, reject) => {
      axios
        .put(
          apiUrl,
          {
            email: data.email,
            user_role_id: data.user_role_id,
            code: data.code,
            user_organization_id: data.user_organization_id,
            organization_id: data.organization_id,
            department_id: data.department_id,
            url: data.url,
          },
          {
            headers: { Authorization: auth_token },
          }
        )
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          }
          resolve(response)
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  userAcceptInvite(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl
    const auth_token = data.auth_token

    return new Promise((resolve, reject) => {
      axios
        .post(
          apiUrl,
          {
            id: data.id,
            full_name: data.full_name,
            password: data.password,
            invite_code: data.invite_code,
          },
          {
            headers: { Authorization: auth_token },
          }
        )
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {})
    })
  },
  async updateUser(context, payload) {
    const token = context.getters.getAuthToken
    const data = await UserListAPI.put(token, {
      userOrganizationId: payload.userOrganizationId,
      department: payload.department,
      userOrganizationCode: payload.userOrganizationCode,
      userRole: payload.userRole,
    })
    return data
  },
  async deleteUser(context, payload) {
    const token = context.getters.getAuthToken
    await UserDeleteAPI.post(token, { id: payload.id })
  },
  cancelUserInvite(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl
    const auth_token = data.auth_token
    return new Promise((resolve, reject) => {
      axios
        .delete(apiUrl, {
          data: {
            id: data.id,
            user_id: data.user_id,
            user_role_id: data.user_role_id,
          },
          headers: { Authorization: auth_token },
        })
        .then(function (response) {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          }
          if (response.data.status == 'success') {
            resolve(response)
          }
        })
    })
  },
  setUserAbilities(context, abilitiesList) {
    try {
      const abilitiesObj = Object.fromEntries(
        abilitiesList.map((obj) => [
          obj.resource + ':' + obj.operation,
          obj.level,
        ])
      )
      // set abilities in state
      let key, level
      for (key of Object.keys(abilitiesObj)) {
        level = abilitiesObj[key]
        context.commit('setUserAbility', { key, level })
      }
      const masterReadKeys = [
        'account_title:read',
        'department:read',
        'tax:read',
        'vendor:read',
        'project:read',
        'segment:read',
        'walletable:read',
        'item:read',
        'tag:read',
        'transfer_fee:read',
        'company:read',
      ]
      const hasPrivilegeToReadMaster = masterReadKeys.some((key) => {
        if (abilitiesObj[key]) {
          return true
        }
      })
      context.commit('setUserAbility', {
        key: 'master:read',
        level: hasPrivilegeToReadMaster ? 1 : 0,
      })
    } catch (e) {
      console.log(e)
    }
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
}
