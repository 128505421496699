<!-- Header Structure -->
<template>
  <div>
    <v-navigation-drawer
      app
      :mini-variant="collapsed"
      mini-variant-width="54"
      color="blue-grey darken-3"
      permanent
      dark
      width="180"
      class="menu"
    >
      <v-list>
        <v-list-item :class="{ 'pl-1': !collapsed }">
          <img src="/logo.png" width="42" height="42" class="ma-1" />
        </v-list-item>
        <template v-for="category in menus">
          <template v-for="item in category">
            <template v-if="checkPrivilege(item.ability)">
              <v-list-item
                :key="item.title"
                v-tooltip="
                  collapsed
                    ? {
                        content: $t(item.title),
                        placement: 'right',
                        classes: 'menu-tooltip',
                      }
                    : ''
                "
                link
                :to="
                  !item.exact
                    ? `/${getCurrentAppLayoutHandler() + item.path}`
                    : item.path
                "
              >
                <v-list-item-action style="min-width: 20px">
                  <s-icon :icon="item.action" size="2xl" />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t(item.title) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>
        </template>
      </v-list>

      <div style="position: absolute; bottom: 0; width: 100%">
        <v-list>
          <v-menu right bottom nudge-right="60" transition="slide-x-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-tooltip="
                  collapsed
                    ? {
                        content: 'ショートカット',
                        placement: 'right',
                        classes: 'menu-tooltip',
                      }
                    : ''
                "
                link
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-action style="min-width: 20px">
                  <s-icon icon="mdi mdi-keyboard-outline" size="2xl" />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title> ショートカット </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-card width="350" class="sweeep-popover">
              <v-card-title v-text="'ショートカットキー'" />
              <v-card-text>
                <div class="fs-10 fw-bold mb-2">
                  請求書メニュー > 詳細ビュー
                </div>
                <v-row no-gutters>
                  <v-col cols="5" class="mt-1">
                    <v-chip small label v-text="'Shift + Ctrl + E'" />
                  </v-col>
                  <v-col cols="7" class="pt-2 pl-1">
                    編集, 編集のキャンセル(Edit)
                  </v-col>

                  <v-col cols="5" class="mt-1">
                    <v-chip small label v-text="'Shift + Ctrl + B'" />
                  </v-col>
                  <v-col cols="7" class="pt-2 pl-1">
                    前の請求書を表示(Back)
                  </v-col>

                  <v-col cols="5" class="mt-1">
                    <v-chip small label v-text="'Shift + Ctrl + G'" />
                  </v-col>
                  <v-col cols="7" class="pt-2 pl-1">
                    次の請求書を表示(Go)
                  </v-col>

                  <v-col cols="5" class="mt-1">
                    <v-chip small label v-text="'Shift + Ctrl + →'" />
                  </v-col>
                  <v-col cols="7" class="pt-2 pl-1">
                    タブの切替え(複数ある場合)
                  </v-col>

                  <v-col cols="5" class="mt-1">
                    <v-chip small label v-text="'Shift + Ctrl + ←'" />
                  </v-col>
                  <v-col cols="7" class="pt-2 pl-1">
                    タブの切替え(複数ある場合)
                  </v-col>

                  <v-col cols="5" class="mt-1">
                    <v-chip small label v-text="'Shift + Ctrl + I'" />
                  </v-col>
                  <v-col cols="7" class="pt-2 pl-1">
                    入力エリア拡大/縮小(Input Area)
                  </v-col>

                  <v-col cols="5" class="mt-1">
                    <v-chip small label v-text="'Shift + Ctrl + Enter'" />
                  </v-col>
                  <v-col cols="7" class="pt-2 pl-1">
                    更新(編集中のみ有効)
                  </v-col>
                </v-row>

                <div class="fs-10 fw-bold mb-2 mt-7">
                  請求書メニュー > 詳細ビュー > 更新確認ダイアログ
                </div>
                <v-row no-gutters>
                  <v-col cols="5" class="mt-1">
                    <v-chip small label v-text="'Shift + Ctrl + F12'" />
                  </v-col>
                  <v-col cols="7" class="pt-2 pl-1">
                    新規仕訳パターンを登録
                  </v-col>

                  <v-col cols="5" class="mt-1">
                    <v-chip small label v-text="'Shift + Ctrl + S'" />
                  </v-col>
                  <v-col cols="7" class="pt-2 pl-1">
                    学習データを上書き(Save)<br />
                    または仕訳パターンを登録
                  </v-col>
                </v-row>

                <v-alert text color="blue" outlined class="fs-12 mt-4">
                  <div class="fw-bold">Macの場合</div>
                  Ctrlは「control」となります
                </v-alert>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-list>
        <account-information />
        <v-list>
          <v-list-item
            v-tooltip="
              collapsed
                ? {
                    content: '展開',
                    placement: 'right',
                    classes: 'menu-tooltip',
                  }
                : ''
            "
            @click="collapsed = !collapsed"
          >
            <v-list-item-action style="min-width: 20px">
              <s-icon
                :icon="
                  collapsed
                    ? 'feather-arrow-right-circle'
                    : 'feather-arrow-left-circle'
                "
                color="gray-300"
                size="2xl"
              />
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="drawerUpload" app right temporary width="600">
      <invoice-dropzone
        class="pa-8"
        :organization="organization"
        :definition="definition"
        :journal-items="masterList"
      />
    </v-navigation-drawer>

    <v-app-bar app color="white" height="50" flat class="border-bottom-1">
      <message-board />
      <v-spacer />
      <select-company />
      <v-btn
        v-if="checkPrivilege('invoice:upload')"
        v-tooltip="{ content: '請求書を追加', offset: '8' }"
        x-small
        fab
        depressed
        dark
        color="blue"
        class="ml-4 mr-2"
        @click="drawerUpload = true"
      >
        <s-icon icon="feather-plus" size="2xl" />
      </v-btn>

      <notifications />
      <a href="#" class="ak-trigger">
        <v-btn v-tooltip="'運営者からのお知らせ'" icon>
          <s-icon icon="mdi mdi-bullhorn-outline" size="2xl" color="gray-500" />
        </v-btn>
        <announce-kit
          catch-click=".ak-trigger"
          :widget="ANNOUNCEKIT_WIDGET_URL"
        />
      </a>
      <v-btn v-tooltip="'全画面表示'" icon @click="toggleFullScreen">
        <s-icon icon="feather-maximize" size="2xl" color="gray-500" />
      </v-btn>
      <v-btn
        v-tooltip="'ヘルプページ'"
        icon
        link
        :href="'https://support.sweeep.ai'"
        target="_blank"
        style="margin-right: 70px"
      >
        <s-icon icon="feather-life-buoy" size="2xl" color="gray-500" />
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import { getCurrentAppLayout } from 'Helpers/helpers'
import { mapGetters } from 'vuex'
import Notifications from './components/Notifications'
import InvoiceDropzone from 'Views/invoice/components/InvoiceDropzone'
import MessageBoard from './components/MessageBoard'
import SelectCompany from './components/SelectCompany'
import AccountInformation from './components/AccountInfomation'
import AnnounceKit from 'announcekit-vue'

export default {
  components: {
    Notifications,
    InvoiceDropzone,
    MessageBoard,
    SelectCompany,
    AccountInformation,
    AnnounceKit,
  },
  props: {
    horizontal: {
      default: false,
      type: Boolean,
    },
    user: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
    definition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      collapsed: true, // collapse sidebar
      drawer: true, // sidebar drawer default true
      chatSidebar: false, // chat component right sidebar
      sidebarImages: '', // sidebar background images
      enableDefaultSidebar: false,
      current_router: '',
      drawerUpload: false,
    }
  },
  computed: {
    ...mapGetters([
      'rtlLayout',
      'backgroundImage',
      'backgroundImage',
      'selectedSidebarBgImage',
      'darkMode',
      'collapseSidebar',
      'activeHeaderFilter',
      'sidebarSelectedFilter',
      'menus',
      'masterList',
    ]),
    ANNOUNCEKIT_WIDGET_URL() {
      return process.env.VUE_APP_ANNOUNCEKIT_WIDGET_URL
    },
  },
  beforeCreate() {
    this.$store.commit('initialiseStore')
  },
  created() {},
  mounted() {
    this.current_router = this.$router.currentRoute.name
  },
  methods: {
    // toggle full screen method
    toggleFullScreen() {
      if (screenfull.enabled) {
        screenfull.toggle()
      }
    },
    getMenuLink(link) {
      return '/' + getCurrentAppLayout(this.$router) + link
    },
  },
}
</script>
