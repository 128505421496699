<template>
  <v-btn
    v-tooltip="tooltip"
    class="mr-2"
    :disabled="disabled"
    @click.native.stop="$emit('click')"
  >
    <i v-if="icon" :class="'mr-2 ' + icon" />
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
