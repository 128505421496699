import recurringInvoiceScheduleAPI from '@/clients/invoice/recurringInvoiceSchedule'

const state = {
  recurringInvoiceSchedule: {
    id: '',
    startAt: '',
    endAt: '',
    issueDay: '',
    issueMonths: [],
    paymentTermMonth: '',
    paymentTermDay: '',
    invoices: [],
    completed: false,
  },
}

const getters = {
  recurringInvoiceScheduleId: (state) => {
    return state.recurringInvoiceSchedule.id
  },
  recurringInvoiceSchedule: (state) => {
    return state.recurringInvoiceSchedule
  },
}

const mutations = {
  setRecurringInvoiceSchedule(
    state,
    {
      schedule,
      defaultIssueDate,
      defaultPaymentTermMonth,
      defaultPaymentTermDay,
    }
  ) {
    const getAttr = (obj, field, noneValues, defaultValue) => {
      const value = obj[field]
      return noneValues.includes(value) ? defaultValue : obj[field]
    }
    defaultIssueDate = defaultIssueDate ? defaultIssueDate : ''
    defaultPaymentTermMonth =
      defaultPaymentTermMonth || defaultPaymentTermMonth === 0
        ? defaultPaymentTermMonth
        : 1
    defaultPaymentTermDay = defaultPaymentTermDay ? defaultPaymentTermDay : 31
    const defaultIssueDay = Number(defaultIssueDate.slice(-2))

    state.recurringInvoiceSchedule.id = schedule.id
    state.recurringInvoiceSchedule.startAt = getAttr(
      schedule,
      'start_at',
      [undefined, null, ''],
      defaultIssueDate
    )
    state.recurringInvoiceSchedule.endAt = getAttr(
      schedule,
      'end_at',
      [undefined, null],
      ''
    )
    state.recurringInvoiceSchedule.issueDay = getAttr(
      schedule,
      'issue_day',
      [undefined, null, ''],
      defaultIssueDay
    )
    state.recurringInvoiceSchedule.issueMonths = getAttr(
      schedule,
      'issue_months',
      [undefined, null, ''],
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    )
    state.recurringInvoiceSchedule.paymentTermMonth = getAttr(
      schedule,
      'payment_term_month',
      [undefined, null, ''],
      defaultPaymentTermMonth
    )
    state.recurringInvoiceSchedule.paymentTermDay = getAttr(
      schedule,
      'payment_term_day',
      [undefined, null, ''],
      defaultPaymentTermDay
    )
    state.recurringInvoiceSchedule.invoices = getAttr(
      schedule,
      'invoices',
      [undefined, null, ''],
      []
    )
    state.recurringInvoiceSchedule.completed = getAttr(
      schedule,
      'completed',
      [undefined, null, ''],
      false
    )
  },
}

const actions = {
  async setRecurringInvoiceSchedule(
    context,
    {
      schedule,
      defaultIssueDate,
      defaultPaymentTermMonth,
      defaultPaymentTermDay,
    }
  ) {
    context.commit('setRecurringInvoiceSchedule', {
      schedule,
      defaultIssueDate,
      defaultPaymentTermMonth,
      defaultPaymentTermDay,
    })
  },

  async reloadInvoiceSchedule(context) {
    const scheduleId = context.getters.recurringInvoiceScheduleId
    context.dispatch('getRecurringInvoiceSchedule', { scheduleId: scheduleId })
  },

  async getRecurringInvoiceSchedule(context, { scheduleId }) {
    if (!scheduleId) {
      return
    }
    const token = context.getters.getAuthToken
    const schedule = await recurringInvoiceScheduleAPI.get(token, {
      scheduleId: scheduleId,
    })
    context.commit('setRecurringInvoiceSchedule', { schedule: schedule })
    return schedule
  },

  async createRecurringInvoiceSchedule(context, payload) {
    const token = context.getters.getAuthToken
    const createdSchedule = await recurringInvoiceScheduleAPI.create(token, {
      invoiceId: payload.invoiceId,
      startAt: payload.startAt,
      endAt: payload.endAt,
      issueDay: payload.issueDay,
      issueMonths: payload.issueMonths,
      paymentTermDay: payload.paymentTermDay,
      paymentTermMonth: payload.paymentTermMonth,
    })
    context.commit('setRecurringInvoiceSchedule', { schedule: createdSchedule })
    return createdSchedule
  },

  async updateRecurringInvoiceSchedule(context, payload) {
    const token = context.getters.getAuthToken
    const updatedSchedule = await recurringInvoiceScheduleAPI.update(token, {
      scheduleId: payload.scheduleId,
      endAt: payload.endAt,
      issueDay: payload.issueDay,
      issueMonths: payload.issueMonths,
      paymentTermDay: payload.paymentTermDay,
      paymentTermMonth: payload.paymentTermMonth,
    })
    context.commit('setRecurringInvoiceSchedule', { schedule: updatedSchedule })
    return updatedSchedule
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
