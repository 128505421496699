// component
const FullScreenPdfView = () => import('Views/invoice/FullScreenPdfView')

import { checkAuthorization, checkIp } from '../helpers/helpers.js'

export default {
  path: '/pdf/:id/:version',
  component: FullScreenPdfView,
  meta: { requiresAuth: true },
  beforeEnter(to, from, next) {
    checkAuthorization(next)
    checkIp(next)
    next()
  },
}
