<template>
  <v-dialog v-model="dialog" persistent width="300">
    <v-card class="freeUploadWaitingModal sweeep-dialog">
      <div class="waiting-container-free-upload pt-9" align="center">
        <v-progress-circular indeterminate color="primary" />
        <br />
        <br />
        <br />
        <label style="margin-top: 15px">
          {{ message }}
        </label>
        <br />
        <br />
        <br />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      message: '',
    }
  },
  methods: {
    open(message) {
      this.message = message
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.message = ''
    },
  },
}
</script>
