import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'
import { getTransferFeeErrorMessage } from '@/helpers/master/index'

class TransferFeeAPI extends APIClient {
  async put(token, id, data) {
    const payload = {
      data,
      url: URLs.TRANSFER_FEE,
      method: 'PUT',
      headers: {
        Authorization: token,
      },
      args: {
        transfer_fee_id: id,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(getTransferFeeErrorMessage(response.data.error))
      }
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }
  async delete(token, id) {
    const payload = {
      url: URLs.TRANSFER_FEE,
      method: 'DELETE',
      headers: {
        Authorization: token,
      },
      args: {
        transfer_fee_id: id,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(getTransferFeeErrorMessage(response.data.error))
      }
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }
}
export default new TransferFeeAPI()
