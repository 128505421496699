const host = process.env.VUE_APP_ROOT_API

export function getApiUrl(path) {
  return host + path
}

export function createResponseError(err) {
  if (err.response && err.response.data.error) {
    return new Error(err.response.data.error)
  } else {
    return new Error(err.message)
  }
}
