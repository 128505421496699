import Vue from 'vue'
import { customRequest } from 'Helpers/api/request'

const actions = {
  getRoles(context) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          url: context.getters.apiRolesUrl,
          method: 'GET',
        }
        customRequest(data).then((response) => {
          resolve(response)
        })
      } catch (e) {
        reject(new Error(e))
      }
    })
  },
  postNewRole(context, { name }) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          url: context.getters.apiNewRoleUrl,
          method: 'POST',
          data: {
            name: name,
          },
        }
        customRequest(data).then((response) => {
          resolve(response)
        })
      } catch (e) {
        reject(new Error(e))
      }
    })
  },
  copyRole(context, { roleId, name }) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          url: context.getters.apiCopyRoleUrl,
          method: 'POST',
          data: {
            copy_role_id: roleId,
            name: name,
          },
        }
        customRequest(data).then((response) => {
          resolve(response)
        })
      } catch (e) {
        reject(new Error(e))
      }
    })
  },
  getRole(context, { roleId }) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          url: context.getters.apiRoleUrl,
          method: 'GET',
          args: {
            id: roleId,
          },
        }
        customRequest(data).then((response) => {
          resolve(response)
        })
      } catch (e) {
        reject(new Error(e))
      }
    })
  },
  patchRole(context, { roleId, name }) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          url: context.getters.apiRoleUrl,
          method: 'PATCH',
          args: {
            id: roleId,
          },
          data: {
            name: name,
          },
        }
        customRequest(data).then((response) => {
          resolve(response)
        })
      } catch (e) {
        reject(new Error(e))
      }
    })
  },
  deleteRole(context, { roleId, name }) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          url: context.getters.apiRoleUrl,
          method: 'DELETE',
          args: {
            id: roleId,
          },
          data: {
            name: name,
          },
        }
        customRequest(data).then((response) => {
          resolve(response)
        })
      } catch (e) {
        reject(new Error(e))
      }
    })
  },
  getAbilities(context, roleId) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          url: context.getters.apiAbilitiesUrl,
          method: 'GET',
          args: {
            id: roleId,
          },
        }
        customRequest(data).then((response) => {
          resolve(response)
        })
      } catch (e) {
        reject(new Error(e))
      }
    })
  },
  putAbilities(context, { roleId, items }) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          url: context.getters.apiAbilitiesUrl,
          method: 'PUT',
          args: {
            id: roleId,
          },
          data: {
            items: items,
          },
        }
        customRequest(data).then((response) => {
          resolve(response)
        })
      } catch (e) {
        reject(new Error(e))
      }
    })
  },
}

export default {
  actions,
}
