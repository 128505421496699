import Vue from 'vue'
import BaseDialog from 'Components/Dialog/BaseDialog'
import ExportWarnDialog from 'Components/Dialog/ExportWarnDialog'

const componentFactory = {
  createComponent: (options) => {
    return new Promise((resolve) => {
      const Component = options.component
      const ComponentClass = Vue.extend(Component)
      const VM = new ComponentClass({
        propsData: options.propsData,
        watch: options.watch || {},
      })

      for (const [methodName, callback] of Object.entries(
        options.methods || []
      )) {
        VM[methodName] = () => resolve(callback(VM, options))
      }
      const mount = (elm, cb) => {
        if (elm) {
          elm.appendChild(VM.$mount().$el)
        } else {
          VM.$mount()
        }
        cb(VM, options)
      }
      const callback = options.mounted || function () {}
      mount(options.targetElm, callback)
    })
  },
}

export const confirmDialogBuilder = {
  async create(props, component) {
    const defaultProps = {
      title: '確認',
      cancelText: 'いいえ',
      submitText: 'はい',
      confirmShowDialog: false,
      localStorageSetName: '',
    }
    const options = {
      component: component ? component : BaseDialog,
      propsData: {
        ...defaultProps,
        ...props,
      },
      watch: {
        dialog: function (show) {
          //click:outside
          if (!show) {
            this.cancel()
          }
        },
      },
      mounted(component) {
        component.open()
      },
      methods: {
        submit(component) {
          if (props.localStorageSetName) {
            component.setLocalStorage()
          }
          component.close()
          return true
        },
        cancel(component) {
          component.close()
          return false
        },
      },
    }
    const elmUnderFull = document.getElementById(
      'full-confirm-dialog-container'
    )
    options.targetElm = elmUnderFull
    const payload = await componentFactory.createComponent(options)
    const previous = elmUnderFull.getElementsByClassName('v-dialog__container')
    for (const elm of previous) {
      elm.remove()
    }
    return payload
  },
}

export const exportWarnDialogBuilder = {
  async create(propsData) {
    const options = {
      propsData,
      component: ExportWarnDialog,
      mounted(component) {
        Vue.nextTick(() => {
          component.open()
        })
      },
      methods: {
        cancel(component) {
          component.close()
          return 'cancel'
        },
        submitAll(component) {
          component.close()
          return 'all'
        },
        submitApproved(component) {
          component.close()
          return 'approved'
        },
      },
    }
    const payload = await componentFactory.createComponent(options)
    return payload
  },
}
