import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class UserDeleteAPI extends APIClient {
  getErrorMessage(error) {
    console.log(error)
    const errorToMessage = {
      cannot_delete_own_user: '自身のアカウントは削除できません。',
      cannot_delete_owner:
        'オーナー権限のユーザの削除はオーナー権限以外ではできません。',
      no_privilege: '権限がありません。',
    }
    if (error in errorToMessage) {
      return errorToMessage[error]
    } else {
      return '予期せぬエラーが発生しました。'
    }
  }

  async post(token, data) {
    const payload = {
      data,
      url: URLs.USER_DELETE,
      method: 'POST',
      headers: {
        Authorization: token,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(this.getErrorMessage(response.data.error))
      }
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }
}

export default new UserDeleteAPI()
