import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class CustomerApprovalStageAPI extends APIClient {
  async get(token, params) {
    const payload = {
      url: URLs.CUSTOMER_APPROVAL_STAGE,
      method: 'GET',
      args: {
        customer_id: params.customerId,
      },
      headers: {
        Authorization: token,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(response.data.error)
      }
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }
}
export default new CustomerApprovalStageAPI()
