/**
 * Auth Module
 */
import Vue from 'vue'
import { users } from './data'
import Axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'

const state = {
  users: null,
  loadingInvoiceModule: false,
  selectedUser: null,
  loadingInvoice: false,
  invoiceSidebar: false,
  invoice: {},
  invoicePrivilege: {
    read: true,
    update: true,
    delete: true,
    export_journal: true,
    export_table: true,
    approve1: true,
    approve2: true,
    approve3: true,
    approve4: true,
    approve5: true,
    pay_approve1: true,
    pay_approve2: true,
    pay_approve3: true,
    pay_approve4: true,
    pay_approve5: true,
  },
  editMode: false,
  windowToken: '',
  editingUsers: [],
  editedInvoiceFormFields: [],
  showMemo: false,
  editingMemo: false,
  loadingInvoiceDetail: false,
  loadingInvoiceEditingUser: {
    get: false,
    update: false,
  },
  timeoutId: undefined,
  gensenType: [
    { code: '0', name: '税理士等の報酬' },
    { code: '1', name: '01 原稿料､著作権の使用料､放送謝金等' },
    { code: '2', name: '02 診療報酬' },
    { code: '3', name: '03 職業野球の選手等の報酬･料金' },
    { code: '4', name: '04 職業拳闘家の報酬' },
    { code: '5', name: '05 外交員等の報酬･料金' },
    { code: '6', name: '06 映画､演劇の俳優等の報酬･料金' },
    {
      code: '7',
      name: '07 芸能人の役務の提供を内容とする事業を行う個人の報酬･料金',
    },
    { code: '8', name: '08 ホステス等の報酬･料金' },
    { code: '21', name: '21 役務提供についての契約金' },
    { code: '31', name: '31 広告宣伝のための賞金' },
    { code: '32', name: '32 個人の馬主が受ける競馬の賞金' },
    { code: '41', name: '41 公的年金等' },
    { code: '61', name: '61 生命･損害保険契約等に基づく年金' },
    { code: '81', name: '81 法人の馬主が受ける競馬の賞金' },
  ],
}

// getters
const getters = {
  loadingInvoiceModule: (state) => {
    return state.loadingInvoiceModule
  },
  loadingInvoiceEditingUser: (state) => {
    return {
      get: state.loadingInvoiceEditingUser.get,
      update: state.loadingInvoiceEditingUser.update,
    }
  },
  users: (state) => {
    return state.users
  },
  selectedUser: (state) => {
    return state.selectedUser
  },
  loadingInvoice: (state) => {
    return state.loadingInvoice
  },
  invoiceSidebar: (state) => {
    return state.invoiceSidebar
  },
  invoicePrivilege: (state) => {
    return state.invoicePrivilege
  },
  gensenType: (state) => {
    return state.gensenType
  },
  editMode: (state) => {
    return state.editMode
  },
  editedInvoiceForm: (state) => {
    return state.editedInvoiceFormFields.length > 0
  },
  editedInvoiceFormFields: (state) => {
    return state.editedInvoiceFormFields
  },
  loadingInvoiceDetail: (state) => {
    return state.loadingInvoiceDetail
  },
  editingUsers: (state) => {
    return state.editingUsers
  },
  windowToken: (state) => {
    return state.windowToken
  },
  editingMemo: (state) => state.editingMemo,
  showMemo: (state) => state.showMemo,
}

// actions
const actions = {
  setWindowToken(context) {
    var text = ''
    var possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (var i = 0; i < 100; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    context.commit('setWindowToken', text)
  },
  getPdfUrl(context, { localId, version }) {
    try {
      return new Promise((resolve) => {
        Axios.get(context.getters.apiInvoicePDFUrl, {
          headers: {
            Authorization: context.getters.getAuthToken,
          },
          params: {
            local_id: localId,
            organization_id: context.getters.getOrganizationID,
            user_organization_id: context.getters.getUserOrganizationID,
            version: version,
          },
        }).then((response) => {
          if (response.data.status === 'success') {
            resolve(response.data)
          } else {
            resolve(response.data)
            Snackbar.error('Error occured on get pdf token.')
          }
        })
      })
    } catch (err) {
      console.log(err)
    }
  },
  downloadInvoicePdfFile(context, { localId, version }) {
    return new Promise((resolve, reject) => {
      try {
        const payload = {
          localId: localId,
          version: version,
        }

        context.dispatch('getPdfUrl', payload).then((data) => {
          Axios.get(data.url, { responseType: 'blob' }).then((fileResponse) => {
            let a = document.createElement('a')
            let blob = new Blob([fileResponse.data], {
              type: 'application/pdf',
            })
            let pdfurl = window.URL.createObjectURL(blob)
            a.href = pdfurl
            a.download = data.file_name
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(pdfurl)
          })
        })
      } catch (e) {
        reject(new Error(e))
      }
    })
  },
  addInvoiceImage(context, payload) {
    context.commit('addInvoiceImageHandler', payload)
  },
  getUsers(context) {
    context.commit('getUsers')
    setTimeout(() => {
      context.commit('getUserSuccess')
    }, 2000)
  },
  openInvoice(context, payload) {
    context.commit('loadInvoice', payload)
    setTimeout(() => {
      context.commit('loadInvoiceSuccess')
    }, 2000)
  },
  sendMessage(context, payload) {
    let message = {
      id: new Date().getTime(),
      body: payload,
      isAdmin: true,
      time: 'just now',
    }
    context.commit('sendMessage', message)
  },
  toggleInvoiceSidebar(context, payload) {
    context.commit('toggleInvoiceSidebarHandler', payload)
  },
  importImageFile(context, payload) {
    return new Promise((resolve, reject) => {
      Axios.put(
        context.getters.apiInvoiceImageUrl,
        {
          local_id: payload.data.local_id,
          image: payload.data.image,
          upload_source: payload.data.fileName,
        },
        {
          headers: {
            Authorization: context.getters.getAuthToken,
          },
        }
      )
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(new Error(error))
        })
    })
  },
  getEditingUsers(context, localId) {
    try {
      const field = 'get'
      context.commit('setLoadingInvoiceEditingUser', {
        field,
        value: true,
      })

      return new Promise(function (resolve, reject) {
        if (!localId) {
          return reject(new Error('no id'))
        }
        Axios.get(context.getters.apiInvoiceEditUser, {
          headers: {
            Authorization: context.getters.getAuthToken,
          },
          params: {
            local_id: localId,
          },
        }).then((response) => {
          context.commit('setLoadingInvoiceEditingUser', {
            field,
            value: false,
          })

          if (context.rootState.route.params.id !== localId) {
            return reject(new Error('id dose not match'))
          }
          if (response.data.status === 'success') {
            if (Array.isArray(response.data.editing_users)) {
              context.commit('setEditingUsers', response.data.editing_users)
              return resolve(response.data.editing_users.length > 0)
            } else {
              context.commit('setEditingUsers', [])
              return resolve(false)
            }
          } else {
            context.commit('setEditingUsers', [])
            Snackbar.error(response.data.error)
            return reject(new Error(response.data.error))
          }
        })
      })
    } catch (err) {
      console.log(err)
    }
  },
  changeInvoiceDepartment(context, { invoiceId, departmentId }) {
    return new Promise(function (resolve, reject) {
      Axios.put(
        context.getters.apiInvoiceDepartment,
        {
          name: 'default',
          invoice_id: invoiceId,
          department_id: departmentId,
        },
        { headers: { Authorization: context.getters.getAuthToken } }
      ).then((response) => {
        resolve(response)
      })
    })
  },
  putEditingUser(context, { localId, mode }) {
    // mode => -1: delete 0: preview 1: editing
    try {
      const field = 'update'
      context.commit('setLoadingInvoiceEditingUser', {
        field,
        value: true,
      })

      const setNextRequest = () => {
        const interval = 5 * 60 * 1000
        const timeoutId = setTimeout(() => {
          if (context.rootState.route.params.id == localId) {
            context.dispatch('putEditingUser', { localId, mode })
          }
        }, interval)
        context.commit('setTimeoutId', timeoutId)
      }

      return new Promise(function (resolve, reject) {
        Axios.put(
          context.getters.apiInvoiceEditUser,
          {
            mode: mode,
            local_id: localId,
            date_start_edit: Date.now(),
            window_token: context.getters.windowToken,
            organization_id: context.getters.getOrganizationID,
            user_organization_id: context.getters.getUserOrganizationID,
          },
          {
            headers: { Authorization: context.getters.getAuthToken },
          }
        ).then((response) => {
          context.commit('setLoadingInvoiceEditingUser', {
            field,
            value: false,
          })
          if (response.data.status === 'success') {
            if (context.state.timeoutId) {
              clearTimeout(context.state.timeoutId)
            }
            if (mode === 1) {
              setNextRequest()
            }
            resolve(true)
          } else {
            return reject(new Error(response.data.message))
          }
        })
      })
    } catch (err) {
      console.log(err)
    }
  },
  postInvoiceViewed(context, id) {
    return new Promise(function (resolve) {
      Axios.post(
        context.getters.apiInvoiceViewedUrl,
        {
          id: id,
        },
        {
          headers: { Authorization: context.getters.getAuthToken },
        }
      ).then((response) => {
        if (response.data.status === 'success') {
          resolve(response.data)
        } else {
          if (response.data.status === 'error') {
            Snackbar.error(response.data.error)
          }
          resolve(null)
        }
      })
    })
  },
  deleteInvoiceViewed(context, id) {
    return new Promise(function (resolve) {
      Axios.delete(context.getters.apiInvoiceViewedUrl, {
        data: {
          id: id,
        },
        headers: {
          Authorization: context.getters.getAuthToken,
        },
      }).then((response) => {
        if (response.data.status === 'success') {
          resolve(response.data)
        } else {
          if (response.data.status === 'error') {
            Snackbar.error(response.data.error)
          }
          resolve(null)
        }
      })
    })
  },
  getInvoiceActionLogs(context, localId) {
    return new Promise(function (resolve) {
      Axios.get(context.getters.apiActionLogsUrl, {
        headers: {
          Authorization: context.getters.getAuthToken,
        },
        params: {
          menu: 'invoice',
          local_id: localId,
        },
      })
        .then((response) => {
          if (response.data.status === 'success') {
            resolve(response.data)
          } else {
            Snackbar.error(response.data.error)
            resolve(null)
          }
        })
        .catch((err) => {
          Snackbar.error(err)
        })
    })
  },
  onEditMode(context) {
    context.dispatch('putEditingUser', {
      localId: context.rootState.route.params.id,
      mode: 1,
    })
    context.commit('setEditMode', true)
  },
  offEditMode(context) {
    context.dispatch('putEditingUser', {
      localId: context.rootState.route.params.id,
      mode: 0,
    })
    context.commit('setEditMode', false)
  },
  clearEditMode(context) {
    context.dispatch('putEditingUser', {
      mode: -1,
    })
    context.commit('setEditMode', false)
  },
}

// mutations
const mutations = {
  setWindowToken(state, windowToken) {
    state.windowToken = windowToken
  },
  setEditedInvoiceFormFields(state, editedFields) {
    state.editedInvoiceFormFields.splice(0)
    editedFields.forEach((value) => {
      state.editedInvoiceFormFields.push(value)
    })
  },
  setLoadingInvoiceDetail(state, val) {
    state.loadingInvoiceDetail = val
  },
  setLoadingInvoiceEditingUser(state, { field, value }) {
    Vue.set(state.loadingInvoiceEditingUser, field, value)
  },
  setEditingUsers(state, users) {
    state.editingUsers.splice(users.length)
    for (let i = 0; i < users.length; i++) {
      Vue.set(state.editingUsers, i, users[i])
    }
  },
  setEditMode(state, value) {
    state.editMode = value
  },
  setInvoicePrivilege(state, privilege) {
    Object.keys(privilege).forEach((op) => {
      Vue.set(state.invoicePrivilege, op, privilege[op])
    })
  },
  setTimeoutId(state, value) {
    state.timeoutId = value
  },
  addInvoiceImageHandler(state, payload) {
    state.invoice[payload.id] = { image: payload.image }
  },
  getUsers(state) {
    state.loadingInvoiceModule = true
  },
  getUserSuccess(state) {
    state.users = users
    state.loadingInvoiceModule = false
  },
  loadInvoice(state, user) {
    state.loadingInvoice = true
    state.selectedUser = user
    for (let i = 0; i < state.users.length; i++) {
      let selectedUser = state.users[i]
      if (selectedUser.id === user.id) {
        state.users[i].selected = true
        state.invoiceSidebar = false
      } else {
        state.users[i].selected = false
      }
    }
  },
  loadInvoiceSuccess(state) {
    state.loadingInvoice = false
  },
  sendMessage(state, message) {
    for (let i = 0; i < state.users.length; i++) {
      let user = state.users[i]
      if (user.selected) {
        state.users[i].invoices.push(message)
      }
    }
  },
  toggleInvoiceSidebarHandler(state, val) {
    state.invoiceSidebar = val
  },
  changeEditingMemo(state, value) {
    state.editingMemo = value
  },
  changeShowMemo(state, value) {
    state.showMemo = value
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
