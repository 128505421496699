<template functional>
  <i
    v-tooltip="props.tooltip"
    :class="[
      `${props.icon} tw-text-${props.color} tw-text-${props.size}`,
      data.staticClass,
      data.class,
    ]"
  />
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'gray-800',
    },
    size: {
      // xs, sm, base, lg, xl, 2xl ,3xl , ... , 9xl
      type: String,
      default: 'base',
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
}
</script>
