import Axios from 'axios'
import EmailsAPI from '@/clients/email/emails'
import EmailAPI from '@/clients/email/email'
import EmailAttachmentURLAPI from '@/clients/email/emailAttachmentURL'

const state = {
  emails: [],
  emailTableParams: {
    dateFrom: null,
    dateTo: null,
  },
  emailTableFilterParams: {
    text: '',
  },
}

const getters = {
  emails: (state) => {
    return state.emails
  },
  emailTableFilterParams: (state) => {
    return state.emailTableFilterParams
  },
}

const actions = {
  initEmailTable(context) {
    const emailTableParams = JSON.parse(
      localStorage.getItem('emailTableParams')
    )
    if (
      emailTableParams &&
      emailTableParams.dateFrom &&
      emailTableParams.dateTo
    ) {
      context.commit('setEmailTableParams', emailTableParams)
    }
    if (emailTableParams && emailTableParams.text != undefined) {
      context.commit('setTextSearchFilter', emailTableParams.text)
    }
    const emailTableFilterParams = JSON.parse(
      localStorage.getItem('emailTableFilterParams')
    )
    if (
      emailTableFilterParams &&
      emailTableFilterParams.text != undefined &&
      emailTableFilterParams.text != null
    ) {
      context.commit('setTextSearchFilter', emailTableFilterParams.text)
    }
  },
  async getEmails(context) {
    const token = context.getters.getAuthToken
    const params = {
      dateFrom: state.emailTableParams.dateFrom,
      dateTo: state.emailTableParams.dateTo,
    }
    const data = await EmailsAPI.get(token, params)
    context.commit('setEmails', data.emails)
  },
  async getEmail(context, payload) {
    const token = context.getters.getAuthToken
    const data = await EmailAPI.get(token, payload)
    return data
  },
  async getEmailAttachment(context, payload) {
    const token = context.getters.getAuthToken
    const response = await EmailAttachmentURLAPI.get(token, payload)
    return new Promise(() => {
      Axios.get(response.file_url, { responseType: 'blob' }).then(
        (fileResponse) => {
          let a = document.createElement('a')
          let blob = new Blob([fileResponse.data])
          let url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = response.file_name
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        }
      )
    })
  },
  async changeDateFrom(context, payload) {
    context.commit('setEmailTableParamsDateFrom', payload.dateFrom)
    localStorage.setItem(
      'emailTableParams',
      JSON.stringify(state.emailTableParams)
    )
    await context.dispatch('getEmails')
  },
  async changeDateTo(context, payload) {
    context.commit('setEmailTableParamsDateTo', payload.dateTo)
    localStorage.setItem(
      'emailTableParams',
      JSON.stringify(state.emailTableParams)
    )
    await context.dispatch('getEmails')
  },
  setTextSearchFilter(context, payload) {
    context.commit('setTextSearchFilter', payload.text)
    localStorage.setItem(
      'emailTableFilterParams',
      JSON.stringify(state.emailTableFilterParams)
    )
  },
}

const mutations = {
  setEmails(state, data) {
    state.emails = data
  },
  setEmailTableParams(state, data) {
    state.emailTableParams = data
  },
  setEmailTableParamsDateFrom(state, dateFrom) {
    state.emailTableParams.dateFrom = dateFrom
  },
  setEmailTableParamsDateTo(state, dateTo) {
    state.emailTableParams.dateTo = dateTo
  },
  setTextSearchFilter(state, text) {
    state.emailTableFilterParams.text = text
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
