import Axios from 'axios'
import Vue from 'vue'
import Snackbar from 'Helpers/snackbar/index'

function errorNotify(msg) {
  Snackbar.error(msg)
}

const state = {}

const getters = {}

const actions = {
  deleteVendor(context, payload) {
    return new Promise((resolve, reject) => {
      Axios.delete(context.getters.apiVendorUrl, {
        data: { id: payload.data.id },
        headers: { Authorization: context.getters.getAuthToken },
      })
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  editVendor(context, payload) {
    return new Promise((resolve, reject) => {
      Axios.put(
        context.getters.apiVendorUrl,
        {
          id: payload.data.id,
          name: payload.data.name,
          code: payload.data.code,
          status: payload.data.status,
          user_organization_id: context.getters.getUserOrganizationID,
          organization_id: context.getters.getOrganizationID,
        },
        {
          headers: { Authorization: context.getters.getAuthToken },
        }
      )
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  addVendor(context, payload) {
    return new Promise((resolve, reject) => {
      Axios.post(
        context.getters.apiVendorUrl,
        {
          name: payload.data.name,
          code: payload.data.code,
          status: payload.data.status,
          user_organization_id: context.getters.getUserOrganizationID,
          organization_id: context.getters.getOrganizationID,
        },
        {
          headers: { Authorization: context.getters.getAuthToken },
        }
      )
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
}

export default {
  state,
  getters,
  actions,
}
