<template>
  <div>
    <v-list-item v-for="message in messages" :key="message.id">
      <v-avatar
        size="25"
        color="grey lighten-2"
        class="mr-2 fs-10"
        v-text="message.full_name.slice(0, 2)"
      />
      <span class="fs-11">{{ message.content }}</span>
      <v-spacer />
      <v-chip
        v-if="message.judge_display_period != '表示中'"
        x-small
        label
        outlined
        class="pa-2 ml-2"
        v-text="message.judge_display_period"
      />
      <v-btn
        v-if="message.created_user_organization == loginUser"
        v-tooltip="'編集'"
        icon
        class="ml-3"
        @click="$emit('click-edit', message)"
      >
        <s-icon icon="feather-edit" />
      </v-btn>
      <v-btn
        v-if="message.created_user_organization == loginUser"
        v-tooltip="'削除'"
        icon
        @click="$emit('click-delete', message)"
      >
        <s-icon icon="feather-trash-2" />
      </v-btn>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
  computed: {
    loginUser() {
      return this.$store.getters.getUserOrganizationID
    },
  },
}
</script>
