<template>
  <v-menu v-if="editable" ref="menu" :offset-y="offsetY" :offset-x="offsetX">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        placeholder=" "
        :dense="flat"
        :class="(flat ? 'more-dense ' : 'narrow-date ') + className"
        style="max-width: 100px"
        :filled="flat"
        :clearable="clearable"
        :outlined="outlined"
        hide-details
        type="date"
        v-bind="attrs"
        tabindex="1"
        v-on="on"
        @focus="onFocusTextField"
        @blur="onBlurTextField"
      />
    </template>

    <v-date-picker
      v-model="date"
      locale="jp-ja"
      :no-title="noTitle"
      :day-format="(date) => new Date(date).getDate()"
      :max="max"
      :min="min"
      @input="onInput"
    />
  </v-menu>
  <v-text-field
    v-else
    v-model="date"
    :label="label"
    placeholder=" "
    :dense="flat"
    :class="(flat ? 'more-dense ' : 'narrow-date ') + className"
    style="max-width: 100px"
    :filled="flat"
    :outlined="outlined"
    hide-details
    type="date"
    readonly
  />
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'select',
  },
  props: {
    value: {
      validator: (prop) => typeof prop === 'string' || prop === null,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    flat: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    offsetX: {
      type: Boolean,
      default: false,
    },
    offsetY: {
      type: Boolean,
      default: true,
    },
    noTitle: {
      type: Boolean,
      default: true,
    },
    max: {
      type: String,
      default: undefined,
    },
    min: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      valueWithFocus: '',
    }
  },
  computed: {
    date: {
      get() {
        return this.value
      },
      set(val) {
        if (val) {
          this.$emit('select', val)
        } else {
          this.$emit('select', '')
        }
      },
    },
  },
  methods: {
    onFocusTextField() {
      this.valueWithFocus = this.date
    },
    onBlurTextField() {
      if (this.valueWithFocus !== this.date) {
        this.$emit('change')
      }
    },
    onInput() {
      this.$emit('change')
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
}
</script>
