//messages.js
export default {
  general: 'General',
  overview: 'Overview',
  customizer: 'Customizer',
  applications: 'Applications',
  features: 'Features',
  components: 'Components',
  salesAndVisitStats: 'Sales & Visits Stats',
  orderAndProjectStats: 'Order & Projects Stats',
  fitnessStats: 'Fitness Stats',
  supportAndUsefulWidgets: 'Support & Useful Widgets',
  dashboard: 'Dashboard',
  dashboardOverview: 'Dashboard Overview',
  dashboardv1: 'Dashboard V1',
  dashboardv2: 'Dashboard V2',
  widgets: 'Widgets',
  horizontalMenu: 'Horizontal Menu',
  pages: 'Pages',
  gallery: 'Gallery',
  pricing1: 'Pricing 1',
  pricing2: 'Pricing 2',
  blank: 'Blank',
  session: 'Session',
  signUp1: 'Sign Up 1',
  signUp2: 'Sign Up 2',
  login1: 'Login 1',
  login2: 'Login 2',
  lockScreen: 'Lock Screen',
  uiElements: 'UI Elements',
  buttons: 'Buttons',
  cards: 'Cards',
  checkbox: 'Checkbox',
  carousel: 'Carousel',
  chips: 'Chips',
  datepicker: 'Datepicker',
  dialog: 'Dialog',
  grid: 'Grid',
  input: 'Input',
  list: 'List',
  menu: 'Menu',
  progress: 'Progress',
  radio: 'Radio',
  select: 'Select',
  slider: 'Slider',
  snackbar: 'Snackbar',
  tabs: 'Tabs',
  toolbar: 'Toolbar',
  tooltip: 'Tooltip',
  timepicker: 'Timepicker',
  forms: 'Forms',
  formValidation: 'Form Validation',
  stepper: 'Stepper',
  charts: 'Charts',
  vueChartjs: 'Vue Chartjs',
  vueEcharts: 'Vue Echarts',
  icons: 'Icons',
  themify: 'Themify',
  material: 'Material',
  tables: 'Tables',
  standard: 'Standard',
  slots: 'Slots',
  selectable: 'Selectable',
  searchRow: 'Search Row',
  maps: 'Maps',
  googleMaps: 'Google Maps',
  leafletMaps: 'Leaflet Maps',
  jvectorMap: 'Jvector Map',
  inbox: '回収',
  titleEmail: 'メール取込結果',
  titleEmailDetail: 'メール詳細',
  requestBilling: '請求依頼',
  users: 'Users',
  userProfile: 'User Profile',
  usersList: 'Users List',
  calendar: 'Calendar',
  editor: 'Editor',
  quillEditor: 'Quill Editor',
  wYSIWYG: 'WYSIWYG',
  vue2Dragula: 'Vue2 Dragula',
  dragAndDrop: 'Drag And Drop',
  vueDraggable: 'Vue Draggable',
  draggableResizeable: 'Draggable Resizeable',
  invoice: 'Invoice',
  todo: 'Todo',
  modules: 'Modules',
  user: 'ユーザ',
  miscellaneous: 'Miscellaneous',
  promo: 'Promo',
  connections: 'Connections',
  follow: 'Follow',
  unfollow: 'Unfollow',
  basicValidation: 'Basic Validation',
  validationWithSubmitAndclear: 'Validation with submit & clear',
  submit: '更新',
  clear: 'クリア',
  step1: 'Step 1',
  step2: 'Step 2',
  step3: 'Step 3',
  continue: '続行',
  cancel: 'Cancel',
  totalEarnings: 'Total Earnings',
  onlineRevenue: 'Online Revenue',
  offlineRevenue: 'Offline Revenue',
  marketingExpenses: 'Marketing Expenses',
  newCustomers: 'New Customers',
  visitAndSalesStatistics: 'Visit & Sales Statistics',
  collapse: 'Collapse',
  reload: 'Reload',
  close: '閉じる',
  bandwidthUsage: 'Bandwidth Usage',
  shoppingCart: 'Shopping Cart',
  totalDownloads: 'Total Downloads',
  productSales: 'Product Sales',
  projectStatus: 'Project Status',
  averageSteps: 'Average Steps',
  todaysDistance: "Today's Distance",
  todaysStep: "Today's Step",
  todaysGoal: "Today's Goal",
  calories: 'Calories',
  weeklySummary: 'Weekly Summary',
  supportTickets: 'Support Tickets',
  todoList: 'To Do List',
  newsletterCampaign: 'Newsletter Campaign',
  currentVisitors: 'Current Visitors',
  newMembers: 'New Members',
  addNewBlog: 'Add New Blog',
  add: '追加',
  edit: '編集',
  logOut: 'ログアウト',
  totalAppMemory: 'Total App Memory',
  totalMemoryUsed: 'Total Memory Used',
  unreadMail: '12 Unread Mail',
  feedback: 'Feedback',
  flatButton: 'Flat Button',
  raisedButton: 'Raised Button',
  gradientButton: 'Gradient Button',
  buttonDropdownVariants: 'Button Dropdown Variants',
  buttonToggle: 'Button Toggle',
  icon: 'Icon',
  normal: 'Normal',
  disabled: 'Disabled',
  floating: 'Floating',
  loaders: 'Loaders',
  sizing: 'Sizing',
  outline: 'Outline',
  round: 'Round',
  block: 'Block',
  search: 'Search',
  checkboxesBoolean: 'Checkboxes Boolean',
  checkboxesArray: 'Checkboxes Array',
  checkboxesStates: 'Checkboxes - States',
  checkboxesColors: 'Checkboxes - Colors',
  switches: 'Switches',
  default: 'Default',
  customTransition: 'Custom Transition',
  customDelimiter: 'Custom Delimiter',
  hideControls: 'Hide Controls',
  hideDelimiters: 'Hide Delimiters',
  colored: 'Colored',
  label: 'Label',
  closeable: 'Closeable',
  datePickerHorizontal: 'Date Picker Horizontal',
  datePickerVertical: 'Date Picker Vertical',
  datePickersInDialogAndMenu: 'Date Pickers- In Dialog And Menu',
  datePickersWithModal: 'Date Pickers With Modal',
  datePickersFormattingDate: 'Date Pickers - Formatting Date',
  simpleDialogs: 'Simple Dialogs',
  withoutActivator: 'Without Activator',
  scrollable: 'Scrollable',
  listOfAllMaterialIcons: 'List Of All Material Icons',
  arrowsAndDirectionsIcons: 'ARROWS & DIRECTION ICONS',
  webAppIcons: 'WEB APP ICONS',
  CONTROLICONS: 'CONTROL ICONS',
  TEXTEDITOR: 'TEXT EDITOR',
  LAYOUTICONS: 'LAYOUT ICONS',
  BRANDICONS: 'BRAND ICONS',
  newClients: 'New Clients',
  recurringClients: 'Recurring Clients',
  bounceRate: 'Bounce Rate',
  pageViews: 'Page Views',
  usefulWidgets: 'Useful Widgets',
  sale: 'Sale',
  sales: 'Sales',
  invoices: 'Invoices',
  referrals: 'Referrals',
  serverLoad: 'Server Load',
  recentSale: 'Recent Sale',
  supportRequest: 'Support Request',
  productSale: 'Product Sale',
  viewAll: 'VIEW ALL',
  barChart: 'Bar Chart',
  polarAreaChart: 'Polar Area Chart',
  lineChart: 'Line Chart',
  radarChart: 'Radar Chart',
  doughnutChart: 'Doughnut Chart',
  bubbleChart: 'Bubble Chart',
  gradientLineChart: 'Gradient Line Chart',
  pieChart: 'Pie Chart',
  funnelChart: 'Funnel Chart',
  loginNow: 'ログイン',
  createAccount: 'アカウントが未登録のお客様',
  termsOfService: '利用規約',
  bySigningUpYouAgreeTo: 'への同意が必要です',
  loginToAdmin: 'ログイン',
  enterUsernameAndPasswordToAccessControlPanelOf:
    'メールアドレスとパスワードを入力しログインください',
  havingAnAccount: 'アカウント登録済みのお客様',
  quickLinks: 'Quick Links',
  plans: 'Plans',
  chooseThePlanThatWorksForYou: 'Choose the plan that works for you.',
  monthly: 'Monthly',
  yearlyGet2MonthExtra: 'Yearly ( get 2 month extra)',
  basic: 'Basic',
  pro: 'Pro',
  advanced: 'Advanced',
  basicFree: 'Basic (Free)',
  startToBasic: 'Start To Basic',
  upgradeToPro: 'Upgrade To Pro.',
  upgradeToAdvance: 'Upgrade To Advance',
  comparePlans: 'Compare Plans',
  frequentlyAskedQuestions: 'Frequently Asked Questions',
  defaultInput: 'Default Input',
  activator: 'Activator',
  hover: 'Hover',
  menus: 'Menus',
  indeterminate: 'Indeterminate',
  sizeAndWidth: 'Size & Width',
  rotate: 'Rotate',
  determinate: 'Determinate',
  buffer: 'Buffer',
  radiosDefault: 'Radios Default',
  radiosDirectionRow: 'Radios Direction Row',
  radiosDirectionColumn: 'Radios Direction Column',
  radiosColors: 'Radios - Colors',
  switchesColors: 'Switches - Colors',
  continuous: 'Continuous',
  discrete: 'Discrete',
  customColors: 'Custom Colors',
  editableNumericValue: 'Editable numeric value',
  contextualSnackbar: 'Contextual snackbar',
  showSnackbar: 'Show Snackbar',
  centeredTabs: 'Centered Tabs',
  toolbarTabs: 'Toolbar Tabs',
  theDefaultColorToolbar: 'The Default Color Toolbar',
  appBar: 'App Bar',
  appBarWithExtension: 'App Bar With Extension',
  visibility: 'Visibility',
  top: 'Top',
  right: 'Right',
  bottom: 'Bottom',
  left: 'Left',
  toggle: 'Toggle',
  timePicker: 'Time Picker',
  timePickerInDialogAndMenu: 'Time Picker - In Dialog And Menu',
  itemsAndHeaders: 'Items And Headers',
  selectableRows: 'Selectable Rows',
  headerCell: 'Header Cell',
  expand: 'Expand',
  recentInvoice: 'Recent Invoice',
  previousInvoices: 'Previous invoices',
  addNewItems: 'Add New Items',
  addToDo: 'Add To Do',
  yes: 'はい',
  no: 'いいえ',
  ok: 'Ok',
  activeUsers: 'Active Users',
  addNew: 'Add New',
  readMore: 'Read More',
  assignNow: 'Assign Now',
  totalRequest: 'Total Request',
  new: 'New',
  pending: 'Pending',
  update: 'Update',
  updated10MinAgo: 'Updated 10 min ago',
  addNewCustomer: 'Add New Customer',
  name: 'Name',
  email: 'メール',
  editMember: 'Edit Member',
  scheduleDate: 'Schedule Date',
  title: 'Title',
  newEmails: 'New Emails',
  newEmail: 'New Email',
  employeePayroll: 'Employee Payroll',
  forMostOfTheUsers: 'For most of the users',
  choosePlan: 'Choose Plan',
  mega: 'Mega',
  forDeveloper: 'For developer',
  forLargeEnterprises: 'For large enterprises',
  composeEmail: 'Compose Email',
  mailboxes: 'Mailboxes',
  folders: 'Folders',
  inbox5: 'Inbox (5)',
  draft: 'Draft',
  starred: 'Starred',
  sentMessages: 'Sent Messages',
  spam: 'Spam',
  delete: '削除',
  work: 'Work',
  personal: 'Personal',
  manageFolders: 'Manage Folders',
  contacts: 'Contacts',
  themeOptions: 'Theme Options',
  darkMode: 'Dark Mode',
  collapseSidebar: 'Collapse Sidebar',
  boxLayout: 'Box Layout',
  rtlLayout: 'Rtl Layout',
  backgroundImage: 'Background Image',
  sidebarFilters: 'Sidebar Filters',
  danger: 'Danger',
  primary: 'Primary',
  warning: 'Warning',
  success: '成功',
  info: 'Info',
  dailySales: 'Daily Sales',
  trafficChannel: 'Traffic Channel',
  campaignPerformance: 'Campaign Performance',
  goToCampaign: 'Go To Campaign',
  fullScreen: 'Full Screen',
  ecommerce: 'E-Commerce',
  shop: 'Shop',
  cart: 'Cart',
  total: 'Total',
  totalPrice: 'Total Price',
  viewCart: 'View Cart',
  checkout: 'Checkout',
  apply: 'Apply',
  noItemsFound: 'No Items Found',
  billingDetails: 'Billing Details',
  placeOrder: 'Place Order',
  addNewCard: 'Add New Card',
  devicesShare: 'Devices Share',
  customerOverview: 'Customer Overview',
  reviews: 'Reviews',
  weeklySales: 'Weekly Sales',
  recentOrders: 'Recent Orders',
  categorySales: 'Category Sales',
  webAnalytics: 'Web Analytics',
  topSelling: 'Top Selling',
  social: 'Social',
  newPost: 'New Post',
  publish: 'Publish',
  magazine: 'Magazine',
  editUser: 'Edit User',
  addNewUser: 'Add New User',
  dark: 'Dark',
  light: 'Light',
  recentUsers: 'Recent Users',
  activityAroundWorld: 'Activity Around World',
  adsIncome: 'Ads Income',
  recentComments: 'Recent Comments',
  recentNotifications: 'Recent Notifications',
  messages: 'Messages',
  topAuthors: 'Top Authors',
  marketingCampaign: 'Marketing Campaign',
  articles: 'Articles',
  websiteTraffic: 'Website Traffic',
  agency: 'Agency',
  recent: 'Recent',
  trending: 'Trending',
  totalPageViews: 'Total Page Views',
  impressions: 'Impressions',
  deviceSeparations: 'Device Separations',
  browserStatics: 'Browser Statics',
  totalDownloading: 'Total Downloading',
  notifications: 'Notifications',
  totalBookmarked: 'Total Bookmarked',
  itemsDownloads: 'Items Downloads',
  itemsUploaded: 'Items Uploaded',
  totalAnalytics: 'Total Analytics',
  hotKeywords: 'Hot Keywords',
  MaleUsers: 'Male Users',
  serverStatus: 'Server Status',
  purchaseVuely: 'Purchase Vuely',
  letsGetInTouch: 'Lets Get In Touch',
  maleUsers: 'Male Users',
  femaleUsers: 'Female Users',
  androidUsers: 'Android Users',
  iOSUsers: 'iOS Users',
  advance: 'Advance',
  routerAnimation: 'Router Animation',
  salesAndEarning: 'Sales And Earning',
  netProfit: 'Net Profit',
  totalExpences: 'Total Expences',
  photos: 'Photos',
  adCampaignPerfomance: 'Ad Campaign Perfomance',
  profitShare: 'Profit Share',
  topSellingTheme: 'Top Selling Theme',
  newRequest: 'New Request',
  followers: 'Followers',
  mailbox: 'Mailbox',
  sent: 'Sent',
  birthdays: 'Birthdays',
  events: 'Events',
  newsletter: 'Newsletter',
  lastMonth: 'Last Month',
  allTime: 'All Time',
  composeNewEmail: 'Compose New Email',
  activity: 'Activity',
  message: 'Message',
  selectTheme: 'Select Theme',
  continueShopping: 'Continue Shopping',
  proceedToCheckout: 'Proceed To Checkout',
  headerFilters: 'Header Filters',
  blog: 'Blog',
  signUp: 'サインアップ',
  signUpAction: '規約に同意して登録',
  login: 'ログイン',
  news: 'News',
  topHeadlines: 'Top Headlines',
  visitors: 'Visitors',
  subscribers: 'Subscribers',
  twitterFeeds: 'Twitter Feeds',
  extensions: 'Extensions',
  imageCropper: 'Image Cropper',
  videoPlayer: 'Video Player',
  dropzone: 'Dropzone',
  baseConfig: 'Base Config',
  audioTrackAndPlaysInline: 'Audio Track And Plays Inline',
  hlsLive: 'HLS Live',
  forgotPassword: 'パスワードをお忘れの場合',
  resetPassword: 'パスワードをリセット',
  backToSignIn: 'ログイン画面に戻る',
  enterYourEmailToSendYouAResetLink: 'メールアドレスを入力してください',
  pleaseEnterYourPasswordToReset: 'Please Enter Your Password To Reset',
  invoice: '請求書',
  payment: '支払',
  confirm: '確認',
  myCompanyData: '会社情報',
  accountData: 'アカウント情報',
  userData: 'ユーザ情報',
  accounting: '勘定科目',
  department: '部門',
  company: '請求元リスト',
  user: 'ユーザ',
  master: 'マスタ情報',
  taxClass: '税区分',
  settings: '管理者設定',
  upload: '読み取り',
  dashboard: 'ダッシュボード',
  report: 'レポート',
  bookingDate: '伝票日付',
  application: 'Application',
  paymentDate: '支払日',
  ipRestriction: 'IP制限',
  emailCapture: 'メール取り込み',
  freee: 'freee同期',
  item: '品目',
  tag: 'メモタグ',
  walletable: '口座',
  export_settings: 'Export Setting',
  exportSettings: '仕訳エクスポート設定',
  change: '変更',
  display_settings: '表示設定',
  bulk_edit: '一括編集',
  bulk_delete: '一括削除',

  //reset password
  reset_password_success: 'パスワードの再設定が完了しました。',
  reset_password_click_login: 'ログインにはこちらをクリックしてください。',
  //login
  login_invalid_user_pass: '「メールアドレスまたはパスワードが間違っています」',
  login_unable: 'このユーザではログインできません',
  save: '保存',
  //invoice table header
  invoice_table_description: '摘要',
  invoice_table_free_text_1: '備考1',
  invoice_table_free_text_2: '備考2',
  invoice_table_export: 'エクスポート',
  invoice_table_upload: '読み取り',
  invoice_table_upload_type: '読取経路',
  invoice_table_update: '更新',
  invoice_table_invoice_local_id: '伝票No.',
  invoice_table_date: '日付',
  invoice_table_reading_date: '読み取り日',
  invoice_table_invoice_date: '伝票日付',
  invoice_table_payment_date: '支払日付',
  invoice_table_business_partner: '請求元',
  invoice_table_is_invoice_issuer: '適格請求',
  invoice_table_debit_account_item: '借方科目',
  invoice_table_debit_subsidiary_item: '借方補助科目',
  invoice_table_debit_tax_classification: '借方税区分',
  invoice_table_debit_with_tax_amount: '借方税込額',
  invoice_table_debit_consumption_tax_amount: '借方税額',
  invoice_table_gensen_amount: '源泉税額',
  invoice_table_credit_account_item: '貸方科目',
  invoice_table_credit_subsidiary_item: '貸方補助科目',
  invoice_table_credit_tax_classification: '貸方税区分',
  invoice_table_credit_with_tax_amount: '貸方税込額',
  invoice_table_credit_consumption_tax_amount: '貸方税額',
  invoice_table_status: 'ｽﾃｰﾀｽ',
  invoice_table_approve: '承認',
  invoice_table_memo: 'メモ',
  invoice_table_approve_user: '承認',
  invoice_table_confirm_user: '確認',
  invoice_table_create_pay_journal: '支払仕訳作成',
  invoice_table_privilege: '権限',
  invoice_list_create_pay_journal: '支払連携',
  invoice_list_payment: '支払',
  invoice_list_payment_confirm_1: '確認1',
  invoice_list_payment_confirm_2: '確認2',
  invoice_list_payment_confirm_3: '確認3',
  invoice_list_payment_approved: '連携済',
  invoice_list_payment_paid: '支払済',
  invoice_list_payment_confirm_1_short: '確1',
  invoice_list_payment_confirm_2_short: '確2',
  invoice_list_payment_confirm_3_short: '確3',
  invoice_list_payment_approved_short: '連携',
  invoice_list_payment_paid_short: '払済',
  invoice_list_gensenzei: '源泉',
  invoice_list_all_export: '一覧エクスポート',
  invoice_list_export: '仕訳エクスポート',
  invoice_report_export: 'レポートエクスポート',
  invoice_list_export_resport: '仕訳エクスポート(Resport用)',
  invoice_last_approve: '最終承認',
  invoice_bulk_editing: '一括編集',
  invoice_list_show_all_journals: '全て展開',
  invoice_list_hide_all_journals: '全て折り畳む',
  invoice_table_debit_department: '借方部門',
  invoice_table_credit_department: '貸方部門',
  invoice_table_credit_project: '貸方ﾌﾟﾛｼﾞｪｸﾄ',
  invoice_table_debit_project: '借方ﾌﾟﾛｼﾞｪｸﾄ',
  invoice_table_credit_segment: '貸方ｾｸﾞﾒﾝﾄ',
  invoice_table_debit_segment: '借方ｾｸﾞﾒﾝﾄ',
  invoice_table_credit_vendor: '貸方取引先',
  invoice_table_debit_vendor: '借方取引先',
  invoice_table_debit_item: '借方品目',
  invoice_table_debit_tag: '借方メモタグ',
  invoice_table_debit_walletable: '借方口座',
  invoice_table_credit_item: '貸方品目',
  invoice_table_credit_tag: '貸方メモタグ',
  invoice_table_credit_walletable: '貸方口座',
  invoice_table_payment_bank: '支払先口座',
  invoice_table_payment_type: '方法',
  invoice_table_export_count: '仕訳出力',
  invoice_table_invoice_status: '仕訳状況',
  invoice_table_payment_status: '支払状況',
  invoice_table_pay_export_count: '支払仕訳出力',
  invoice_table_journal_row_count: '明細数',
  //end invoice table header
  //invoice_upload
  invoice_upload_no: 'No.',
  invoice_upload_thumbnail: 'サムネイル',
  invoice_upload_filename: 'ファイル名',
  invoice_upload_size: 'サイズ',
  invoice_upload_way: '経路',
  invoice_upload_status: 'ステータス',
  invoice_upload_on_request_error: '問題が発生しました。',
  invoice_upload_on_error_process: '読み取り失敗',
  invoice_upload_on_invalid_file_type: '無効入力',
  invoice_upload_contact_support: 'サポートにご連絡ください',
  //end invoice upload
  //invoice_detail
  invoice_approve_success:
    '請求書を最終承認し、仕訳がロックされました。仕訳を編集する場合は承認解除を行ってください。',
  invoice_cancel_approve_success: '最終承認が解除されました。',
  invoice_delete_success: '請求書が削除されました。',
  invoice_restore_success: '請求書が復元されました。',
  invoice_delete_multiple_reference:
    '削除しました。この請求書ファイル（PDF）は他の伝票でも使われており、同伝票から引き続き閲覧可能です。',
  invoice_confirm_import_multiple_reference:
    'この伝票の請求書ファイルのみを差し替えます。',
  invoice_confirm_import_image_to_approved_invoice:
    '承認済みの請求書ですが、ファイルを差し替えますか？',
  invoice_pay_approve_confirmation: '支払承認しますか？',
  invoice_pay_approve_confirmation2:
    '支払承認すると登録内容にロックがかかります。',
  invoice_paid_confirmation: '支払済みにしますか？',
  invoice_paid_confirmation2: '支払済みにすると登録内容にロックがかかります。',
  invoice_pay_cancel_approve: '支払メニューへの連携を解除しますか？',
  invoice_debit_account_title_name: '借方勘定科目',
  invoice_debit_sub_account_name: '借方補助科目',
  invoice_debit_tax_name: '借方税区分',
  invoice_debit_department_name: '借方部門',
  invoice_debit_project_name: '借方プロジェクト',
  invoice_debit_segment_name: '借方セグメント',
  invoice_debit_vendor_name: '借方取引先',
  invoice_credit_account_title_name: '貸方勘定科目',
  invoice_credit_sub_account_name: '貸方補助科目',
  invoice_credit_tax_name: '貸方税区分',
  invoice_credit_department_name: '貸方部門',
  invoice_credit_project_name: '貸方プロジェクト',
  invoice_credit_segment_name: '貸方セグメント',
  invoice_credit_vendor_name: '貸方取引先',
  invoice_gensenzei: '源泉対象',
  invoice_spot_payment: 'spot',
  invoice_description: '摘要',
  invoice_free_text_1: '備考1',
  invoice_free_text_2: '備考2',
  invoice_set_pay_date: '「支払日付」を再計算しますか？',
  invoice_amount_edit_hint:
    '金額を変更する場合は、仕訳部分の金額を修正してください。',
  invoice_amount_candidates_hint: '金額の読取り結果を表示',

  payment_approve_success:
    '支払承認が完了しました。支払内容・支払時の仕訳を編集する場合は承認を解除してください。',
  payment_cancel_approve_success: '支払承認が解除されました。',
  //end invoice_detail

  //PaymentHeaderFilter
  payment_exported: '出力済データ',
  payment_paid: '支払済データ',
  payment_all: '全てのデータ',

  //payment table header
  payment_table_payment_local_id: '支払No.',
  payment_table_issue_date: '伝票日付',
  payment_table_payment_date: '支払日',
  payment_table_payment_way: '方法',
  payment_table_business_partner: '請求元',
  payment_table_bank_code: 'コード',
  payment_table_bank_name: '金融機関',
  payment_table_bank_branch_code: 'コード',
  payment_table_bank_branch_name: '支店',
  payment_table_bank_type: '種別',
  payment_table_bank_number: '番号',
  payment_table_bank_user_name: '口座名義人名',
  payment_table_user_bank: '振込元口座',
  payment_table_amount: '請求額',
  payment_table_gensen_amount: '源泉税額',
  payment_table_pay_amount: '支払額',
  payment_table_export: 'エクスポート',
  payment_journal_data_export: '仕訳エクスポート',
  payment_payment_data_export: '振込データ出力',
  payment_table_export_count: '仕訳',
  payment_table_payment_status: '支払',
  payment_table_transfer_fee: '先方負担振込手数料',
  payment_table_pay_amount_deduct_transfer_fee: '振込手数料控除後支払額',
  payment_list_export: '振込データのエクスポート',
  including_paid: '支払済含む',
  payment_cancel_paid: '支払解除',
  payment_paid_status: '支払済とする',
  payment_cancel_pay_approve: '支払連携解除',
  payment_change_user_bank: '振込元口座変更',
  //end payment table header

  //payment dialogs
  payment_title_approve_paid: '支払済ステータス適用',
  payment_message_approve_paid:
    'チェック済データを支払済ステータスに変更します。（解除可）',
  payment_edit_transfer_fee: '編集',
  //end payment dialogs

  //signupPage
  signup_business_form_1: '法人新規',
  signup_business_form_2: '個人新規',
  signup_business_form_3: '法人追加',
  signup_organization_name: '会社名',
  signup_individual: 'お名前',
  signup_email: 'メールアドレス',
  signup_sent_message_line1: '登録したメールアドレス宛にメールを送信しました。',
  signup_sent_message_line2: 'リンクをクリックして登録を完了させてください。',
  signup_sent_message_line3:
    '@sweeep.ai ドメインからのメール受信を許可してください。',
  signup_sent_message_line4:
    '許可しない場合は迷惑メールフォルダに保存されることがあります。',
  //end signupPage
  //signup confirm
  signup_confirm_account_activated: 'アカウントが利用可能になりました。',
  signup_confrim_password_match_error: 'パスワードが違います',
  signup_confirm_full_name: '氏名（フルネーム）',
  signup_confirm_full_name_hint: '例：山田太郎',
  signup_confirm_password: 'パスワード',
  signup_confirm_confirmpassword: 'パスワードの確認',
  signup_confirm_new: '利用を開始する',
  signup_confirm_already: '招待を受ける',
  signup_confirm_message_success:
    'サインアップが終了しました。ログインしてください。',
  error_password_and_confirm_not_same:
    'パスワードとパスワードの確認が一致しません。',
  //end signup confirm
  // sort_num
  sort_num: '表示順',
  //department
  department_add_btn: '追加',
  department_code: '部門コード',
  department_name: '部門名',
  department_delete_message: '削除しますか?',

  //modal actions
  modal_save_btn: '保存',
  modal_yes_btn: 'はい',
  modal_no_btn: 'いいえ',
  modal_cancel_pay_approve: '連携解除',
  modal_cancel_btn: 'キャンセル',
  modal_import_btn: 'インポート',
  modal_close_btn: '閉じる',
  modal_send_btn: '送信',
  //table actions
  table_edit_btn: '編集',
  table_delete_btn: '削除',
  reset_password_newpassword: '新パスワード',
  reset_passwrod_repeatnewPassword: '新パスワード（確認）',
  invite: '招待する',
  noResult: '指定された検索条件のデータはありません。',
  //form
  form_input_required: '※この項目は必須です',
  form_input_password_format: '最低8文字以上のパスワードを設定してください。',
  // master
  master_export: 'エクスポート',

  // save_sort_num
  save_sort_num: '表示順更新',
  save_sort_num_dialog_massage:
    '表示順位を一括で変更できます。並び替え方法を選択して下さい。',
  save_sort_num_dialog_items: '並び変え項目',
  save_sort_num_dialog_order: '並び順',
  edit_sort_num: '編集',

  //accounting
  add: '追加',
  accounting_import_account: 'インポート',
  accounting_get_sample: 'インポートCSV取得',
  accounting_add_tax_classification: '税区分',
  accounting_delete_classification: 'この税区分を削除します。続行しますか？',
  accounting_delete_account: 'この勘定科目を削除します。続行しますか？',
  accounting_delete_supplementary: 'この補助科目を削除します。続行しますか？',
  accounting_account_name: '勘定科目',
  accounting_supplementary_name: '補助科目',
  accounting_tax_classification_name: '税区分',
  accounting_title_name: '勘定科目名',
  accounting_title_code: '勘定科目コード',
  accounting_tax_code: '税区分コード',
  accounting_tax_name: '税区分',
  accounting_tax_rate: '税率',
  accounting_table_add_to: '追加',
  accounting_table_cancel: 'キャンセル',
  //company list
  company_list_delete_message: 'この仕訳パターンを削除します。続行しますか？',
  company_list_subject: '借方補助科目',
  company_table_company_name: '請求元',
  company_table_invoice_issuer_number: 'インボイス番号',
  company_table_company_address: '住所',
  company_table_company_phonenumber: '電話番号',
  company_table_email: 'メールアドレス',
  company_table_debit_account_title_name: '借方勘定科目',
  company_table_debit_subject_name: '借方補助科目',
  company_table_debit_tax_name: '借方税区分',
  company_table_debit_department_name: '借方部門',
  company_table_credit_account_title_name: '貸方勘定科目',
  company_table_credit_subject_name: '貸方補助科目',
  company_table_credit_tax_name: '貸方税区分',
  company_table_credit_department_name: '貸方部門',
  company_table_abstract: '摘要',
  company_table_scanned_company_phonenumber: '読取り電話番号',
  company_table_gensenzei: '源泉',
  company_table_pay_site: '支払日',
  company_billing_represent_name: '請求元担当者',
  company_billing_represent_deparment: '請求元担当者部門',
  company_success_edit: '設定の更新が完了しました。',
  company_send_email: '送信',
  company_confirm_upload: '請求書確認',
  temp_upload_title: '請求書アップロード',
  summary: '摘要',
  //my company
  my_company_copy_invoice: '未払仕訳をコピー',
  my_company_use: '使用する',
  my_company_account_title: '勘定科目',
  my_company_subsidiary_item: '補助科目',
  my_company_department: '部門',
  my_company_vendor: '取引先',
  my_company_segment: 'セグメント',
  my_company_project: 'プロジェクト',
  //end my company

  //user setting
  user_setting_changepassword: 'パスワード変更',
  user_setting_password: 'パスワード',
  user_setting_success_password: 'パスワードが登録されました。',
  user_setting_current_password: '現在のパスワード',
  user_setting_changeEmail: 'アドレス変更',
  //users
  users_invite_sent: '招待しました。',
  user_sending_invitation: 'メールを送信しています',
  //user modal
  user_modal_cancel_invitation: '招待を中止します。続行しますか？',
  user_modal_delete_user: 'このユーザを削除します。続行しますか？',
  user_modal_delete: '削除',
  user_code: 'ユーザコード',
  //user edit modal
  user_modal_edit: '更新する',
  //user table
  user_table_email: 'メールアドレス',
  user_table_invited_email: '招待先メールアドレス',
  user_table_valid_until: '招待の有効期限',
  user_table_role_name: '権限',
  user_table_invited_by: '招待者',
  user_table_cancel: 'キャンセル',
  user_table_full_name: '名前',
  user_table_department: '所属部門',
  //user setting
  user_setting_changepassword: 'パスワード変更',
  user_setting_password: 'パスワード',
  user_setting_success_password: 'パスワードが登録されました。',
  //upload page
  upload_tbl_uploading: '解析中...',
  upload_tbl_success: '読み取り完了',
  upload_tbl_no_data: 'この値は取得できませんでした。',
  //login
  login_invalid_username_or_password: 'Invalid Email or Password',
  //404 page
  button_message_404: 'ログインにはこちらをクリックしてください。',
  message_404: 'ページが見つかりません',

  // General message
  complete: '完了',
  name_already_in_use: '名前は使用中です。別の名前を使用してください。',
  invalid_input: '無効入力',
  learning: '学習',
  form_input_required_number: '※必須です（数字のみ入力可）',
  form_input_required_above_zero: '1以上を入力してください',
  form_input_required_under_256: '255以下を入力してください',

  confirm_delete: '請求書を削除しますか?',
  confirm_delete_general: 'この仕訳明細を削除しますか？',
  confirm_delete_company_pattern: 'この仕訳パターンを削除しますか？',
  confirm_clear_record: 'この仕訳明細の入力内容をクリアしますか？',
  confirm_clear_all_record: '全ての仕訳明細の入力内容をクリアしますか？',
  addedJournal: '明細行を追加しました。',
  copyedJournal: '明細行をコピーしました。',
  clearedOneJournal: '明細をクリアしました。',
  clearedAllJournals: '全明細をクリアしました。',
  deletedOneJournal: '明細を削除しました。',
  deletedAllJournals: '全明細を削除しました。',
  // Organization
  settlement_day: '決済日',
  settlement_month: '決済月',
  // Email invite confirm
  invite_already_activated: '招待は有効になっています。',
  invite_has_expired: '招待は期限切れです。',
  invalid_invitation:
    'この招待URLは無効です。招待が取り消された可能性があります',
  //character length
  character_max_length: '文字以内で入力ください',
  password_validation:
    '大文字英字、小文字英字、数字、記号を全て含んだ8文字以上で設定ください。',
  //invoice list
  selected_invoices_approved: '選択した請求書が正常に承認されました。',
  selected_invoices_edited: '選択した請求書が正常に編集されました。',
  selected_invoices_deleted: '選択した請求書が正常に削除されました。',
  approve_all_invoices_confirm:
    '選択した請求書をすべて承認（最終承認）します。',
  delete_all_invoices_confirm: '選択した請求書をすべて削除してよろしいですか? ',
  delete_all_invoices_confirm2:
    'よい場合はDELETEと入力し、削除をクリックして下さい。',
  no_invoice_selected: '選択した請求書がありません。',

  //invoiceHeaderFilter
  invoice_exported: '出力済のデータ',
  invoice_not_exported: '未出力のデータ',
  invoice_all: '全てのデータ',
  filterBarTooltip: `入力した文字とマッチ（部分一致）する請求書を抽出します。
<br>請求書に紐づく全ての項目に対してフィルタを適用します。`,
  description: '摘要',
  free_text_1: '備考1',
  free_text_2: '備考2',

  // inbox
  uncollect_message:
    'アップロードされたファイルは削除されます。未請求に戻しますか？',
  disapprove_message: '一度否認すると元に戻せません。否認しますか？',
  delete_item_message: '一度削除すると元に戻せません。削除しますか？',
  uncollect_error_title: 'エラー',
  uncollect_error_message:
    '未請求に戻せません。（自分でアップロードした場合のみ未請求に戻せます）',
  include_deleted_user_message:
    '削除されているユーザが自社担当者に設定されています',
  filter_bar_tooltip_on_inbox_customers: `入力した文字とマッチ（部分一致）する取引先を抽出します。
<br>取引先・自社担当者に対してフィルタを適用します。`,
  get_customers_csv: '取引先一覧CSV取得',
  import_csv_update_message: '上書き登録する',
  import_csv_create_message: '新しく追加する',
  inbox_multiple_upload_message:
    '一度に複数ファイルを送信できません。一つずつお送りください。',

  // Master
  master_bulk_delete_message: '選択した項目を一括削除します。',

  // Vendor
  vendor: '取引先',
  vendor_name: '取引先名',
  vendor_code: '取引先コード',
  vendor_add_dialog_header: '取引先追加',
  vendor_delete_message: '削除しますか?',
  vendor_edit_dialog_header: '取引先編集',

  // Bank
  bank_name: '銀行名',
  bank_code: 'コード',
  bank_branch_name: '支店名',
  bank_branch_code: 'コード',
  bank_account_type: '口座種別',
  bank_account_name: '口座名義',
  bank_account_number: '口座番号',
  bank_account_number_form_message: '7桁以内の半角数字で入力してください。',
  bank_account_name_form_message_length: '48文字以内で入力してください。',
  bank_account_name_form_message_name_characters:
    'カタカナ・半角数字・半角大文字英字・半角記号・スペース ()./- で入力してください。',
  bank_not_entered_message: '支払タブに未入力の項目があります。',

  // Project
  project: 'プロジェクト',
  project_name: 'プロジェクト名',
  project_code: 'プロジェクトコード',
  project_add_dialog_header: 'プロジェクト追加',
  project_delete_message: '削除しますか?',
  project_edit_dialog_header: 'プロジェクト編集',
  project_local_bank_code: '管理用口座情報コード',
  project_bank_master_name: '銀行マスタ',
  project_bank_name: '銀行名',
  project_bank_code: '銀行コード',
  project_bank_branch_name: '支店名',
  project_bank_branch_code: '支店コード',
  project_bank_account_type: '口座種別',
  project_bank_account_name: '口座名義',
  project_bank_account_number: '口座番号',

  // Segment
  segment: 'セグメント',
  segment_name: 'セグメント名',
  segment_code: 'セグメントコード',
  segment_add_dialog_header: 'セグメント追加',
  segment_delete_message: '削除しますか?',
  segment_edit_dialog_header: 'セグメント編集',

  //general
  code: 'コード',
  success_import_csv: 'ファイルをインポートしました',
  import_csv_btn: 'インポート',
  no_selected_data: 'データを選択してください。',

  //ip restriction
  ip_err_fetch: '現在のIPアドレスを取得できません',
  ip_err_first_list: '最初のIPアドレスは現在接続中のものを指定ください',
  ip_err_delete_current_ip: '現在接続中のIPアドレスは削除できません',
  ip_delete_message: 'このIPアドレスを削除しますか？',
  ip_edit_current_ip:
    '現在接続中のIPアドレスのため、IPアドレスの変更はできません。',

  //export settings
  exp_delete_format: 'Delete format?',
  exp_search_fields: 'Search Column',
  exp_format_name: 'Format name',
  exp_selected_fields: 'Selected Columns',
  exp_format_list: 'Format List',
  exp_format_name_exists: 'Format name already exists!',
  export_settings_multiple_edit: '一括編集',
  export_settings_change_display_setting:
    '選択したエクスポート設定の表示設定を一括で変更します。',

  // Item
  item_name: '品目名',
  item_code: '品目コード',
  item_add_dialog_header: '品目追加',
  item_delete_message: '削除しますか?',
  item_edit_dialog_header: '品目編集',

  // Tag
  tag_name: 'メモタグ名',
  tag_code: 'メモタグコード',
  tag_add_dialog_header: 'メモタグ追加',
  tag_delete_message: '削除しますか?',
  tag_edit_dialog_header: 'メモタグ編集',

  // Walletable
  walletable_name: '口座名',
  walletable_code: '口座コード',
  walletable_add_dialog_header: '口座追加',
  walletable_delete_message: '削除しますか?',
  walletable_edit_dialog_header: '口座編集',

  //TaxClass
  tax_class_delete_message: '削除しますか?',

  // Bank Transfer Fee
  transfer_fee_delete_message: '削除しますか?',

  //line
  disconnect: '連携解除',
  line_use: 'LINE 連携',
  line_ok: '認証',
  //Slack
  slack_use: 'Slack 連携',
  slack_hint_channel_list:
    'プライベートチャンネルを通知先に選択する場合は、希望のチャンネルにBotを追加してください。',
  //Chatwork
  Chatwork_use: 'Chatwork 連携',
  Chatwork_issue: 'アクセストークン発行',
  apiupload: '外部サービスアップロード',
  //freee
  freee_confirm_delete_master_title: 'マスタデータ削除確認',
  freee_confirm_delete_master_message:
    '現在sweeepに存在するマスタデータが削除されます。',

  // import
  confirm_import_mode: 'モードを選択してください',
  //Full Alert
  chromeAlert:
    'Google Chrome以外のブラウザだと動作が不安定になります。ブラウザはGoogle Chromeをご利用ください。',

  // Dialog
  delete_message: '削除しますか?',

  confirm_change_invoice_department: '請求書の所管部門を変更します',

  send_email: 'メール送信',
  comment: 'コメント',
  confirm_send_invoice_email: '入力したコメントが読取ユーザ・更新ユーザ・承認済ユーザへ送信されます',

  interrupted_on_editing_invoice:
    '現在編集中のため処理できません。更新ボタンを押して編集を完了するか、編集をキャンセルしてからボタンを押してください。',
  interrupted_on_cancel_viewed_invoice:
    '更新済み、または承認済みのデータは閲覧を解除できません。',

  progress_check_import_naster: 'ファイルをチェックしています...',
  progress_import_naster: 'ファイルをインポートしています...',

  inbox_customer_company_name: '取引先会社名',
  inbox_customer_department_name: '取引先部門名',
  inbox_customer_represent_name: '取引先担当者名',
  inbox_owner_company_name: '自社名',
  inbox_owner_department_name: '自社部門名',
  inbox_owner_represent_name: '自社担当者名',
  inbox_upload_url: '請求書回収用URL',
  inbox_upload_email: '請求書回収用メールアドレス',
  // generic
  unexpectedError: 'エラーが発生しました。サポートチームにお知らせください。',
}
