<template>
  <v-row no-gutters :class="{ 'pr-3': !noPadding }">
    <v-col :cols="debitCols">
      <v-card tile outlined>
        <v-toolbar
          block
          flat
          color="grey lighten-4"
          class="d-flex justify-center"
          height="25"
        >
          <div class="fs-10 fw-bold blue--text">借方</div>
        </v-toolbar>
      </v-card>
    </v-col>
    <v-col :cols="creditCols">
      <v-card tile outlined>
        <v-toolbar
          block
          flat
          color="grey lighten-4"
          class="d-flex justify-center"
          height="25"
        >
          <div class="fs-10 fw-bold green--text">貸方</div>
        </v-toolbar>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    debitCols: {
      type: String,
      default: '6',
    },
    creditCols: {
      type: String,
      default: '6',
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
