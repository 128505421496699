/**
 * Auth Module
 */
import Vue from 'vue'
//import firebase from 'firebase';
import Nprogress from 'nprogress'
import router from '../../../router'
import axios from 'axios'
import {
  facebookAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
  githubAuthProvider,
} from '../../../firebase'
import { store } from '../../store'
import Snackbar from 'Helpers/snackbar/index'

function errorNotify(msg) {
  Snackbar.error(msg)
}

const state = {
  user: localStorage.getItem('user'),
  isUserSigninWithAuth0: Boolean(localStorage.getItem('isUserSigninWithAuth0')),
}

// getters
const getters = {
  getUser: (state) => {
    return state.user
  },
  isUserSigninWithAuth0: (state) => {
    return state.isUserSigninWithAuth0
  },
}

// actions
const actions = {
  checkUserInvite(context, payload) {
    const data = payload.data
    const apiUrl =
      payload.apiUrl + '?id=' + data.id + '&invite_code=' + data.invite_code

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl, {
          data: { id: data.id },
        })
        .then(function (response) {
          resolve(response)
          // if ('error' in response.data) {
          //     errorNotify(response.data.error)
          // } else {
          //     resolve(response)
          // }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  apiResetPassword(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl, {
          id: data.id,
          reset_code: data.reset_code,
          password: data.password,
        })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {})
    })
  },
  apiForgetPassword(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl

    return new Promise((resolve, reject) => {
      axios
        .put(apiUrl, {
          email: data.email,
          url: data.url,
        })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {})
    })
  },
  apiConfirmSignUp(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl, {
          id: data.id,
          full_name: data.full_name,
          password: data.password,
          telphone_number: data.telphone_number,
          address: data.address,
          type_of_bussiness: data.type_of_bussiness,
          zipcode: data.zipcode,
          activation_code: data.activation_code,
        })
        .then(function (response) {
          resolve(response)
          // router.push("/session/login");
        })
        .catch(function (error) {})
    })
  },
  apiCheckConfirmCode(context, payload) {
    const data = payload.data
    const apiUrl =
      payload.apiUrl +
      '?id=' +
      data.id +
      '&activation_code=' +
      data.activation_code

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl)
        .then(function (response) {
          if (response.data.error) {
            router.push('/session/login')
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {})
    })
  },
  apiSignUpUser(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl
    return new Promise((resolve, reject) => {
      axios
        .put(apiUrl, {
          email: data.email,
          business_form: data.business_form,
          organization_name: data.organization_name,
          url: data.url,
          isSupportCreated: data.isSupportCreated,
        })
        .then(function (response) {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          } else {
            Nprogress.done()
            var user = response.data.data
          }
          resolve(response)
        })
    })
  },
  apiSignInUser(context, payload) {
    const user = payload.user
    const apiUrl = payload.apiUrl
    const next = payload.next
    var headers = {
      'Content-Type': 'application/json; charset=utf-8',
    }
    return new Promise((resolve, reject) => {
      axios
        .post(
          apiUrl,
          {
            email: user.email,
            password: user.password,
            organization_name: user.organization_name,
            envtype: user.envtype,
          },
          { headers: headers }
        )
        .then(function (response) {
          Nprogress.done()
          if (response.data.error) {
            resolve(response)
          } else {
            var user = response.data.data
            user.init_message = payload.message || response.data.data.message
            if (user) {
              setTimeout(() => {
                context.commit('loginUserSuccess', { user, next })
              }, 500)
            }
          }
        })
    })
  },
  apiSignInAnother(context, payload) {
    const apiUrl = payload.apiUrl

    const data = {
      user_organization_id: payload.user_organization_id,
      envtype: payload.envtype,
    }
    const headers = { headers: { Authorization: payload.token } }

    return new Promise((resolve, reject) => {
      axios.post(apiUrl, data, headers).then(function (response) {
        Nprogress.done()
        if (response.data.error) {
          resolve(response)
        } else {
          var user = response.data.data
          if (user) {
            setTimeout(() => {
              context.commit('loginUserSuccess', { user })
            }, 500)
          }
        }
      })
    })
  },
  signinUserInFirebase(context, payload) {
    const { user } = payload
    context.commit('loginUser')
    firebase
      .auth()
      .signInWithEmailAndPassword(user.email, user.password)
      .then((user) => {
        Nprogress.done()
        setTimeout(() => {
          context.commit('loginUserSuccess', { user })
        }, 500)
      })
      .catch((error) => {
        context.commit('loginUserFailure', error)
      })
  },
  logoutUserFromFirebase(context) {
    Nprogress.start()
    firebase
      .auth()
      .signOut()
      .then(() => {
        Nprogress.done()
        setTimeout(() => {
          context.commit('logoutUser')
        }, 500)
      })
      .catch((error) => {
        context.commit('loginUserFailure', error)
      })
  },
  signinUserWithFacebook(context) {
    context.commit('loginUser')
    firebase
      .auth()
      .signInWithPopup(facebookAuthProvider)
      .then((result) => {
        Nprogress.done()
        setTimeout(() => {
          context.commit('loginUserSuccess', { user: result.user })
        }, 500)
      })
      .catch((error) => {
        context.commit('loginUserFailure', error)
      })
  },
  signinUserWithGoogle(context) {
    context.commit('loginUser')
    firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then((result) => {
        Nprogress.done()
        setTimeout(() => {
          context.commit('loginUserSuccess', { user: result.user })
        }, 500)
      })
      .catch((error) => {
        context.commit('loginUserFailure', error)
      })
  },
  signinUserWithTwitter(context) {
    context.commit('loginUser')
    firebase
      .auth()
      .signInWithPopup(twitterAuthProvider)
      .then((result) => {
        Nprogress.done()
        setTimeout(() => {
          context.commit('loginUserSuccess', { user: result.user })
        }, 500)
      })
      .catch((error) => {
        context.commit('loginUserFailure', error)
      })
  },
  signinUserWithGithub(context) {
    context.commit('loginUser')
    firebase
      .auth()
      .signInWithPopup(githubAuthProvider)
      .then((result) => {
        Nprogress.done()
        setTimeout(() => {
          context.commit('loginUserSuccess', { user: result.user })
        }, 500)
      })
      .catch((error) => {
        context.commit('loginUserFailure', error)
      })
  },
  signupUserInFirebase(context, payload) {
    let { userDetail } = payload
    context.commit('signUpUser')
    firebase
      .auth()
      .createUserWithEmailAndPassword(userDetail.email, userDetail.password)
      .then(() => {
        Nprogress.done()
        setTimeout(() => {
          context.commit('signUpUserSuccess', userDetail)
        }, 500)
      })
      .catch((error) => {
        context.commit('signUpUserFailure', error)
      })
  },
  signInUserWithAuth0(context, payload) {
    context.commit('signInUserWithAuth0Success', payload)
  },
  signOutUserFromAuth0(context) {
    context.commit('signOutUserFromAuth0Success')
  },
}

// mutations
const mutations = {
  loginUser(state) {
    Nprogress.start()
  },
  loginUserSuccess(state, obj) {
    const user = obj.user
    const next = !!obj.next ? obj.next : null
    state.user = user
    var struc = {}
    try {
      struc = {
        organization_id: user.organization_id,
        organization_name: encodeURIComponent(user.organization_name),
        token: user.token,
        user_id: user.user_id,
        user_organization_id: user.user_organization_id,
        user_role_id: user.user_role_id,
        username: encodeURIComponent(user.username),
        envtype: user.envtype,
        init_message: user.init_message,
      }
      //Full.vueのshowInitMessageで出力している
      var cookie_name = 'token_' + user.envtype
      document.cookie =
        cookie_name + '=' + JSON.stringify(struc) + ';domain=.sweeep.ai;path=/'
      localStorage.setItem('user', JSON.stringify(user))

      store.dispatch('action_initialiseStore', {}).then((response) => {})
      state.isUserSigninWithAuth0 = false
      const fullPath = process.env['VUE_APP_SUBDOMAIN_APP_FULL']
      if (!!next) {
        setTimeout(function () {
          window.location.href = fullPath + next
        }, 1500)
      } else if (user.user_role_id === 5) {
        setTimeout(function () {
          window.location.href = `${fullPath}/upload`
        }, 1500)
      } else if (user.user_role_id === 6) {
        setTimeout(function () {
          window.location.href = `${fullPath}/inbox`
        }, 1500)
      } else if (user.to_invoice == 1) {
        setTimeout(function () {
          window.location.href = `${fullPath}/invoice`
        }, 1500)
      } else {
        location.reload()
      }
    } catch (err) {
      Snackbar.error(err.message)
    }
  },
  loginUserFailure(state, error) {
    Nprogress.done()
    Snackbar.error(error.message)
  },
  logoutUser(state) {
    state.user = null
    router.push('/session/login')
  },
  signUpUser(state) {
    Nprogress.start()
  },
  signUpUserSuccess(state, user) {
    state.user = localStorage.setItem('user', user)
    router.push('/invoice')
    Snackbar.success('Account Created!')
  },
  signUpUserFailure(state, error) {
    Nprogress.done()
    Snackbar.error(error.message)
  },
  signInUserWithAuth0Success(state, user) {
    state.user = user
    localStorage.setItem('user', JSON.stringify(user))
    state.isUserSigninWithAuth0 = true
  },
  signOutUserFromAuth0Success(state) {
    state.user = null
    localStorage.removeItem('user')
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
