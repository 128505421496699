<template>
  <v-tour name="vuelyTour" :steps="steps"></v-tour>
</template>

<script>
export default {
  data() {
    return {
      steps: [
        {
          target: '.v-step-0',
          content: 'メニューの表示／非表示を切り替えます',
          params: {
            placement: 'right',
          },
        },
        {
          target: '.v-step-1',
          content: '全画面表示により快適な操作が可能です',
          params: {
            placement: 'left',
          },
        },
      ],
    }
  },
}
</script>
