<!-- App Main Structure -->
<template>
  <v-app>
    <template v-if="loading">
      <socket></socket>
    </template>
    <template v-else>
      <!-- App Alert -->
      <v-alert
        slot="not-chrome"
        v-show="alert.notChrome"
        color="error"
        icon="warning"
        style="margin-bottom: -50px"
        class="sweeep-not-chrome-alert"
      >
        {{ $t('message.chromeAlert') }}
      </v-alert>
      <!-- App Header -->
      <app-header
        :user="user"
        :organization="organization"
        :definition="definition"
        :journalItems="journalItems"
      ></app-header>

      <div id="full-confirm-dialog-container" />
      <div>
        <!-- App Main Content -->
        <v-content>
          <!-- App Router -->
          <transition
            name="router-anim"
            :enter-active-class="`animated ${selectedRouterAnimation}`"
          >
            <router-view
              :getUserSession="getUserSession"
              :organization="organization"
              :definition="definition"
              :bankData="bankData"
              :journalItems="journalItems"
              :exportMaster="exportMaster"
              :sortMaster="sortMaster"
              :getJournalItems="getMasterList"
              :getMaster="getMaster"
              :user="user"
            ></router-view>
          </transition>
        </v-content>
        <!-- User Tour -->
        <tour></tour>
      </div>
      <!-- <v-footer
        color=""
        app
        inset
        fixed="false"
      >
        <span class="grey--text">&copy; 2019</span>
      </v-footer> -->
    </template>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Snackbar from 'Helpers/snackbar/index'
import { consoleLog } from 'Helpers/helpers'
import AppConfig from 'Constants/AppConfig'
import Axios from 'axios'
import Header from 'Components/Header/Header.vue'
import Tour from 'Components/Tour/Tour'

export default {
  components: {
    appHeader: Header,
    Tour,
  },
  updated() {
    consoleLog('Render', 'Full')
  },
  data() {
    return {
      loading: true,
      organization: {
        settingsAccountTitle: false,
        settingsDepartment: false,
        settingsProject: false,
        settingsSegment: false,
        settingsSubAccount: false,
        settingsVendor: false,
        settingsItem: false,
        settingsTag: false,
        wordOrByte: 1,
        maxLenDescription: false,
        maxLenFreeText1: false,
        maxLenFreeText2: false,
        settingsWalletable: false,
        settingPayDay: false,
        settingPayMonth: false,
        settingJournal: 1,
        settingPaidByCustomer: false,
        approveLevel: 2,
        payConfirmLevel: 3
      },
      definition: {
        account_title: '',
        sub_account: '',
        tax_class: '',
        department: '',
        vendor: '',
        project: '',
        segment: '',
        walletable: '',
        item: '',
        tag: '',
        description: '',
        free_text_1: '',
        free_text_2: '',
        journal: {},
      },
      journalItems: {
        account_title: [],
        tax: [],
        department: [],
        project: [],
        segment: [],
        vendor: [],
        item: [],
        tag: [],
        walletable: [],
        loaded: 0,
      },
      bankData: {
        bankNameList: [],
        accountTypeList: [],
        loaded: false,
      },
      user: {
        role: {
          id: '',
          name: '',
          accessLevel: null,
        },
      },
      alert: { notChrome: false },
    }
  },
  mounted() {
    this.$store.dispatch('setWindowToken')
    this.checkBrowserInfo()
    this.getUserSession()
    this.getMasterList()
    this.getBankData()
    this.showInitMessage()
    this.$store.dispatch('getOrganizationSetting', {})
    this.$store.dispatch('getUserOrganizations')
    setTimeout(() => {
      this.loading = false
      setTimeout(() => {
        if (AppConfig.enableUserTour) {
          this.$tours['vuelyTour'].start()
        }
      }, 1000)
    }, 2000)
  },
  computed: {
    ...mapGetters(['selectedRouterAnimation']),
  },
  methods: {
    showInitMessage() {
      setTimeout(() => {
        var user = JSON.parse(localStorage.getItem('user'))
        if (user.init_message) {
          const option = {
            text: user.init_message,
            color: 'success',
            center: true,
            top: true,
          }
          Snackbar.show(option)
          delete user.init_message
          localStorage.setItem('user', JSON.stringify(user))
        }
      }, 3000)
    },
    getUserSession() {
      try {
        const start = new Date()
        Axios.get(this.$store.getters.apiUserSessionFunction, {
          headers: { Authorization: this.$store.getters.getAuthToken },
          params: {
            user_organization_id: this.$store.getters.getUserOrganizationID,
          },
        }).then((response) => {
          if (response.data.status === 'success') {
            var data = response.data
            this.organization.settingsAccountTitle =
              data.organization.settings_account_title
            this.organization.settingsDepartment =
              data.organization.settings_department
            this.organization.settingsProject =
              data.organization.settings_project
            this.organization.settingsSegment =
              data.organization.settings_segment
            this.organization.settingsSubAccount =
              data.organization.settings_subaccount
            this.organization.settingsVendor = data.organization.settings_vendor
            this.organization.settingsItem = data.organization.settings_item
            this.organization.settingsTag = data.organization.settings_tag
            this.organization.settingsWalletable =
              data.organization.settings_walletable
            this.organization.settingsTax = data.organization.settings_tax
            this.organization.settingsDescription =
              data.organization.settings_description
            this.organization.settingsFreeText1 =
              data.organization.settings_free_text_1
            this.organization.settingsFreeText2 =
              data.organization.settings_free_text_2
            this.organization.wordOrByte = data.organization.word_or_byte
            this.organization.maxLenDescription =
              data.organization.setting_maxlen_description
            this.organization.maxLenFreeText1 =
              data.organization.setting_maxlen_free_text_1
            this.organization.maxLenFreeText2 =
              data.organization.setting_maxlen_free_text_2
            this.organization.approveLevel = data.organization.approve_level
            this.organization.payConfirmLevel = data.organization.pay_confirm_level
            this.organization.settingPayMonth =
              data.organization.setting_pay_month
            this.organization.settingPayDay = data.organization.setting_pay_day
            this.organization.settingJournal = data.organization.setting_journal
            this.organization.settingPayJournal =
              data.organization.setting_pay_journal
            this.organization.settingAvoidBankHolidays =
              data.organization.setting_avoid_bank_holidays
            this.organization.settingPaidByCustomer =
              data.organization.setting_paid_by_customer
            this.organization.settingForeignCurrency =
              data.organization.setting_foreign_currency

            this.definition = data.organization.definition
            this.user.id = data.user.id
            this.user.full_name = data.user.full_name
            this.user.role.id = data.user_role.id
            this.user.role.name = data.user_role.name
            this.user.role.accessLevel = data.user_role.access_level
            this.$store.dispatch('setUserAbilities', data.user.abilities)

            let _this = this
            setTimeout(function () {
              _this.$forceUpdate()
            }, 1000)

            consoleLog('API', 'getUserSession', start)
          } else {
            Snackbar.error(response.data.error)
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    getBankData() {
      try {
        const start = new Date()
        this.$store.dispatch('getBankData').then((resolve) => {
          consoleLog('API', 'getBankData', start)
        })
      } catch (e) {
        console.log(e)
      }
    },
    getMasterList() {
      try {
        this.$store.dispatch('getMasterList', {})
      } catch {}
    },
    exportMaster(name, mode) {
      try {
        this.$store.dispatch('ExportMaster_API', { name: name, mode: mode })
      } catch (e) {
        console.log(e)
      }
    },
    sortMaster(data) {
      try {
        return new Promise((resolve, reject) => {
          this.$store.dispatch('SortMaster_API', data).then((response) => {
            if (response.data.status === 'success') {
              Snackbar.success('表示順位を更新しました。')
              this.getMasterList()
              resolve({ status: 'success', items: response.data.items })
            }
          })
        })
      } catch (e) {
        console.log(e)
      }
    },
    getMaster(name) {
      try {
        return new Promise((resolve, reject) => {
          this.$store.dispatch('GetMaster_API', name).then((response) => {
            if (response.data.error) {
              Snackbar.error(response.data.error)
              resolve([])
            } else {
              resolve(response.data)
            }
          })
        })
      } catch (e) {
        console.log(e)
      }
    },
    checkBrowserInfo() {
      let agent = window.navigator.userAgent.toLowerCase()
      var chrome =
        agent.indexOf('chrome') !== -1 &&
        agent.indexOf('edge') === -1 &&
        agent.indexOf('opr') === -1
      if (!chrome) {
        this.alert.notChrome = true
      } else {
        return
      }
    },
  },
}
</script>
