/*
<script>
import  Snackbar from 'Helpers/snackbar/index'

~~~~~~~~~~~~~~~~~
methods:[
  f(){
    Snackbar.show({
      text:text,
      snackbarsProps:value**,
      //timeout,top,right,center,icon....(https://vuetifyjs.com/en/components/snackbars)
      //custon botton:true(add snackbar "閉じる" botton)
    }),
    or
    Snackbar.success(text,option{})
    Snackbar.error(text,option{})
    Snackbar.warning(text,option{})
  }


]
</script>

*/

import Vue from 'vue'
import Toast from './snackbar.vue'

const colors = ['success', 'error', 'warning']
//default options
const defaultOptions = {
  text: '',
  icon: '',
  color: 'success',
  timeout: 9000,
  dismissible: true,
  left: true,
}

let toastCmp = null

function createToastCmp() {
  const cmp = new Vue(Toast)

  document.body.appendChild(cmp.$mount().$el)

  return cmp
}

function getToastCmp() {
  if (!toastCmp) {
    toastCmp = createToastCmp()
  }

  return toastCmp
}

function show(options = {}) {
  switch (options.color) {
    case 'success':
      options.color = '#4caf50'
      break
    case 'error':
      options.color = '#FF3739'
      break
    case 'warinig':
      options.color = '#d69909'
      break
    default:
      break
  }
  if (options.right) {
    options.left = false
  }
  if (options.centered) {
    ;(options.left = false), (options.right = false)
  }
  getToastCmp().show({ ...defaultOptions, ...options })
}

function close() {
  getToastCmp().close()
}

function createShorthands() {
  const shorthands = {}
  colors.forEach(
    (color) =>
      (shorthands[color] = (text, options = {}) =>
        show({ color, text, ...options }))
  )
  return shorthands
}

export default {
  show,
  close,
  getToastCmp,
  defaultOptions,
  ...createShorthands(),
}
