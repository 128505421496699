/**
 * App Entry File
 * Vuely: A Powerfull Material Design Admin Template
 * Copyright 2018. All Rights Reserved
 * Created By: The Iron Network, LLC
 * Made with Love
 */
import 'babel-polyfill'
import Vue from 'vue'
import Vuetify from 'vuetify'
import * as VueGoogleMaps from 'vue2-google-maps'
import { Vue2Dragula } from 'vue2-dragula'
import VueQuillEditor from 'vue-quill-editor'
import wysiwyg from 'vue-wysiwyg'
import VueBreadcrumbs from 'vue2-breadcrumbs'
import VueResource from 'vue-resource'
import Notifications from 'vue-notification'
import velocity from 'velocity-animate'
import AmCharts from 'amcharts3'
import AmSerial from 'amcharts3/amcharts/serial'
import AmAngularGauge from 'amcharts3/amcharts/gauge'
import VueTour from 'vue-tour'
import fullscreen from 'vue-fullscreen'
import InstantSearch from 'vue-instantsearch'
import VueVideoPlayer from 'vue-video-player'
import Croppa from 'vue-croppa'
import VueShortkey from 'vue-shortkey'
import VueMask from 'v-mask'
//vue currency
import VueCurrencyFilter from 'vue-currency-filter'
import { sync } from 'vuex-router-sync'

// global components
import GlobalComponents from './globalComponents'

// gloval methods
import GlobalMethods from './globalMethods'

// app.vue
import App from './App.vue'

// router
import router from './router'

// themes
import primaryTheme from './themes/primaryTheme'

// store
import { store } from './store/store'

// firebase
//import './firebase'

// include script file
import './lib/VuelyScript'

// include all css files
import './lib/VuelyCss'

//vue tooltip
import VTooltip from 'v-tooltip'

//plugins
import MultiFiltersPlugin from './plugins/MultiFilters'
import { i18n } from './plugins/i18n'
import { inializeSentry } from './plugins/sentry'

// functions
import confirm from '@/helpers/confirm'

// plugins
Vue.use(Vuetify, {
  theme: store.getters.selectedTheme.theme,
})
Vue.use(InstantSearch)
Vue.use(AmCharts)
Vue.use(AmSerial)
Vue.use(VueTour)
Vue.use(AmAngularGauge)
Vue.use(Vue2Dragula)
Vue.use(VueQuillEditor)
Vue.use(VueResource)
Vue.use(wysiwyg, {})
Vue.use(VueBreadcrumbs)
Vue.use(Notifications, { velocity })
Vue.use(fullscreen)
Vue.use(GlobalComponents)
Vue.use(GlobalMethods)
Vue.use(VueVideoPlayer)
Vue.use(Croppa)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBtdO5k6CRntAMJCF-H5uZjTCoSGX95cdk', // Add your here your google map api key
  },
})
Vue.use(VueMask)
//vue currency
Vue.use(VueCurrencyFilter, {
  thousandsSeparator: ',',
})
Vue.use(VTooltip, {
  defaultBoundariesElement: 'window', // overflow-xを指定した際に点滅しないためのオプション(https://github.com/Akryum/v-tooltip/issues/192)
})

//vue multifilters
Vue.use(MultiFiltersPlugin)

Vue.use(VueShortkey)

Vue.config.performance = true
Vue.prototype.$confirm = confirm

sync(store, router)

inializeSentry()

/* eslint-disable no-new */
new Vue({
  vuetify: new Vuetify(),
  store,
  // @ts-ignore
  i18n,
  router,
  render: (h) => h(App),
  components: { App },
}).$mount('#app')
