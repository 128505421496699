<template functional>
  <span
    class="
      tw-inline-block
      tw-rounded
      tw-items-center
      tw-whitespace-nowrap
      tw-px-1.5
      tw-py-px
      tw-text-xs
      tw-font-semibold
      tw-border
      tw-border-solid
      tw-border-rounded
    "
    :class="[
      data.staticClass,
      data.class,
      `tw-text-${props.color ? props.color : 'gray-800'} tw-border-${
        props.color ? props.color : 'gray-800'
      }`,
    ]"
  >
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: null,
    },
  },
}
</script>
