<template>
  <div>
    <base-dialog
      ref="dialogDeleteMessageConfirm"
      title="メッセージ削除"
      message="このメッセージを削除しますか？"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.delete')"
      @submit="deleteMessage(selectedMessage)"
    />
    <v-dialog v-model="dialog" max-width="720">
      <v-card class="sweeep-dialog">
        <v-card-title> メッセージ編集 </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="selectedMessage.content"
            style="white-space: pre-wrap; word-wrap: break-word"
            outlined
            dense
            filled
            label="メッセージ内容"
          />
          <span>表示期間</span>
          <v-row no-gutters>
            <date-pick-input v-model="selectedMessage.date_display_from" />
            <span class="mx-2">〜</span>
            <date-pick-input v-model="selectedMessage.date_display_until" />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default darken-1" @click.native="dialog = false">
            閉じる
          </v-btn>
          <v-btn
            class="ml-2"
            color="primary"
            @click="isNewMessage ? createMessage() : updateMessage()"
          >
            設定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-menu
      offset-y
      z-index="99"
      transition="slide-y-transition"
      nudge-bottom="15"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-carousel
            v-if="publicMessages.length != 0"
            v-model="model"
            height="50px"
            vertical
            hide-delimiters
            :show-arrows="false"
            light
            cycle
          >
            <v-carousel-item
              v-for="message in publicMessages"
              :key="message.id"
            >
              <v-toolbar height="50">
                <v-avatar
                  size="25"
                  color="grey"
                  style="margin-left: -15px"
                  class="fs-10 mr-5 white--text"
                  v-text="message.full_name.slice(0, 2)"
                />
                <v-chip
                  label
                  small
                  link
                  outlined
                  class="mr-3"
                  v-text="'お知らせ'"
                />
                <div style="max-width: 500px" class="ellipsis">
                  {{ message.content }}
                </div>
              </v-toolbar>
            </v-carousel-item>
          </v-carousel>
          <div v-else>
            <v-btn
              small
              depressed
              color="blue lighten-5"
              v-text="'掲示板機能：メッセージを追加できます'"
            />
          </div>
        </div>
      </template>
      <div>
        <v-card class="sweeep-popover" min-width="500">
          <v-card-title>
            メッセージ一覧
            <v-spacer />
            <v-btn
              v-tooltip="'新規メッセージを追加'"
              icon
              dark
              @click="editMessage()"
            >
              <s-icon icon="feather-plus" />
            </v-btn>
          </v-card-title>
          <vue-perfect-scrollbar :style="getScrollHeight()">
            <v-list dense>
              <message-list-item
                :messages="publicMessages"
                @click-edit="editMessage($event)"
                @click-delete="showDeleteMessageDialogConfirm($event)"
              />
              <v-divider v-if="privateMessages.length != 0" />
              <message-list-item
                :messages="privateMessages"
                @click-edit="editMessage($event)"
                @click-delete="showDeleteMessageDialogConfirm($event)"
              />
            </v-list>
          </vue-perfect-scrollbar>
        </v-card>
      </div>
    </v-menu>
  </div>
</template>

<script>
import MessageListItem from './MessageListItem'
import DatePickInput from 'Components/Input/DatePickInput'
import Snackbar from 'Helpers/snackbar/index'
import { customRequest } from 'Helpers/api/request'

export default {
  components: {
    DatePickInput,
    MessageListItem,
  },
  data() {
    return {
      dialog: false,
      messages: [],
      model: 0,
      isNewMessage: false,
      selectedMessage: {
        id: '',
        content: '',
        date_display_from: '',
        date_display_until: '',
      },
    }
  },
  computed: {
    publicMessages() {
      return this.messages.filter(function (message) {
        return message.judge_display_period == '表示中'
      })
    },
    privateMessages() {
      return this.messages
        .filter(function (message) {
          return message.judge_display_period != '表示中'
        })
        .sort(function (a, b) {
          if (a.judge_display_period != b.judge_display_period) {
            return a.judge_display_period > b.judge_display_period ? 1 : -1
          } else {
            return new Date(a.date_display_from) - new Date(b.date_display_from)
          }
        })
    },
    today() {
      const today = new Date()
      const year = today.getFullYear()
      const month = ('00' + (today.getMonth() + 1)).slice(-2)
      const date = ('00' + today.getDate()).slice(-2)
      return `${year}-${month}-${date}`
    },
    nextMonth() {
      const today = new Date()
      const year = today.getFullYear()
      const month = ('00' + (today.getMonth() + 2)).slice(-2)
      const date = ('00' + today.getDate()).slice(-2)
      return `${year}-${month}-${date}`
    },
  },
  created() {
    this.getMessages()
  },
  methods: {
    getMessages() {
      try {
        const auth_token = this.$store.getters.getAuthToken
        const data = {
          url: this.$store.getters.apiBoardMessages,
          method: 'GET',
          headers: { Authorization: auth_token },
        }
        customRequest(data).then((response) => {
          if (response.status === 'success') {
            const messages = response.messages
            for (let i = 0; i < messages.length; i++) {
              this.messages.push(messages[i])
            }
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    createMessage() {
      try {
        const auth_token = this.$store.getters.getAuthToken
        const data = {
          url: this.$store.getters.apiBoardMessages,
          method: 'POST',
          headers: { Authorization: auth_token },
          data: {
            content: this.selectedMessage.content,
            date_display_from: this.selectedMessage.date_display_from,
            date_display_until: this.selectedMessage.date_display_until,
          },
        }
        customRequest(data).then((response) => {
          if (response.status == 'success') {
            this.messages.push(response.message)
            if (this.publicMessages.length == 2) {
              this.model += 1
            }
            Snackbar.success('メッセージを登録しました')
          } else {
            Snackbar.error(response.error)
          }
        })
        this.dialog = false
      } catch (err) {
        console.log(err)
      }
    },
    updateMessage() {
      try {
        const auth_token = this.$store.getters.getAuthToken
        const data = {
          url: this.$store.getters.apiBoardMessageInstance,
          method: 'PUT',
          headers: { Authorization: auth_token },
          args: {
            id: this.selectedMessage.id,
          },
          data: {
            content: this.selectedMessage.content,
            date_display_from: this.selectedMessage.date_display_from,
            date_display_until: this.selectedMessage.date_display_until,
          },
        }
        customRequest(data).then((response) => {
          if (response.status == 'success') {
            const id = response.message.id
            const message = response.message
            const index = this.messages.findIndex((obj) => obj.id === id)
            this.messages.splice(index, 1, message)
            Snackbar.success('メッセージを変更しました')
          } else {
            Snackbar.error(response.error)
          }
        })
        this.dialog = false
      } catch (err) {
        console.log(err)
      }
    },
    deleteMessage() {
      try {
        const auth_token = this.$store.getters.getAuthToken
        const data = {
          url: this.$store.getters.apiBoardMessageInstance,
          method: 'DELETE',
          headers: { Authorization: auth_token },
          args: {
            id: this.selectedMessage.id,
          },
        }
        customRequest(data).then((response) => {
          if (response.status == 'success') {
            const index = this.messages.findIndex(
              (obj) => obj.id === this.selectedMessage.id
            )
            this.messages.splice(index, 1)
            this.$refs.dialogDeleteMessageConfirm.close()
            Snackbar.success('メッセージを削除しました')
          } else {
            Snackbar.error(response.error)
          }
        })
        this.dialog = false
      } catch (err) {
        console.log(err)
      }
    },
    editMessage(message) {
      if (message) {
        this.isNewMessage = false
      } else {
        this.isNewMessage = true
      }
      this.setSelectMessage(message)
      this.dialog = true
    },
    showDeleteMessageDialogConfirm(message) {
      this.selectedMessage = message
      this.$refs.dialogDeleteMessageConfirm.open()
    },
    setSelectMessage(message) {
      if (message) {
        this.selectedMessage.id = message.id
        this.selectedMessage.content = message.content
        this.selectedMessage.date_display_from = message.date_display_from
        this.selectedMessage.date_display_until = message.date_display_until
      } else {
        this.selectedMessage.id = ''
        this.selectedMessage.content = ''
        this.selectedMessage.date_display_from = this.today
        this.selectedMessage.date_display_until = this.nextMonth
      }
    },
    getScrollHeight() {
      return 'max-height: calc(100vh - 100px)'
    },
  },
}
</script>
