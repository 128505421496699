export function getUserBankFullText(item) {
  try {
    if (!item) {
      return ''
    }
    if (item.id) {
      const bankCode = item.bank_code ? ' (' + item.bank_code + ')' : ''
      const bankText = checkUndefined(item.bank_name) + bankCode
      const bankBranchCode = item.bank_branch_code
        ? ' (' + item.bank_branch_code + ')'
        : ''
      const bankBranchText =
        checkUndefined(item.bank_branch_name) + bankBranchCode
      const accountText =
        checkUndefined(item.bank_account_type) +
        ' ' +
        checkUndefined(item.bank_account_number)
      return bankText + ' ' + bankBranchText + ' ' + accountText
    } else {
      return ''
    }
  } catch (e) {
    console.log(e)
    return ''
  }
}

export function getUserBankSimpleText(item) {
  try {
    if (!item) {
      return ''
    }
    if (item.id) {
      const bankText = checkUndefined(item.bank_name)
      const accountText = checkUndefined(item.bank_account_number)
      return bankText + ' ' + accountText
    } else {
      return ''
    }
  } catch (e) {
    console.log(e)
    return ''
  }
}

function checkUndefined(value) {
  if (typeof value === 'undefined') {
    return ''
  } else {
    return value
  }
}
