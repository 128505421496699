import InvoiceReportAPI from '@/clients/report/report'

const state = {}

const getters = {}

const actions = {
  async getInvoiceReport(context) {
    const token = context.getters.getAuthToken
    const data = await InvoiceReportAPI.get(token)
    return data
  },
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
