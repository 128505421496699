import Full from 'Container/Full'

// component
const Settings = () => import('Views/settings/Settings')

// helpers
import { checkAuthorization, checkIp } from '../helpers/helpers.js'

export default {
  path: '/settings',
  component: Full,
  meta: { requiresAuth: true },
  beforeEnter(to, from, next) {
    checkAuthorization(next)
    checkIp(next)
    next()
  },
  children: [
    {
      path: '',
      component: Settings,
      meta: { title: '管理者設定' },
    },
  ],
}
