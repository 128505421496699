import VueI18n from 'vue-i18n'
import Vue from 'vue'

// messages
import messages from '@/lang'

Vue.use(VueI18n)
// Create VueI18n instance with options
export const i18n = new VueI18n({
  locale: 'ja',
  messages, // set locale messages
})

// https://kazupon.github.io/vue-i18n/guide/locale.html
export const changeLocale = (locale) => {
  i18n.locale = locale
}
