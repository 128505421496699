// component
const TempUpload = () => import('@/pages/temp-upload/index')

export default {
  path: '/tempupload/:uuid',
  component: TempUpload,
  meta: {
    title: '請求依頼',
  },
}
