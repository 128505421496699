export function convertUplaodErrorMessage(errorName) {
  switch (errorName) {
    case 'InvalidFileType':
      return 'サポートされていないファイル拡張子です。ご確認下さい。'
    case 'PdfReadError':
      return 'PDFファイルにパスワードがかかっている可能性があります。'
    case 'SaveImageError':
      return 'ファイルの読み込みに失敗しました。'
    case 'timestamp_error':
      return 'タイムスタンプの付与に失敗しました。'
    default:
      return 'ファイルの読み取りに失敗しました。'
  }
}
