export function getId(obj) {
  try {
    if (obj.id) {
      return obj.id
    } else {
      return ''
    }
  } catch (e) {
    return ''
  }
}

export function getName(obj) {
  try {
    if (obj.name) {
      return obj.name
    } else {
      return ''
    }
  } catch (e) {
    return ''
  }
}

export function getCode(obj) {
  try {
    return obj.code
  } catch (e) {
    return ''
  }
}

export function getNameCode(obj) {
  try {
    if (obj.code) {
      return obj.name + ' ' + obj.code
    } else {
      return obj.name
    }
  } catch (e) {
    return ''
  }
}

export function checkEqualNameCodeId(obj1, obj2) {
  try {
    if (
      obj1.name == obj2.name &&
      obj1.code == obj2.code &&
      obj1.id == obj2.id
    ) {
      return true
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export function datetimeToString(date) {
  var Y = date.getFullYear()
  var M = date.getMonth() + 1
  var D = date.getDate()
  var yyyy = ('000' + Y).slice(-4)
  var mm = ('0' + M).slice(-2)
  var dd = ('0' + D).slice(-2)
  return yyyy + '-' + mm + '-' + dd
}

export function getPayDate(issueDate, payMonth, payDay, type = String) {
  try {
    if (type === String) {
      issueDate = new Date(issueDate)
    }
    const issueYear = issueDate.getFullYear()
    const issueMonth = issueDate.getMonth()

    let payDate

    payMonth = Number(payMonth)
    payDay = Number(payDay)

    if ((payMonth || payMonth === 0) && payDay) {
      if (payDay == 31) {
        payDate = new Date(issueYear, issueMonth + payMonth + 1, 0)
      } else {
        payDate = new Date(issueYear, issueMonth + payMonth, payDay)
      }
      if (type === String) {
        return datetimeToString(payDate)
      } else {
        return payDate
      }
    }
    return ''
  } catch (e) {
    console.log(e)
    return ''
  }
}

export function getPayTypeName(payType) {
  payType = Number(payType)
  switch (payType) {
    case 1:
      return '振込'
    case 2:
      return '引落'
    case 3:
      return 'クレジット'
    case 4:
      return '手形'
    case 5:
      return '現金'
    case 6:
      return '海外送金'
    case 0:
      return 'その他'
    default:
      return ''
  }
}

export function getPaySiteName(payMonth, payDay) {
  payMonth = Number(payMonth)
  payDay = Number(payDay)
  let payMonthText, payDayText
  switch (payMonth) {
    case 0:
      payMonthText = '当月'
      break
    case 1:
      payMonthText = '翌月'
      break
    case 2:
      payMonthText = '翌々月'
      break
    default:
      payMonthText = payMonth + 'ヵ月後'
      break
  }
  switch (payDay) {
    case 31:
      payDayText = '月末'
      break
    default:
      payDayText = payDay + '日'
      break
  }

  if ([0, 1, 2].includes(payMonth) && payDay === 31) {
    // 当月末 or 翌月末 or 翌々月末
    return payMonthText + '末'
  } else {
    // Nヶ月後のN日
    return `${payMonthText}の${payDayText}`
  }
}

export function computeDescription(description, date) {
  try {
    //invoice table descriptionがない時null値
    if (description === null) {
      return ''
    }
    const reg = new RegExp(/%\-{0,1}\+{0,1}\d{1,2}月%/g)
    let matchMonthArray = description.match(reg)
    let resultMonth
    if (matchMonthArray) {
      for (let i in matchMonthArray) {
        let changeNum = Number(matchMonthArray[i].match(/\d{1,2}/)[0])
        let issueMonth = Number(date.slice(5, 7))
        resultMonth = matchMonthArray[i].match(/-/)
          ? issueMonth - changeNum
          : issueMonth + changeNum
        resultMonth =
          resultMonth % 12 < 1 ? 12 - (-resultMonth % 12) : resultMonth % 12
        description = description.replace(
          /%\-{0,1}\+{0,1}\d{1,2}月%/,
          resultMonth + '月'
        )
      }
      return description
    } else {
      return description
    }
  } catch (e) {
    console.log(e)
  }
}

export function getInitialJournalForm() {
  const initialData = {
    free_text_1: '',
    free_text_2: '',
    description: '',

    debit_account_title: null,
    debit_sub_account: null,
    debit_department: null,
    debit_vendor: null,
    debit_project: null,
    debit_segment: null,
    debit_walletable: null,
    debit_item: null,
    debit_tag: null,
    debit_tax: null,

    credit_account_title: null,
    credit_sub_account: null,
    credit_department: null,
    credit_vendor: null,
    credit_segment: null,
    credit_project: null,
    credit_walletable: null,
    credit_item: null,
    credit_tag: null,
    credit_tax: null,

    debit_amount: '',
    debit_amount_without_tax: '',
    debit_tax_amount: '',

    credit_amount: '',
    credit_amount_without_tax: '',
    credit_tax_amount: '',

    credit_foreign_currency_amount: '0',
    debit_foreign_currency_amount: '0',
    foreign_currency_rate: 0,

    debit_amount_input_mode: 2,
    credit_amount_input_mode: 2,

    debit_is_keikasochi: false,
    debit_tax_amount_after_adjustment: '',
    debit_amount_without_tax_after_adjustment: '',

    credit_is_keikasochi: false,
    credit_tax_amount_after_adjustment: '',
    credit_amount_without_tax_after_adjustment: '',
  }
  return initialData
}

export function getInitialSearchForm() {
  const initialData = {
    //headerfilter >>>>>>
    headerDate: '',
    headerDateTo: '',
    filterDateType: 0, //0 issueDate 1 readingDate 2 payDate
    //<<<<<<<<<<<

    search_bar: '',

    id_1: '',
    id_2: '',
    upload_source: '',
    upload_type: '',
    reading_date_from: '',
    reading_date_to: '',
    date: '',
    date_to: '',
    pay_date_from: '',
    pay_date_to: '',
    company_name: '',
    department: '',
    pay_type: '',
    gensenzei: '',
    description: '',
    freeText1: '',
    freeText2: '',
    amount_1: '',
    amount_2: '',
    scoreChoice: -1,
    transactionChoice: -1,
    deletedStatusChoice: 1,
    readStatusChoice: 0,

    exported: '',
    updated: '',
    approve1: '',
    approve2: '',
    approve3: '',
    approve4: '',
    approve5: '',
    upload_user: '',
    update_user: '',
    approve_user: '',
    pay_confirm1: '',
    pay_confirm2: '',
    pay_confirm3: '',
    pay_approve: '',
    paid: '',
    pay_export_user: '',
    pay_approve_user: '',
    paid_user: '',
    approve_status: 0,
    pay_approve_status: 0,

    debit_amount: '',
    credit_amount: '',

    debit_account_title: '',
    debit_sub_account: '',
    debit_department: '',
    debit_vendor: '',
    debit_project: '',
    debit_segment: '',
    debit_walletable: '',
    debit_item: '',
    debit_tag: '',
    debit_tax_class: '',

    credit_account_title: '',
    credit_sub_account: '',
    credit_department: '',
    credit_vendor: '',
    credit_project: '',
    credit_segment: '',
    credit_walletable: '',
    credit_item: '',
    credit_tag: '',
    credit_tax_class: '',

    export_user: '',

    //use only if ResportSetting is true
    project_local_bank_code: '',
    debit_project_local_bank_code: '',
    credit_project_local_bank_code: '',
    settingsFilterBlank: {
      issueDate: false,
      debitAccountTitle: false,
      creditAccountTitle: false,
    },
  }
  return initialData
}

export function getApproveSettings(level) {
  try {
    const settings = {
      approve1: {},
      approve2: {},
      approve3: {},
      approve4: {},
      approve5: {}
    }

    if (level == 1) {
      settings.approve1 = { name: '', name2: '', display: false }
      settings.approve2 = { name: '', name2: '', display: false }
      settings.approve3 = { name: '', name2: '', display: false }
      settings.approve4 = { name: '', name2: '', display: false }
      settings.approve5 = { name: '承認', name2: '承認', display: true }
    } else if (level == 2) {
      settings.approve1 = { name: '', name2: '', display: false }
      settings.approve2 = { name: '', name2: '', display: false }
      settings.approve3 = { name: '', name2: '', display: false }
      settings.approve4 = { name: '承認1', name2: '承1', display: true }
      settings.approve5 = { name: '承認2', name2: '承2', display: true }
    } else if (level == 3) {
      settings.approve1 = { name: '', name2: '', display: false }
      settings.approve2 = { name: '', name2: '', display: false }
      settings.approve3 = { name: '承認1', name2: '承1', display: true }
      settings.approve4 = { name: '承認2', name2: '承2', display: true }
      settings.approve5 = { name: '承認3', name2: '承3', display: true }
    } else if (level == 4) {
      settings.approve1 = { name: '', name2: '', display: false }
      settings.approve2 = { name: '承認1', name2: '承1', display: true }
      settings.approve3 = { name: '承認2', name2: '承2', display: true }
      settings.approve4 = { name: '承認3', name2: '承3', display: true }
      settings.approve5 = { name: '承認4', name2: '承4', display: true }
    } else {
      settings.approve1 = { name: '承認1', name2: '承1', display: true }
      settings.approve2 = { name: '承認2', name2: '承2', display: true }
      settings.approve3 = { name: '承認3', name2: '承3', display: true }
      settings.approve4 = { name: '承認4', name2: '承4', display: true }
      settings.approve5 = { name: '承認5', name2: '承5', display: true }
    }
    return settings
  } catch (e) {
    console.log(e)
  }
}

export function getPayConfirmSettings(level) {
  try {
    const settings = {
      pay_confirm1: {},
      pay_confirm2: {},
      pay_confirm3: {}
    }

    if (level === 0) {
      settings.pay_confirm1 = { name: '', name2: '', display: false }
      settings.pay_confirm2 = { name: '', name2: '', display: false }
      settings.pay_confirm3 = { name: '', name2: '', display: false }
    } else if (level === 1) {
      settings.pay_confirm1 = { name: '', name2: '', display: false }
      settings.pay_confirm2 = { name: '', name2: '', display: false }
      settings.pay_confirm3 = { name: '確認1', name2: '確1', display: true }
    } else if (level === 2) {
      settings.pay_confirm1 = { name: '', name2: '', display: false }
      settings.pay_confirm2 = { name: '確認1', name2: '確1', display: true }
      settings.pay_confirm3 = { name: '確認2', name2: '確2', display: true }
    } else {
      settings.pay_confirm1 = { name: '確認1', name2: '確1', display: true }
      settings.pay_confirm2 = { name: '確認2', name2: '確2', display: true }
      settings.pay_confirm3 = { name: '確認3', name2: '確3', display: true }
    }
    return settings
  } catch (e) {
    console.log(e)
  }
}
