import Axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'
import { findCode } from 'Helpers/bank/index'

const state = {
  bankList: [],
  bankBranchList: {},
  bankAccountTypeList: [],
  bankLoaded: false,
}

const getters = {
  bankList: (state) => {
    return state.bankList
  },
  bankBranchList: (state) => {
    return state.bankBranchList
  },
  bankAccountTypeList: (state) => {
    return state.bankAccountTypeList
  },
  bankLoaded: (state) => {
    return state.bankLoaded
  },
  getBankCode: (state) => (bankName) => {
    try {
      const bankList = state.bankList
      return findCode(bankName, bankList)
    } catch (err) {
      console.log(err)
      return ''
    }
  },
  getBankBranchCode: (state) => (bankName, branchName) => {
    try {
      const bankBranchList = state.bankBranchList[bankName]
      return findCode(branchName, bankBranchList)
    } catch (err) {
      console.log(err)
      return ''
    }
  },
}

const actions = {
  getBankData({ getters, commit }) {
    return new Promise((resolve) => {
      Axios.get(getters.apiBankData, {
        headers: {
          Authorization: getters.getAuthToken,
        },
        params: {
          organization_id: getters.getOrganizationID,
        },
      }).then((response) => {
        if (response.data.error) {
          Snackbar.error(response.data.error)
          resolve(false)
        } else {
          commit('setBankData', response.data.bank_data)
          resolve(true)
        }
      })
    })
  },
}

const mutations = {
  setBankData(state, data) {
    state.bankList = data.bank_list
    state.bankBranchList = data.bank_branch_list
    state.bankAccountTypeList = data.bank_account_type_list
    state.loaded = true
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
