import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'
import { getTransferFeeErrorMessage } from '@/helpers/master/index'

class TransferFeesAPI extends APIClient {
  async get(token) {
    const payload = {
      url: URLs.TRANSFER_FEES,
      method: 'GET',
      headers: {
        Authorization: token,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(getTransferFeeErrorMessage(response.data.error))
      }
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }
  async post(token, data) {
    const payload = {
      data,
      url: URLs.TRANSFER_FEES,
      method: 'POST',
      headers: {
        Authorization: token,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(getTransferFeeErrorMessage(response.data.error))
      }
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }
}
export default new TransferFeesAPI()
