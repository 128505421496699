var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-w-full tw-flex-none tw-pt-2 tw-pb-3"},[_c('label',{staticClass:"tw-relative tw-block tw-text-xs"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"tw-w-full tw-rounded-t tw-h-18 tw-pt-8 tw-font-bold tw-px-4 tw-transition tw-duration-300",class:[
        ("tw-bg-" + _vm.color + "-100"),
        ("tw-placeholder-" + _vm.color + "-400"),
        ("tw-text-" + _vm.color + "-900"),
        ("" + (_vm.outlined ? 'outlined tw-ring-' + _vm.color + '-500 focus:tw-ring-' + _vm.focusedColor + '-600' : '')),
        ("" + (_vm.disabled ? '' : 'hover:tw-bg-' + _vm.color + '-200 hover:tw-ring-' + _vm.color + '-600')),
        ("" + (_vm.hasError ? 'input-error' : ''))
      ],attrs:{"type":"text","placeholder":_vm.placeholder,"disabled":_vm.disabled,"required":_vm.required !== false},domProps:{"value":(_vm.localValue)},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"input":function($event){if($event.target.composing){ return; }_vm.localValue=$event.target.value}}}),_c('div',{staticClass:"input-label tw-absolute tw-inline-block tw-py-1 tw-px-2",class:[
        ("tw-text-" + _vm.color + "-600"),
        ("" + (_vm.labelFloated ? 'floated' : '')),
        ("" + (_vm.outlined ? 'outlined ' : '')),

        ("" + (_vm.inputFocused ? 'tw-text-' + _vm.focusedColor + '-500' : ''))
      ]},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]),_c('div',{staticClass:"input-label-background tw-absolute tw-inline-block tw-py-1 tw-px-2 tw-text-transparent",class:("" + (_vm.labelFloated ? 'floated' : ''))},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]),(!_vm.outlined)?_c('div',{staticClass:"input-bottom-border tw-absolute tw-top-16 tw-w-full",class:("tw-bg-" + _vm.color + "-500")}):_vm._e(),(!_vm.outlined)?_c('div',{staticClass:"input-bottom-border-focused tw-absolute tw-top-16 tw-w-full tw-transform tw-scale-x-0 tw-transition-transform tw-duration-300",class:("" + (_vm.inputFocused ? 'tw-bg-' + _vm.focusedColor + '-600' : 'tw-bg-' + _vm.color + '-500'))}):_vm._e(),_c('div',{staticClass:"input-note tw-py-1 tw-px-4 tw-text-xs",class:("tw-text-" + _vm.color + "-600")},[_vm._v("\n      "+_vm._s(_vm.noteMessage)+"\n    ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }