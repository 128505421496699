import Full from 'Container/Full'

// payment component
const Payment = () => import('Views/payment/Payment')

// helpers
import { checkAuthorization, checkIp } from '../helpers/helpers.js'

export default {
  path: '/payment',
  component: Full,
  meta: { requiresAuth: true },
  beforeEnter(to, from, next) {
    if (from.path == '/session/login') {
      window.location.href = process.env.VUE_APP_SUBDOMAIN_APP_FULL + '/payment'
    } else {
      checkAuthorization(next)
      checkIp(next)
    }
    next()
  },
  children: [
    {
      path: '',
      component: Payment,
      name: 'payment',
      meta: { title: '支払' },
    },
  ],
}
