import Full from 'Container/Full'

// component
const Freee = () => import('Views/settings/Freee')
const Settings = () => import('Views/settings/Settings')

// helpers
import { checkAuthorization, checkIp } from '../helpers/helpers.js'

export default {
  path: '/freee',
  component: Full,
  meta: { requiresAuth: true },
  beforeEnter(to, from, next) {
    try {
      checkAuthorization(next)
      checkIp(next)
      next()
    } catch (err) {
      console.log(err)
    }
  },
  children: [
    {
      path: '',
      component: Settings,
      children: [
        {
          path: '',
          component: Freee,
          meta: { title: '管理者設定' },
        },
      ],
    },
  ],
}
