/**
 * Vuely Global Components
 */
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AppSectionLoader from 'Components/AppSectionLoader/AppSectionLoader'
import { Socket } from 'vue-loading-spinner'

// delete Confirmation Dialog
import DeleteConfirmationDialog from 'Components/DeleteConfirmationDialog/DeleteConfirmationDialog'

// buttom
import BaseButton from 'Components/Button/BaseButton'

import SChip from 'Components/Chip/SChip'
import SChipDark from 'Components/Chip/SChipDark'
import SIcon from 'Components/Icon/SIcon'
import STextField from "Components/Input/STextField"

import BaseDialog from 'Components/Dialog/BaseDialog'
import BaseAlert from 'Components/Alert/BaseAlert'
import ConfirmDialog from 'Components/Dialog/ConfirmDialog'
import InputDeleteDialog from 'Components/Dialog/InputDeleteDialog'
import NoPrivilegeDialog from 'Components/Dialog/NoPrivilegeDialog'
import ProgressDialog from 'Components/Dialog/ProgressDialog'

const GlobalComponents = {
  install(Vue) {
    Vue.component('deleteConfirmationDialog', DeleteConfirmationDialog)
    Vue.component('vuePerfectScrollbar', VuePerfectScrollbar)
    Vue.component('appSectionLoader', AppSectionLoader)
    Vue.component('Socket', Socket)
    Vue.component('baseButton', BaseButton)
    Vue.component('baseDialog', BaseDialog)
    Vue.component('baseAlert', BaseAlert)
    Vue.component('confirmDialog', ConfirmDialog)
    Vue.component('inputDeleteDialog', InputDeleteDialog)
    Vue.component('noPrivilegeDialog', NoPrivilegeDialog)
    Vue.component('progressDialog', ProgressDialog)
    Vue.component('SChip', SChip)
    Vue.component('SChipDark', SChipDark)
    Vue.component('SIcon', SIcon)
    Vue.component('STextField', STextField)
  },
}

export default GlobalComponents
