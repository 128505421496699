import TransferFeeAPI from '@/clients/master/transfer_fee'
import TransferFeesAPI from '@/clients/master/transfer_fees'
import { getUserBankFullText } from '@/helpers/account/index'

const state = {}

const getters = {}

const actions = {
  async getTransferFeeList(context) {
    const token = context.getters.getAuthToken
    const data = await TransferFeesAPI.get(token)
    let transfer_fee_list = data.transfer_fee_list
    transfer_fee_list.forEach((item) => {
      item.user_bank.fulltext = getUserBankFullText(item.user_bank)
    })
    return transfer_fee_list
  },
  async createTransferFee(context, payload) {
    const token = context.getters.getAuthToken
    const data = await TransferFeesAPI.post(token, {
      transfer_type: payload.transfer_type,
      destination_bank_name: payload.destination_bank_name,
      destination_bank_code: payload.destination_bank_code,
      applied_condition_type: payload.applied_condition_type,
      applied_condition_min: payload.applied_condition_min,
      applied_condition_max: payload.applied_condition_max,
      transfer_fee: payload.transfer_fee,
      user_bank_id: payload.user_bank_id,
    })
    return data.transfer_fee
  },
  async updateTransferFee(context, payload) {
    const token = context.getters.getAuthToken
    const data = await TransferFeeAPI.put(token, payload.id, {
      id: payload.id,
      transfer_type: payload.transfer_type,
      destination_bank_name: payload.destination_bank_name,
      destination_bank_code: payload.destination_bank_code,
      applied_condition_type: payload.applied_condition_type,
      applied_condition_min: payload.applied_condition_min,
      applied_condition_max: payload.applied_condition_max,
      transfer_fee: payload.transfer_fee,
      user_bank_id: payload.user_bank_id,
    })
    return data.transfer_fee
  },
  async deleteTransferFee(context, payload) {
    const token = context.getters.getAuthToken
    await TransferFeeAPI.delete(token, payload.id)
  },
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
