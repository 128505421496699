import Full from 'Container/Full'

// component
const Inbox = () => import('@/pages/inbox/index')

// helpers
import { checkAuthorization, checkIp } from '../helpers/helpers.js'

export default {
  path: '/inbox',
  component: Full,
  meta: { requiresAuth: true },
  beforeEnter(to, from, next) {
    checkAuthorization(next)
    checkIp(next)
    next()
  },
  children: [
    {
      path: '',
      component: Inbox,
      name: 'inbox',
      meta: { title: '回収' },
    },
    {
      path: ':id',
      component: Inbox,
      meta: { title: '回収' },
    },
  ],
}
