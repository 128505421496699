import Vue from 'vue'
import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter, {
  thousandsSeparator: ',',
})

const vm = new Vue()

export function setDisplaySetting(obj, organization) {
  obj.department = organization.settingsDepartment
  obj.subject = organization.settingsSubAccount
  obj.project = organization.settingsProject
  obj.segment = organization.settingsSegment
  obj.vendor = organization.settingsVendor
  obj.item = organization.settingsItem
  obj.tag = organization.settingsTag
  obj.walletable = organization.settingsWalletable
  obj.free_text_1 = organization.settingsFreeText1
  obj.free_text_2 = organization.settingsFreeText2
  obj.tax = organization.settingsTax
  obj.description = organization.settingsDescription
}

export function copyObject(obj) {
  try {
    return Object.assign({}, obj)
  } catch (e) {
    return null
  }
}

export function currencyToNumber(amount) {
  try {
    if (!amount) {
      return 0
    }
    if (typeof amount == 'number') {
      return amount
    }
    return Number(String(amount).replace(/,/g, '').replace(/\s+/g, ''))
  } catch (e) {
    return 0
  }
}

export function numberToCurrencyUSD(amount) {
  try {
    if (amount === null || amount === undefined) {
      return '0.00'
    }
    return vm.$options.filters.currency(amount, '', ',', 2, '.')
  } catch (e) {
    console.log(e)
    return '0.00'
  }
}

export function numberToCurrency(amount) {
  try {
    if (amount === null || amount === undefined) {
      return '0'
    }
    var length = String(amount).length
    if (length <= 16) {
      amount = vm.$options.filters.currency(amount).replace(/\s+/g, '')
    } else {
      amount = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        .replace(/\s+/g, '')
    }
    return amount
  } catch (e) {
    console.log(e)
    return '0'
  }
}

export function calcGensenAmount(reward) {
  if (reward <= 1000000) {
    return parseInt(reward * 0.1021)
  } else {
    return 102100 + parseInt((reward - 1000000) * 0.2042)
  }
}

export function checkNull(text) {
  if (!text) {
    return ''
  } else {
    return text
  }
}

export function sliceText(text, i1, i2) {
  try {
    return text.slice(i1, i2)
  } catch (e) {
    return ''
  }
}

export function onChangeJournal(event, journal) {
  const value = event.obj ? event.obj : null
  const key = `${event.entrySide}_${event.field}`
  Vue.set(journal, key, value)
}

export function clearOne(journal) {
  for (let key of Object.keys(journal)) {
    if (key.includes('amount')) {
      journal[key] = '0'
    } else if(key.includes('rate')) {
      journal[key] = '0.0'
    } else {
      journal[key] = ''
    }
  }
}

export const journalFields = Object.freeze([
  'account_title',
  'sub_account',
  'department',
  'vendor',
  'project',
  'segment',
  'walletable',
  'item',
  'tag',
  'tax',
])

export const amountFields = Object.freeze([
  'debit_amount',
  'credit_amount',
  'debit_tax_amount',
  'credit_tax_amount',
  'debit_amount_without_tax',
  'credit_amount_without_tax',
  'debit_tax_amount_after_adjustment',
  'credit_tax_amount_after_adjustment',
  'debit_amount_without_tax_after_adjustment',
  'credit_amount_without_tax_aftere_adjustment',
])

export const fragFields = Object.freeze([
  'debit_is_keikasochi',
  'credit_is_keikasochi',
])

export const getEntrySideJournalFields = () => {
  return journalFields
    .map((field) => [`debit_${field}`, `credit_${field}`])
    .flat()
}

const journalFieldsWithEntrySide = getEntrySideJournalFields()

export const updateJournal = (formJournals, index, incomingJournal) => {
  const getMasterId = (master) => (master === null ? {} : master.id)
  const entrySideJournalFields = getEntrySideJournalFields()

  const getJournalDiff = (after, before) => {
    return Object.keys(after)
      .map((field) => {
        if (entrySideJournalFields.includes(field)) {
          if (getMasterId(after[field]) !== getMasterId(before[field])) {
            return { field, value: after[field] }
          }
        } else if (fragFields.includes(field)) {
          return after[field] === before[field] ? undefined : { field, value: after[field] }
        } else {
          let _after = after[field]
          let _before = before[field]
          if (amountFields.includes(field)) {
            _after = numberToCurrency(after[field])
            _before = numberToCurrency(before[field])
          }
          return _after === _before ? undefined : { field, value: _after }
        }
      })
      .filter((field) => field)
  }
  try {
    const updateObjects = getJournalDiff(incomingJournal, formJournals[index])
    for (const { field, value } of updateObjects) {
      Vue.set(formJournals[index], field, value)
    }
    return formJournals[index]
  } catch (e) {
    console.log(e)
  }
}

export function asyncSetJournals(incomingJournals, formInvoiceJournals) {
  const diff = incomingJournals.length - formInvoiceJournals.length
  if (diff === 0) {
    return incomingJournals.map((journal, index) => {
      return new Promise((resolve) =>
        setTimeout(() => {
          resolve(updateJournal(formInvoiceJournals, index, journal))
        }, 60)
      )
    })
  } else if (diff > 0) {
    // 追加分がある場合
    const promises = incomingJournals.map((journal, index) => {
      return new Promise((resolve) =>
        setTimeout(() => {
          if (index < formInvoiceJournals.length) {
            resolve(updateJournal(formInvoiceJournals, index, journal))
          } else {
            resolve(formInvoiceJournals.push(journal))
          }
        }, 60)
      )
    })
    return promises
  } else {
    // diff <0
    const promises = incomingJournals.map((journal, index) => {
      return new Promise((resolve) =>
        setTimeout(() => {
          resolve(updateJournal(formInvoiceJournals, index, journal))
        }, 60)
      )
    })
    for (let i = 0; i > diff; i--) {
      const promise = new Promise((resolve) =>
        setTimeout(() => {
          resolve(formInvoiceJournals.pop())
        }, 60)
      )
      promises.push(promise)
    }
    return promises
  }
}

export const convertJournalObjectToID = (journal) => {
  const result = {}
  const companyJournalSubAccountNames = ['credit_subject', 'debit_subject']
  const journalFieldSet = new Set([
    ...journalFieldsWithEntrySide,
    ...companyJournalSubAccountNames,
  ])
  for (const [field, value] of Object.entries(journal)) {
    if (journalFieldSet.has(field)) {
      result[`${field}_id`] = (value && value.id) || null
    } else {
      result[field] = value
    }
  }
  return result
}

export const floatToCurrency = (float) => {
  const floatString = String(float)
  const [intString, decimal] = floatString.split('.')
  if (!decimal) {
    return numberToCurrency(intString)
  } else {
    return `${numberToCurrency(intString)}.${decimal}`
  }
}
