<template>
  <base-dialog
    ref="dialog"
    :size="size"
    :title="$t('message.confirm')"
    :message="message"
  >
    <template #card-actions>
      <v-spacer />
      <v-btn depressed @click="close">
        {{ $t('message.modal_close_btn') }}
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'small',
    },
  },
  data() {
    return {
      message: '',
    }
  },
  methods: {
    open(message) {
      this.message = message
      this.$refs.dialog.open()
    },
    close() {
      this.$refs.dialog.close()
    },
  },
}
</script>
