export default {
  primary: '#1155cc',
  secondary: '#82b1ff',
  debit: '#c0d8ff',
  credit: '#b2ffad',
  dark: '#424242',
  accent: '#82B1FF',
  error: '#FF3739',
  info: '#00D0BD',
  success: '#4caf50',
  warning: '#d69909',
  light: '#dfdfdf',
}
