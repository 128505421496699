<template>
  <div>
    <v-dialog v-model="dialogCustomJournal" width="600">
      <v-card class="sweeep-dialog pa-6">
        <p
          class="fs-12"
          v-tooltip="
            '以下の優先順位で仕訳が適用されます<br>1. アップロード時の指定（この指定内容）<br>2. 仕訳パターン（取引先ごとの学習結果）<br>3. デフォルト仕訳（会社情報での設定。権限に応じて閲覧可能）'
          "
        >
          こちらの仕訳はデフォルト仕訳および仕訳パターンより優先されます
        </p>
        <journal-form-header />
        <v-row no-gutters class="pr-3">
          <template v-for="dc in ['debit', 'credit']">
            <v-col cols="6" :key="`dropzone_${dc}_journal`">
              <v-card
                tile
                outlined
                class="pb-3"
                style="background-color: #f5f5f5"
              >
                <div class="px-2 pt-2">
                  <journal-iterators
                    :journal="journal"
                    :editable="true"
                    :entrySide="dc"
                    @change="handleChangeEvent($event, journal)"
                  />
                </div>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCompanyList">
      <v-card style="sweeep-dialog height:90vh">
        <v-card-title v-text="'学習済み仕訳パターンを指定'" />

        <v-layout>
          <v-flex xs12>
            <invoice-company-list
              :definition="definition"
              :selectedJournal="selectedCompanyJournal"
            />
          </v-flex>
        </v-layout>

        <v-toolbar style="height: 65px">
          <v-spacer></v-spacer>
          <v-btn @click.native="dialogCompanyList = false">{{
            $t('message.modal_cancel_btn')
          }}</v-btn>
          <v-btn color="primary" v-on:click="setCompanyJournal()"
            >選択した仕訳パターンを指定</v-btn
          >
        </v-toolbar>
      </v-card>
    </v-dialog>

    <v-form v-if="session == true">
      <dropzone
        id="myVueDropzone"
        class="grey lighten-3"
        :options="dropzoneOptions"
        @vdropzone-file-added="onAddFile"
        @vdropzone-sending="sendingFile"
        @vdropzone-success="success"
        @vdropzone-sending-multiple="sendingMultiple"
        @vdropzone-processing="processingFile"
        @vdropzone-queue-complete="qcomplete"
        @vdropzone-complete="complete"
        @vdropzone-upload-progress="progress"
        @vdropzone-mounted="mounted"
      >
        <input type="hidden" name="token" value="xxx" />
      </dropzone>
      <v-progress-linear
        v-show="totalItems > 0"
        v-model="buffer"
        :buffer-value="bufferValue"
      ></v-progress-linear>
      <div v-show="totalItems > 0" class="text-center pt-2">
        {{ currItems }} / {{ totalItems }}
      </div>

      <v-row class="mt-2 px-3">
        <v-menu
          offset-y
          top
          nudge-top="20"
          z-index="99"
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              他のファイル取込方法
              <s-icon class="tw-pl-2" icon="feather-info" />
            </div>
          </template>

          <v-card class="sweeep-popover" width="400">
            <v-card-title>取込方法について</v-card-title>
            <v-card-text>
              <div>
                sweeep内の画面からアップロードする以外にも様々なファイル読み取り方法があります。
              </div>
              <div class="mt-3 fw-bold">メール取込</div>
              <div class="pl-3">
                請求書ファイルを添付したメールを専用アドレスへ送ることで取込が可能です。取引先や社員から受け取った請求書をすぐに転送できて便利です。
              </div>
              <div class="mt-3 fw-bold">チャット取込</div>
              <div class="pl-3">
                Slack、チャットワーク、LINEからの取込が可能です。社内コミュニケーションをチャットで行っている場合に便利です。
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-row>

      <v-row class="mt-8">
        <v-col cols="2">
          <span class="fw-bold">1. 日付</span>
          <v-menu
            offset-y
            top
            nudge-top="20"
            z-index="99"
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <s-icon class="tw-pl-2" icon="feather-info" />
              </span>
            </template>

            <v-card width="400">
              <v-toolbar flat dark height="40" class="fs-12"
                >日付について</v-toolbar
              >

              <v-card-text class="pa-4">
                <div>
                  伝票日付および支払日付を2種類の方法で読み取ることができます。
                </div>
                <div class="mt-3 fw-bold">OCR読取</div>
                <div class="pl-3">
                  OCR技術を使って読み取った日付がセットされます。一つも日付が取得できな場合は日付なしとなります。
                </div>
                <div class="mt-3 fw-bold">日付を指定</div>
                <div class="pl-3">
                  指定した日付がセットされます。支払日付を指定しない場合は、伝票日付からデフォルト支払サイトを元に計算されます。<br />（管理者設定＞デフォルト仕訳／支払で支払サイトの設定が可能）
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
        <v-col cols="5">
          <span class="fs-10">伝票日付</span>
          <v-radio-group v-model="issueDateByOcr" :mandatory="false">
            <v-radio :value="true" label="OCR読取" />
            <v-radio :value="false" label="日付を指定" />
          </v-radio-group>
          <v-menu :close-on-content-click="true" offset-y>
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date"
                v-show="!issueDateByOcr"
                placeholder=" "
                label="伝票日付"
                outlined
                dense
                hide-details
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              locale="jp-ja"
              no-title
              :day-format="(date) => new Date(date).getDate()"
            />
          </v-menu>
        </v-col>
        <v-col cols="5">
          <span class="fs-10">支払日付</span>
          <v-radio-group v-model="payDateByOcr" :mandatory="false">
            <v-radio :value="true" label="OCR読取" />
            <v-radio :value="false" label="日付を指定" />
          </v-radio-group>
          <v-menu :close-on-content-click="true" offset-y>
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="payDate"
                v-show="!payDateByOcr"
                placeholder=" "
                label="支払日付"
                outlined
                dense
                hide-details
                readonly
                clearable
                clearable-icon="feather-x"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="payDate"
              locale="jp-ja"
              no-title
              :day-format="(date) => new Date(date).getDate()"
            />
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2">
          <span class="fw-bold">2. 読取</span>
          <v-menu
            offset-y
            top
            nudge-top="20"
            z-index="99"
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <s-icon class="tw-pl-2" icon="feather-info" />
              </span>
            </template>

            <v-card class="sweeep-popover" width="400">
              <v-card-title>読取方法について</v-card-title>

              <v-card-text>
                <div>請求書ファイルを2種類の方法で読み取ることができます。</div>
                <div class="mt-3 fw-bold">全てのページを分割しない場合</div>
                <div class="pl-3">
                  アップロードした請求書を1ファイルにつき1請求書として扱います。明細ページや添付資料などが1つのファイルにまとめている場合はこちらを利用ください。
                </div>
                <div class="mt-3 fw-bold">全てのページを分割する場合</div>
                <div class="pl-3">
                  アップロードした請求書を1ページにつき1請求書として扱います。各ページを自動分割し読み取ります。複数の請求書を一度にスキャンして一つのPDFとした場合はこちらをご利用ください。
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
        <v-col cols="10">
          <div class="like-text-area">
            <v-autocomplete
              v-model="attachmentPage"
              :items="attachmentPageItems"
              item-text="name"
              item-value="page"
              label="OCR対象ページ"
              :disabled="splitPage"
              v-tooltip="
                'OCR対象以外のページは添付資料として扱います。<br>OCR対象ページを絞ることで自動仕訳精度が高まります。<br>OCR対象ページは最大5ページです。'
              "
            ></v-autocomplete>
            <v-checkbox
              v-model="splitPage"
              label="全てのページを分割する"
              dense
              hide-details
              class="mt-0"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2">
          <span
            v-tooltip.right="'請求書の所管部門を指定する事が可能です。'"
            class="fw-bold"
          >
            3. 所管部門
          </span>
        </v-col>
        <v-col cols="10">
          <v-autocomplete
            :value="invoiceDepartmentId"
            :items="departmentList"
            outlined
            hide-detail
            dense
            clearable
            item-text="name"
            item-value="id"
            @change="invoiceDepartmentId = $event || null"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2">
          <span class="fw-bold">4. 仕訳</span>
          <v-menu
            offset-y
            top
            nudge-top="20"
            z-index="99"
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <s-icon class="tw-pl-2" icon="feather-info" />
              </span>
            </template>

            <v-card class="sweeep-popover" width="400">
              <v-card-title>仕訳の指定について</v-card-title>

              <v-card-text>
                <div>
                  ここで指定された項目は読み取り後の仕訳に反映されます。
                </div>
                <div>この指定は学習済み仕訳に優先して適用されます。</div>
                <div class="mt-3 fw-bold">例</div>
                <div class="pl-3">
                  読み取る請求書の費用負担部門を全て「営業部」としたい場合は、「営業部」を指定した上で読み取りを行ってください。
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
        <v-col cols="10">
          <div
            class="like-text-area pointer"
            @click="dialogCustomJournal = true"
          >
            <span class="grey--text lighten-3 fs-12" v-show="sumChips == 0"
              >クリックして仕訳を指定</span
            >
            <template v-for="(item, index) in showChips">
              <v-chip
                :key="`chips_${index}`"
                :color="item.color"
                small
                label
                dark
                class="mr-2 my-1"
                v-if="item.name"
              >
                {{ item.title }}
                <span class="fw-bold ml-2">{{ item.name }}</span>
              </v-chip>
            </template>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import Axios from 'axios'
import Dropzone from 'vue2-dropzone'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import InvoiceCompanyList from './InvoiceCompanyList'
import Snackbar from 'Helpers/snackbar/index'
import {
  successNotify,
  errorNotify,
  chunkArray,
  createNotification,
} from 'Helpers/helpers'
import JournalSelect from 'Components/Journal/JournalSelect.vue'
import JournalIterators from 'Components/Journal/JournalIterators.vue'
import JournalFormHeader from 'Components/Journal/JournalFormHeader.vue'
import { onChangeJournal } from 'Helpers/journal'
import { convertUplaodErrorMessage } from 'Helpers/invoice_upload/index'

/*
journalItems:{
  account_title,
  tax,
  department,
  project,
  segment,
  vendor,
  item,
  tag,
  walletable,
  loaded
}
*/

export default {
  props: {
    organization: Object,
    definition: Object,
    journalItems: Object,
  },
  components: {
    Dropzone,
    InvoiceCompanyList,
    JournalSelect,
    JournalIterators,
    JournalFormHeader,
  },
  data() {
    return {
      issueDateByOcr: false,
      payDateByOcr: false,
      attachmentPage: 3,
      availableFileTypes: [
        'png',
        'PNG',
        'jpg',
        'jpeg',
        'JPG',
        'JPEG',
        'pdf',
        'PDF',
      ],
      attachmentPageItems: [
        {
          page: 1,
          name: '1ページ目のみ',
        },
        {
          page: 2,
          name: '2ページ目まで',
        },
        {
          page: 3,
          name: '3ページ目まで',
        },
        {
          page: 4,
          name: '4ページ目まで',
        },
        {
          page: 5,
          name: '5ページ目まで',
        },
      ],
      dialogCompanyList: false,
      dialogCustomJournal: false,
      splitPage: false,
      forcedUpload: false,
      dropzoneOptions: {},
      session: true,
      connected_internet: true,
      uploading: false,
      uploadNotifications: [],
      taskIds: [],
      currItems: 0,
      totalItems: 0,
      bufferValue: 100,
      resportSetting: false,
      showChips: {
        debit_account: { title: '借方科目', name: '', color: 'blue' },
        debit_subAccount: { title: '借方補助科目', name: '', color: 'blue' },
        debit_department: { title: '借方部門', name: '', color: 'blue' },
        debit_vendor: { title: '借方取引先', name: '', color: 'blue' },
        debit_project: { title: '借方プロジェクト', name: '', color: 'blue' },
        debit_segment: { title: '借方セグメント', name: '', color: 'blue' },
        debit_item: { title: '借方科目', name: '', color: 'blue' },
        debit_tag: { title: '借方メモタグ', name: '', color: 'blue' },
        debit_walletable: { title: '借方口座', name: '', color: 'blue' },
        debit_tax: { title: '借方税区分', name: '', color: 'blue' },

        credit_account: { title: '貸方科目', name: '', color: 'green' },
        credit_subAccount: { title: '貸方補助科目', name: '', color: 'green' },
        credit_department: { title: '貸方部門', name: '', color: 'green' },
        credit_vendor: { title: '貸方取引先', name: '', color: 'green' },
        credit_project: { title: '貸方プロジェクト', name: '', color: 'green' },
        credit_segment: { title: '貸方セグメント', name: '', color: 'green' },
        credit_item: { title: '貸方科目', name: '', color: 'green' },
        credit_tag: { title: '貸方メモタグ', name: '', color: 'green' },
        credit_walletable: { title: '貸方口座', name: '', color: 'green' },
        credit_tax: { title: '貸方税区分', name: '', color: 'green' },
      },
      journal: {
        debit_account_title: {},
        debit_sub_account: {},
        debit_department: {},
        debit_tax: {},
        debit_project: {},
        debit_segment: {},
        debit_vendor: {},
        debit_item: {},
        debit_tag: {},
        debit_walletable: {},
        credit_account_title: {},
        credit_sub_account: {},
        credit_department: {},
        credit_tax: {},
        credit_project: {},
        credit_segment: {},
        credit_vendor: {},
        credit_item: {},
        credit_tag: {},
        credit_walletable: {},
      },
      debit: {
        account: '',
        subAccount: '',
        department: '',
        tax: '',
        project: '',
        segment: '',
        vendor: '',
        item: '',
        tag: '',
        walletable: '',
      },
      credit: {
        account: '',
        subAccount: '',
        department: '',
        tax: '',
        project: '',
        segment: '',
        vendor: '',
        item: '',
        tag: '',
        walletable: '',
      },
      selectedCompanyJournal: [
        {
          debit: {
            account: '',
            subAccount: '',
            department: '',
            tax: '',
            project: '',
            segment: '',
            vendor: '',
            item: '',
            tag: '',
            walletable: '',
          },
          credit: {
            account: '',
            subAccount: '',
            department: '',
            tax: '',
            project: '',
            segment: '',
            vendor: '',
            item: '',
            tag: '',
            walletable: '',
          },
        },
      ],
      date: null,
      payDate: null,
      payDay: null,
      payMonth: null,
      payMonthList: [],
      payDayList: [],
      invoiceDepartmentId: null,
    }
  },
  watch: {
    date: function (val) {
      localStorage.setItem('issue_date', val)
    },
    splitPage: function (val) {
      localStorage.setItem('multiple_upload', !val)
    },
    attachmentPage: function (val) {
      localStorage.setItem('attachment_page', val)
    },
    currItems: function (val) {
      this.enableDropzone()
    },
    uploading: function (boolean) {
      if (!boolean) {
        try {
          const url = this.$store.getters.apiNotificationDropUrl
          const task_ids = this.taskIds
          Axios.post(url, task_ids, {
            headers: { Authorization: this.$store.getters.getAuthToken },
          }).then((response) => {
            this.taskIds.length = 0
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    issueDateByOcr: function (boolean) {
      try {
        localStorage.setItem('issueDateByOcr', boolean)
        if (boolean) {
          this.date = ''
        } else {
          this.date = this.getLastMonthEnd()
        }
      } catch (error) {
        console.log(error)
      }
    },
    payDateByOcr: function (boolean) {
      localStorage.setItem('payDateByOcr', boolean)
      this.payDate = ''
    },
    invoiceDepartmentId: function (val) {
      localStorage.setItem('invoiceDepartmentId', val)
    },
  },
  created() {
    try {
      this.dropzoneOptions = {
        url: this.$store.getters.apiInvoiceUploadUrl,
        thumbnailWidth: 150,
        uploadMultiple: true,
        autoProcessQueue: false,
        maxFilesize: 2,
        method: 'put',
        headers: { Authorization: this.$store.getters.getAuthToken },
        params: {},
        dictDefaultMessage:
          '<i class="feather-download" style="font-size:20px;color:#000"></i><br><br>ファイルをドラッグ＆ドロップするか<br>クリックして請求書をアップロードしてください。',
      }
      this.initDate()
      this.initUploadType()
      this.initInvoiceDepartmentId()
      this.initAttachmentPage()
      this.createPaySiteList()
    } catch (e) {
      console.log(e)
    }
  },
  computed: {
    ...mapGetters(['displaySetting', 'departmentList']),
    buffer: function () {
      return (this.currItems / this.totalItems) * 100
    },
    sumChips: function () {
      try {
        var sum = 0
        var chips = this.showChips
        for (let key in chips) {
          sum += chips[key].name.length
        }
        return sum
      } catch (e) {
        console.log(e)
      }
    },
  },
  methods: {
    handleChangeEvent(event, journal) {
      try {
        onChangeJournal(event, journal)
        const cleared = !event.obj
        const obj = event.obj
        const entrySide = event.entrySide
        let field = event.field

        if (field === 'account_title') {
          this.fillTaxRelated(obj, entrySide)
          field = 'account'
        } else if (field === 'sub_account') {
          field = 'subAccount'
        }

        const key = `${entrySide}_${field}`
        if (cleared) {
          this.setChips(key, '')
        } else {
          this.setChips(key, obj.name)
        }
        this.updateParams(entrySide)
      } catch (e) {
        console.log(e)
      }
    },
    getLastMonthEnd() {
      var now = new Date()
      var now_year = now.getFullYear()
      var now_month = now.getMonth()
      var issue = new Date(now_year, now_month, 0)
      var year = issue.getFullYear()
      var month = ('0' + (issue.getMonth() + 1)).slice(-2)
      var day = ('0' + issue.getDate()).slice(-2)
      const date = year + '-' + month + '-' + day
      return date
    },
    initDate() {
      try {
        var date = localStorage.getItem('issue_date')
        const issueDateByOcr = JSON.parse(
          localStorage.getItem('issueDateByOcr')
        )
        const payDateByOcr = JSON.parse(localStorage.getItem('payDateByOcr'))

        this.issueDateByOcr = issueDateByOcr ? true : false
        this.payDateByOcr = payDateByOcr ? true : false
        if (date || date === '') {
          this.date = date
        } else {
          this.date = this.getLastMonthEnd()
        }
      } catch (e) {
        console.log(e)
      }
    },
    getResportSetting() {
      try {
        Axios.get(this.$store.getters.apiResportSettingUrl, {
          headers: { Authorization: this.$store.getters.getAuthToken },
          params: { org_id: this.$store.getters.getOrganizationID },
        }).then((response) => {
          if (response.data.status == 'success') {
            this.resportSetting = response.data.resport_setting
          } else {
            Snackbar.error(response.data.error)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    initUploadType() {
      try {
        var multiple = localStorage.getItem('multiple_upload')
        if (multiple == 'true') {
          this.splitPage = false
        } else {
          this.splitPage = true
        }
      } catch (e) {
        console.log(e)
      }
    },
    initInvoiceDepartmentId() {
      try {
        const invoiceDepartmentId = JSON.parse(
          localStorage.getItem('invoiceDepartmentId')
        )

        this.invoiceDepartmentId = invoiceDepartmentId
          ? invoiceDepartmentId
          : null
      } catch (e) {
        console.log(e)
      }
    },
    initAttachmentPage() {
      try {
        var page = localStorage.getItem('attachment_page')
        if (page) {
          this.attachmentPage = Number(page)
        } else {
          this.attachmentPage = 3
        }
      } catch (e) {
        console.log(e)
      }
    },
    setChips(item, name) {
      try {
        if (name) {
          this.showChips[item].name = name
        } else {
          this.showChips[item].name = ''
        }
      } catch (e) {
        console.log(e)
      }
    },
    clearItems() {
      Object.keys(
        this.journal.forEach((key) => {
          this.journal[key] = {}
        })
      )
    },
    getName(obj) {
      try {
        return obj.name
      } catch {
        return ''
      }
    },
    setCompanyJournal() {
      let journal = this.selectedCompanyJournal[0]

      this.journal.debit_account = journal.debit.account
      this.journal.debit_subAccount = journal.debit.subAccount
      this.journal.debit_department = journal.debit.department
      this.journal.debit_vendor = journal.debit.vendor
      this.journal.debit_project = journal.debit.project
      this.journal.debit_segment = journal.debit.segment
      this.journal.debit_item = journal.debit.item
      this.journal.debit_tag = journal.debit.tag
      this.journal.debit_walletable = journal.debit.walletable
      this.journal.debit_tax = journal.debit.tax

      this.journal.credit_account = journal.credit.account
      this.journal.credit_subAccount = journal.credit.subAccount
      this.journal.credit_department = journal.credit.department
      this.journal.credit_vendor = journal.credit.vendor
      this.journal.credit_project = journal.credit.project
      this.journal.credit_segment = journal.credit.segment
      this.journal.credit_item = journal.credit.item
      this.journal.credit_tag = journal.credit.tag
      this.journal.credit_walletable = journal.credit.walletable
      this.journal.credit_tax = journal.credit.tax

      this.setChips('debit_account', this.getName(journal.debit.account))
      this.setChips('debit_subAccount', this.getName(journal.debit.subAccount))
      this.setChips('debit_department', this.getName(journal.debit.department))
      this.setChips('debit_project', this.getName(journal.debit.project))
      this.setChips('debit_segment', this.getName(journal.debit.segment))
      this.setChips('debit_vendor', this.getName(journal.debit.vendor))
      this.setChips('debit_item', this.getName(journal.debit.item))
      this.setChips('debit_tag', this.getName(journal.debit.tag))
      this.setChips('debit_walletable', this.getName(journal.walletable))
      this.setChips('debit_tax', this.getName(journal.debit.tax))

      this.setChips('credit_account', this.getName(journal.credit.account))
      this.setChips(
        'credit_subAccount',
        this.getName(journal.credit.subAccount)
      )
      this.setChips(
        'credit_department',
        this.getName(journal.credit.department)
      )
      this.setChips('credit_project', this.getName(journal.credit.project))
      this.setChips('credit_segment', this.getName(journal.credit.segment))
      this.setChips('credit_vendor', this.getName(journal.credit.vendor))
      this.setChips('credit_item', this.getName(journal.credit.item))
      this.setChips('credit_tag', this.getName(journal.credit.tag))
      this.setChips('credit_walletable', this.getName(journal.walletable))
      this.setChips('credit_tax', this.getName(journal.credit.tax))

      this.dialogCompanyList = false
    },
    createPaySiteList() {
      try {
        for (let i = 0; i <= 12; i++) {
          if (i == 0) {
            this.payMonthList.push({ name: '当月', value: i })
          } else {
            this.payMonthList.push({ name: i + 'ヶ月後', value: i })
          }
        }
        for (let i = 31; i >= 1; i--) {
          if (i == 31) {
            this.payDayList.push({ name: '月末', value: i })
          } else {
            this.payDayList.push({ name: i + '日', value: i })
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    formatDate(date) {
      var Y = date.getFullYear()
      var M = date.getMonth() + 1
      var D = date.getDate()
      var yyyy = ('000' + Y).slice(-4)
      var mm = ('0' + M).slice(-2)
      var dd = ('0' + D).slice(-2)
      return yyyy + '-' + mm + '-' + dd
    },
    getSubAccounts(account) {
      try {
        let subAccounts = this.journalItems.account_title.find(
          (item) => item.name == account.name
        ).subjects
        return subAccounts
      } catch {
        return []
      }
    },
    getJouranlParams(entrySide) {
      const fields = [
        'account',
        'subAccount',
        'department',
        'tax',
        'project',
        'segment',
        'vendor',
        'item',
        'tag',
        'walletable',
      ]
      const params = {}
      let key
      fields.forEach((field) => {
        if (field === 'account') {
          key = `${entrySide}_account_title`
        } else if (field === 'subAccount') {
          key = `${entrySide}_sub_account`
        } else {
          key = `${entrySide}_${field}`
        }
        params[field] = this.journal[key]
      })
      return params
    },
    updateParams(type) {
      try {
        Vue.nextTick(() => {
          params = this.getJouranlParams(type)
          if (type == 'credit') {
            this.dropzoneOptions.params.credit = JSON.stringify(params)
          } else if (type == 'debit') {
            this.dropzoneOptions.params.debit = JSON.stringify(params)
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    uploadFile(file) {
      try {
        var t = this
        var reader = new FileReader()
        var status = false
        const paramsDebit = t.getJouranlParams('debit')
        const paramsCredit = t.getJouranlParams('credit')

        reader.onload = function (e) {
          status = true

          $.ajax({
            url: t.dropzoneOptions.url,
            type: 'PUT',
            dataType: 'json',
            data: {
              title: 'default',
              image: e.target.result,
              upload_source: file.name,
              date: t.date,
              pay_date: t.payDate,
              pay_day: t.payDay,
              pay_month: t.payMonth,
              user_organization_id: t.$store.getters.getUserOrganizationID,
              organization_id: t.$store.getters.getOrganizationID,
              debit: JSON.stringify(paramsDebit),
              invoice_department_id: t.invoiceDepartmentId,
              credit: JSON.stringify(paramsCredit),
              multiple: !t.splitPage,
              attachment_page: t.attachmentPage,
            },
            beforeSend: function (xhr) {
              /* Authorization header */
              xhr.setRequestHeader(
                'Authorization',
                t.$store.getters.getAuthToken
              )
            },
            error: function (response) {
              errorNotify('Error on uploading ' + file.name)
            },
            success: function (response) {
              if (response.error) {
                t.currItems += 1
                const message = convertUplaodErrorMessage(response.error)
                errorNotify(message)
              } else {
                t.currItems += 1
                t.taskIds = [...t.taskIds, ...response.queue_ids]
              }
            },
          })
        }
        reader.onloadend = function (e) {
          if (!status) {
            setTimeout(function () {
              t.totalItems += -1
            }, 500)
          }
        }
        reader.readAsDataURL(file)
      } catch (error) {
        console.log(error)
      }
    },
    onAddFile(file) {
      let t = this
      this.$store.dispatch('getUploadList', {})
      this.processingFile()
      this.uploading = true
      this.totalItems += 1

      let uploadTimer = setInterval(() => {
        t.uploadFile(file)
        clearInterval(uploadTimer)
      }, 1000)

      try {
        $('.dropzone').removeClass('dz-started')
        $('.dz-preview').remove()
      } catch {}
    },
    qcomplete(file, xhr, formData) {},
    complete(file) {
      $('.dz-hidden-input').prop('disabled', true)
    },
    processingFile() {
      $('.dz-hidden-input').prop('disabled', true)
    },
    enableDropzone() {
      try {
        if (this.currItems === this.totalItems) {
          this.uploading = false
          $('.dz-hidden-input').prop('disabled', false)
        }
      } catch (error) {
        console.log(error)
      }
    },
    makeid() {
      var text = ''
      var possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      for (var i = 0; i < 100; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))
      return text
    },
    convertFile(file) {
      var t = this
    },
    setFile(file, response) {},
    sendingFile(file, response, formData) {},
    sendingMultiple(files, xhr, formData) {},
    queueComplete(file, response) {},
    success(file, response) {},
    progress(file, progress, bytesSent) {},
    mounted() {
      var x = document.getElementsByClassName('dz-hidden-input')
      $('.dz-hidden-input').attr('id', 'input-file-upload')
      this.getResportSetting()
    },
    fillTaxRelated(accountTitle, type) {
      try {
        if (!!accountTitle && !!accountTitle.tax_classification_id) {
          this.$store.getters.taxList.forEach((taxItem) => {
            if (taxItem.id === accountTitle.tax_classification_id) {
              this.journal[type + '_tax'] = taxItem
              return true
            }
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
