<template>
  <v-snackbar
    v-model="active"
    :timeout="timeout"
    :centered="centered"
    :color="color"
    :left="left"
    :right="right"
    :top="top"
    :vertical="vertical"
    :multiple="multiple"
    @click="dismiss"
  >
    <v-icon v-if="icon.length > 0" dark left>
      {{ icon }}
    </v-icon>
    <span style="white-space: pre-line">{{ computedText }}</span>
    <v-btn v-model="bottom" color="white" text @click="active = false">
      <v-icon small> feather-x </v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      text: '',
      icon: '',
      color: '',
      timeout: null,
      centered: false,
      dismissible: true,
      right: false,
      left: false,
      top: false,
      vertical: false,
      multiple: false,
      bottom: false,
    }
  },
  computed: {
    computedText: function () {
      if (this.text.search('<br>')) {
        return this.text.replace(/<br>/g, '\n')
      } else {
        return this.text
      }
    },
  },
  methods: {
    show(options = {}) {
      if (this.active) {
        this.close()
        this.$nextTick(() => this.show(options))
        return
      }
      Object.keys(options).forEach((field) => {
        this[field] = options[field]
      })
      this.active = true
    },
    close() {
      this.active = false
    },
    dismiss() {
      if (this.dismissible) {
        this.active = false
      }
    },
  },
}
</script>
