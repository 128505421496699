export function initInputsObj(obj) {
  try {
    if (obj.name) {
      obj.name = ''
    }
    if (obj.code) {
      obj.code = ''
    }
    if (obj.status) {
      obj.status = 1
    }
  } catch (e) {
    return
  }
}

export function updateTableDataStatus(data, ids, status) {
  ids.forEach((id) => {
    let obj = data.find((item) => item.id == id)
    if (obj) {
      obj.status = status
    }
  })
}

export function deleteTableData(data, ids) {
  ids.forEach((id) => {
    let index = data.findIndex((item) => item.id == id)
    if (index >= 0) {
      data.splice(index, 1)
    }
  })
}

export function getTransferFeeErrorMessage(error) {
  const errorToMessage = {
    overlap_existing_condition:
      '既存の適用条件と重複しているため登録できません。\n適用範囲が被らないように登録してください。',
    invalid_condition_range: '適用条件の範囲が不正です。',
  }
  if (error in errorToMessage) {
    return errorToMessage[error]
  } else {
    return '予期せぬエラーが発生しました。'
  }
}
