/**
 * Mail Module
 */
import { emailList } from './data'

const state = {
  allEmails: emailList,
  emailList: null,
  loadingEmails: false,
  selectedEmail: null,
}

const getters = {
  emailList: (state) => {
    return state.emailList
  },
  loadingEmails: (state) => {
    return state.loadingEmails
  },
  selectedEmail: (state) => {
    return state.selectedEmail
  },
}

const actions = {
  getEmails(context) {
    context.commit('showLoadingEmailsIndicator')
    setTimeout(() => {
      context.commit('getEmails')
    }, 500)
  },
  markAsStarEmail(context, payload) {
    context.commit('markAsStarEmailHandler', payload)
  },
  filterEmails(context, payload) {
    context.commit('showLoadingEmailsIndicator')
    setTimeout(() => {
      context.commit('filterEmailsHandler', payload)
    }, 500)
  },
  backToEmails(context, payload) {
    context.commit('backToEmailsHandler')
  },
  onDeleteEmail(context) {
    context.commit('onDeleteEmailHandler')
  },
  onSearchEmail(context, payload) {
    context.commit('onSearchEmailHandler', payload)
  },
}

const mutations = {
  showLoadingEmailsIndicator(state) {
    state.loadingEmails = true
  },
  getEmails(state) {
    state.emails = state.allEmails.filter((email) => email.inbox)
    state.loadingEmails = false
  },
  markAsStarEmailHandler(state, email) {
    let indexOfEmail = state.emails.indexOf(email)
    state.emails[indexOfEmail].starred = !email.starred
  },
  onDeleteEmailHandler(state) {
    for (let i = 0; i < state.emails.length; i++) {
      const element = state.allEmails[i]
      if (element.id === state.selectedEmail.id) {
        state.emails[i].trash = true
        state.emails[i].inbox = false
        state.selectedEmail = null
      }
    }
  },
  filterEmailsHandler(state, filter) {
    switch (filter.id) {
      case 1:
        state.emails = state.allEmails.filter((email) => email.inbox)
        break
      case 2:
        state.emails = state.allEmails.filter((email) => email.draft)
        break
      case 3:
        state.emails = state.allEmails.filter((email) => email.starred)
        break
      case 4:
        state.emails = state.allEmails.filter((email) => email.sent)
        break
      case 5:
        state.emails = state.allEmails.filter((email) => email.spam)
        break
      case 6:
        state.emails = state.allEmails.filter((email) => email.trash)
        break
      default:
        state.emails = state.allEmails.filter((email) => email.inbox)
        break
    }
    state.loadingEmails = false
    state.selectedEmail = null
  },
  backToEmailsHandler(state) {
    state.selectedEmail = null
  },
  onSearchEmailHandler(state, text) {
    if (text === '') {
      state.emails = state.allEmails.filter((email) => email.inbox)
    }
    state.emails = state.allEmails.filter(
      (email) => email.text.toLowerCase().indexOf(text.toLowerCase()) > -1
    )
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
