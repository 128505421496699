<template>
  <v-menu
    v-if="userOrganizations.length > 0"
    offset-y
    z-index="99"
    transition="slide-y-transition"
    nudge-bottom="15"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text style="text-transform: none" v-bind="attrs" v-on="on">
        {{ companyName }}
        <v-icon x-small class="ml-2"> feather-chevron-down </v-icon>
      </v-btn>
    </template>
    <div v-if="userOrganizations.length > 0">
      <v-card class="sweeep-popover">
        <v-card-title> 会社を選択 </v-card-title>
        <vue-perfect-scrollbar :style="getScrollHeight()">
          <v-list dense>
            <v-list-item>
              <i class="mr-3 feather-home primary--text" />
              <span class="fs-11">{{ companyName }}</span>
              <v-chip
                x-small
                label
                color="blue"
                outlined
                class="pa-2 ml-2"
                v-text="'選択中'"
              />
            </v-list-item>
            <v-list-item
              v-for="x in userOrganizations"
              :key="x.organization_name"
              link
              @click="signin(x.user_organization_id)"
            >
              <i class="mr-3 feather-home primary--text" />
              <span class="fs-11">{{ x.organization_name }}</span>
            </v-list-item>
          </v-list>
        </vue-perfect-scrollbar>
      </v-card>
    </div>
  </v-menu>
  <div v-else>
    <span class="fs-10 pr-2">{{ companyName }}</span>
  </div>
</template>

<script>
import Snackbar from 'Helpers/snackbar/index'

export default {
  props: {},
  data() {
    return {
      loading: false,
      hoverOrgs: false,
      setting: [
        {
          title: 'message.accountData',
          icon: 'ti-user primary--text',
        },
      ],
      logoff: [
        {
          title: 'message.logOut',
          icon: 'ti-power-off error--text',
        },
      ],
    }
  },
  computed: {
    companyName() {
      return this.$store.getters.getOrganizationName
    },
    userOrganizations() {
      const userOrganizations = [...this.$store.getters.userOrganizations]
      if (userOrganizations.length === 0) {
        return []
      }
      const loginOrganizationIndex = userOrganizations.findIndex((userOrg) => {
        return (
          userOrg.user_organization_id ===
          this.$store.getters.getUserOrganizationID
        )
      })
      userOrganizations.splice(loginOrganizationIndex, 1)
      return userOrganizations
    },
  },
  methods: {
    goToAccount() {
      this.$router.push('/account')
    },
    logout() {
      this.$store.dispatch('logOutUser')
    },
    signin(user_organization_id) {
      try {
        if (user_organization_id) {
          this.loading = true
          var apiUrl = this.$store.getters.apiSignInAnother
          var token = this.$store.getters.getAuthToken
          var envtype = this.$store.getters.getEnvType

          this.$store
            .dispatch('apiSignInAnother', {
              user_organization_id,
              envtype,
              apiUrl,
              token,
            })
            .then((response) => {
              if (response.data.error) {
                Snackbar.error(response.data.error)
              }
              this.loading = false
            })
        }
      } catch (e) {
        console.log(e)
      }
    },
    getScrollHeight() {
      return 'max-height: calc(100vh - 150px)'
    },
  },
}
</script>
