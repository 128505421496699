import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class PaymentDetailAPI extends APIClient {
  getErrorMessage(error) {
    const errorToMessage = {
      unable_to_edit_paid_record: '支払済のデータは更新できません。',
    }
    if (error in errorToMessage) {
      return errorToMessage[error]
    } else {
      return '予期せぬエラーが発生しました。'
    }
  }

  async put(token, data) {
    const payload = {
      data,
      url: URLs.PAYMENT_DETAIL,
      method: 'PUT',
      headers: {
        Authorization: token,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(this.getErrorMessage(response.data.error))
      }
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }
}
export default new PaymentDetailAPI()
