import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class EmailsAPI extends APIClient {
  async get(token, params) {
    const payload = {
      url: URLs.EMAILS,
      method: 'GET',
      headers: {
        Authorization: token,
      },
      params: {
        date_from: params.dateFrom,
        date_to: params.dateTo,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(response.data.error)
      }
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }
}
export default new EmailsAPI()
