import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class FreeeSeparateApprovalAPI extends APIClient {
  async post(token, { separate_approval }) {
    const payload = {
      url: URLs.FREEE_APPROVAL_TYPE,
      method: 'POST',
      headers: {
        Authorization: token,
      },
      data: {
        separate_approval: separate_approval,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }
}
export default new FreeeSeparateApprovalAPI()
