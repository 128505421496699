/**
 * App Config File
 */
export default {
  appLogo: '/static/img/site-logo.png', // App Logo,
  darkLogo: '/static/img/site-dark-logo.png', // dark logo
  appLogo2: '/static/img/session.png', // App Logo 2 For Login & Signup Page
  brand: 'sweeep', // Brand Name
  copyrightText: 'sweeep © 2018 All Rights Reserved.', // Copyright Text
  enableUserTour: process.env.NODE_ENV === 'production' ? false : false, // Enable User Tour
  pagination: [25, 50, 100],
}
