import Vue from 'vue'
import Snackbar from 'Helpers/snackbar/index'

import InvoiceUploadAPI from '@/clients/invoice/InvoiceUpload'

// state
const state = {
  invoiceUploadList: {
    uploadList: [],
    lastCount: 0,
  },
  invoiceUploadListLatestPid: 0,
  invoiceUploadNewIndex: [],
}

// getters
const getters = {
  invoiceUploadList: (state) => state.invoiceUploadList,
  invoiceUploadListLatestPid: (state) => state.invoiceUploadListLatestPid,
  invoiceUploadNewIndex: (state) => state.invoiceUploadNewIndex,
}

//actions
const actions = {
  getUploadList(
    context,
    { checkUpdate = 0, timeout = 0, count = 0, pid = null, noupdate = 0 }
  ) {
    // checkUpdate 0 or 1
    try {
      let latestPid = context.getters.invoiceUploadListLatestPid
      if (count === 0) {
        latestPid += 1
        context.commit('setInvoiceUploadListLatestPid', latestPid)
        pid = latestPid
      }
      if (pid < latestPid || noupdate > 3) {
        return
      }

      setTimeout(() => {
        context.dispatch('requestGetUploadList', checkUpdate).then((data) => {
          const invoiceUploadList = context.getters.invoiceUploadList
          const shouldGet = invoiceUploadList.uploadList.some((item) =>
            ['STARTED', 'WAITING'].includes(item.state)
          )
          let payload = {
            checkUpdate: 1,
            timeout: 3000,
            count: count + 1,
            pid: pid,
            noupdate: 0,
          }
          if (count < 2) {
            context.dispatch('getUploadList', payload)
          } else if (shouldGet || data.updated) {
            context.dispatch('getUploadList', payload)
          } else {
            payload.noupdate = noupdate + 1
            context.dispatch('getUploadList', payload)
          }
        })
      }, timeout)
    } catch (error) {
      console.log(error)
    }
  },
  async requestGetUploadList(context, check_update) {
    function showWarnMessage(uploadList) {
      try {
        let newInvoiceIndex = context.getters.invoiceUploadNewIndex
        newInvoiceIndex.forEach((index) => {
          if (
            uploadList[index].error &&
            uploadList[index].error.params &&
            uploadList[index].error.params.duplicate_local_ids.length > 0
          ) {
            const option = {
              text: '請求書のアップロードが完了しました。\n最近アップロードしたファイルと同じファイル名です。\n重複がないかご注意ください。',
              color: 'warning',
            }
            Snackbar.show(option)
            context.commit('setInvoiceUploadNewIndex', [])
            return
          }
          if (uploadList[index].state == 'SUCCESS') {
            const option = {
              text: '請求書のアップロードが完了しました。',
              color: 'success',
            }
            Snackbar.show(option)
            context.commit('setInvoiceUploadNewIndex', [])
            return
          }
        })
      } catch (e) {
        console.log(e)
      }
    }

    const invoiceUploadList = context.getters.invoiceUploadList
    const lastCount = invoiceUploadList.lastCount
    const token = context.getters.getAuthToken
    const params = {
      check_update: check_update,
      last_count: lastCount,
    }
    const data = await InvoiceUploadAPI.get(token, params)
    const updated = data.updated === 1
    if (updated) {
      const prevImageCount = invoiceUploadList.uploadList.length
      const newImageCount = data.upload_list.length - prevImageCount
      if (prevImageCount !== 0 && newImageCount !== 0) {
        for (let i = 0; i < newImageCount; i++) {
          let newInvoiceIndex = context.getters.invoiceUploadNewIndex
          newInvoiceIndex.push(prevImageCount + i)
          context.commit('setInvoiceUploadNewIndex', newInvoiceIndex)
        }
      }

      context.commit('setInvoiceUploadList', {
        uploadList: data.upload_list,
        lastCount: data.last_count,
      })
      showWarnMessage(data.upload_list)
    }

    return data
  },
}

//mutations
const mutations = {
  setInvoiceUploadList(state, params) {
    Object.keys(params).forEach((field) => {
      Vue.set(state.invoiceUploadList, field, params[field])
    })
  },
  setInvoiceUploadListLatestPid(state, value) {
    state.invoiceUploadListLatestPid = value
  },
  setInvoiceUploadNewIndex(state, value) {
    state.invoiceUploadNewIndex = value
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
