<template>
  <v-dialog
    v-model="dialog"
    :max-width="applyWidth"
    :persistent="persistent"
    :no-click-animation="persistent"
    :scrollable="scrollable"
    @click:outside="$emit('click:outside')"
  >
    <v-card class="sweeep-dialog">
      <v-card-title v-text="title" />
      <v-card-text>
        <slot name="card-text">
          <span style="white-space: pre-line">
            {{ message }}
          </span>
        </slot>
      </v-card-text>

      <div class="px-5">
        <slot name="card-content" />
      </div>

      <v-card-actions>
        <slot name="card-actions">
          <v-switch
            v-if="confirmShowDialog && localStorageSetName"
            v-model="shouldShowDialogFuture"
            dense
            hide-details
            :label="'今後このダイアログを表示しない'"
          />
          <v-spacer />
          <v-btn
            v-if="!hideCancelBtn"
            :disabled="loading || loading2"
            @click="cancel()"
          >
            {{ cancelText }}
          </v-btn>
          <v-btn
            v-if="!hideSubmitBtn"
            color="primary"
            :loading="loading || loading2"
            :disabled="disabledSubmit"
            @click="submit()"
          >
            {{ submitText }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: 'no',
    },
    submitText: {
      type: String,
      default: 'yes',
    },
    hideCancelBtn: {
      type: Boolean,
      default: false,
    },
    hideSubmitBtn: {
      type: Boolean,
      default: false,
    },
    disabledSubmit: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    closeOnCancel: {
      type: Boolean,
      default: true,
    },
    closeOnSubmit: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    confirmShowDialog: {
      type: Boolean,
      default: false,
    },
    localStorageSetName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      data: null,
      loading2: false,
      width: '',
      shouldShowDialogFuture: false,
    }
  },
  beforeMount() {
    this.setWidth()
  },
  methods: {
    open(data = null) {
      if (localStorage.getItem(this.localStorageSetName)) {
        this.submit()
        return
      }
      this.stopLoading()
      this.data = data
      this.dialog = true
    },
    close() {
      this.stopLoading()
      this.dialog = false
    },
    cancel() {
      if (this.closeOnCancel) {
        this.close()
      }
      this.$emit('cancel')
    },
    startLoading() {
      this.loading2 = true
    },
    stopLoading() {
      this.loading2 = false
    },
    submit() {
      this.$emit('submit', this.data)
      if (this.closeOnSubmit) {
        this.close()
      }
    },
    setWidth() {
      if (this.maxWidth) {
        this.applyWidth = this.maxWidth
      } else {
        switch (this.size) {
          case 'small':
            this.applyWidth = '300'
            break
          case 'medium':
            this.applyWidth = '500'
            break
          case 'large':
            this.applyWidth = '700'
            break
          case 'x-large':
            this.applyWidth = '900'
            break
          default:
            this.applyWidth = '500'
            break
        }
      }
    },
    setLocalStorage() {
      if (this.shouldShowDialogFuture) {
        localStorage.setItem(this.localStorageSetName, true)
      }
    },
  },
}
</script>
