import Vue from 'vue'
import Router from 'vue-router'
import Nprogress from 'nprogress'

//routes
import accountRoutes from './account'
import { applicationRoutes, appAuthorizeRoutes } from './application'
import freeeRoutes from './freee'
import myCompanyRoutes from './mycompany'
import invoiceRoutes from './invoice'
import paymentRoutes from './payment'
import uploadRoutes from './upload'
import apiUpload from './api-upload'
import report from './report'
import inboxRoutes from './inbox'
import masterRoutes from './master'
import tempUploadRoutes from './tempupload'
import pdfRoutes from './pdf'
import initialSettingsRoutes from './initial-settings'
import role from './role'
import email from './email'
import gdrive from './gdrive'
// import testRoute from "@/views/test/route"

// session components
const SignUpOne = () => import('Views/session/SignUpOne')
const SignUpWizard = () => import('Views/session/SignUpWizard')
const LoginOne = () => import('Views/session/LoginOne')
const ForgotPassword = () => import('Views/session/ForgotPassword')
const ResetPassword = () => import('Views/session/ResetPassword')
const InviteConfirm = () => import('Views/session/InviteConfirm')
const Auth0CallBack = () => import('Components/Auth0Callback/Auth0Callback')
const PageNotFound = () => import('Views/pages/404-page')
const NoAccess = () => import('Views/pages/NoAccess')

// helpers
import {
  clearSession,
  getLocalStorage,
  getCookieStorage,
} from '../helpers/helpers.js'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    accountRoutes,
    masterRoutes,
    applicationRoutes,
    appAuthorizeRoutes,
    freeeRoutes,
    invoiceRoutes,
    paymentRoutes,
    myCompanyRoutes,
    uploadRoutes,
    report,
    inboxRoutes,
    apiUpload,
    tempUploadRoutes,
    pdfRoutes,
    initialSettingsRoutes,
    role,
    email,
    gdrive,
    // テストページのためコメントアウト
    // testRoute,

    {
      path: '/callback',
      component: Auth0CallBack,
    },
    {
      path: '/session/sign-up',
      component: SignUpOne,
      meta: {
        breadcrumb: 'Session / Sign Up',
      },
    },
    {
      path: '/session/cs-entry',
      component: SignUpOne,
      meta: {
        breadcrumb: 'Session / Sign Up　By Support',
      },
    },

    {
      path: '/session/sign-up/confirm/:id/:code',
      component: SignUpWizard,
      meta: {
        breadcrumb: 'Session / Sign Up / Confirm',
      },
    },
    {
      path: '/session/invite/confirm/:code',
      component: InviteConfirm,
      meta: {
        breadcrumb: 'Session / Invite/ Confirm',
      },
      beforeEnter(to, from, next) {
        clearSession()
        next()
      },
    },
    {
      path: '/session/login',
      name: 'session_login',
      component: LoginOne,
      meta: {
        title: 'ログイン',
        breadcrumb: 'Session / Login',
      },
      beforeEnter(to, from, next) {
        if (from.fullPath == '/') {
          if (
            window.location.hostname != process.env.VUE_APP_SUBDOMAIN_ACCOUNTS
          ) {
            next('pagenotfound')
          } else {
            if (getLocalStorage() === true && getCookieStorage() === true) {
              window.location.href =
                process.env.VUE_APP_SUBDOMAIN_APP_FULL + '/invoice'
            }
          }
        } else {
          clearSession()
          window.location.href =
            process.env.VUE_APP_SUBDOMAIN_ACCOUNTS_FULL + '/session/login'
        }
        next()
      },
    },
    {
      path: '/session/forgot-password',
      component: ForgotPassword,
      meta: {
        breadcrumb: 'Session / Forgot Password',
      },
    },
    {
      path: '/session/reset-password/:id/:code',
      component: ResetPassword,
      meta: {
        breadcrumb: 'Session / Reset Password',
      },
    },
    {
      path: '/',
      beforeEnter(to, from, next) {
        if (
          window.location.hostname == process.env.VUE_APP_SUBDOMAIN_ACCOUNTS
        ) {
          next({ name: 'session_login' })
        } else if (
          window.location.hostname == process.env.VUE_APP_SUBDOMAIN_APP
        ) {
          next({ name: 'invoice' })
        } else {
          next()
        }
      },
    },
    {
      path: '*',
      name: 'pagenotfound',
      component: PageNotFound,
    },
    {
      path: '/noaccess',
      name: 'noaccess',
      component: NoAccess,
    },
  ],
})

const setTitle = (meta) => {
  const siteTitle = 'sweeep - 請求書のストレスをゼロに'
  const pageTitle = !meta.title ? siteTitle : meta.title + ' | ' + siteTitle
  return (window.document.title = pageTitle)
}

// navigation guards before each
router.beforeEach((to, from, next) => {
  setTitle(to.meta)
  Nprogress.start()
  next()
})

// navigation guard after each
router.afterEach((to, from) => {
  Nprogress.done()
  setTimeout(() => {
    const contentWrapper = document.querySelector('.v-content__wrap')
    if (contentWrapper !== null) {
      contentWrapper.scrollTop = 0
    }
  }, 200)
})

export default router
