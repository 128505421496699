import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class RecurringInvoiceScheduleAPI extends APIClient {
  async get(token, { scheduleId }) {
    const payload = {
      url: URLs.RECURRING_INVOICE_SCHEDULE,
      method: 'GET',
      headers: {
        Authorization: token,
      },
      args: {
        schedule_id: scheduleId,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      return response.data.schedule
    } catch (err) {
      throw this.createResponseError(err)
    }
  }

  async create(
    token,
    {
      invoiceId,
      startAt,
      endAt,
      issueDay,
      issueMonths,
      paymentTermMonth,
      paymentTermDay,
    }
  ) {
    const payload = {
      url: URLs.RECURRING_INVOICE_SCHEDULES,
      method: 'POST',
      headers: {
        Authorization: token,
      },
      data: {
        invoice_id: invoiceId,
        start_at: startAt,
        end_at: endAt,
        issue_day: issueDay,
        issue_months: issueMonths,
        payment_term_month: paymentTermMonth,
        payment_term_day: paymentTermDay,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      return response.data.schedule
    } catch (err) {
      throw this.createResponseError(err)
    }
  }

  async update(
    token,
    {
      scheduleId,
      startAt,
      endAt,
      issueDay,
      issueMonths,
      paymentTermMonth,
      paymentTermDay,
    }
  ) {
    const payload = {
      url: URLs.RECURRING_INVOICE_SCHEDULE,
      method: 'PATCH',
      headers: {
        Authorization: token,
      },
      args: {
        schedule_id: scheduleId,
      },
      data: {
        start_at: startAt,
        end_at: endAt,
        issue_day: issueDay,
        issue_months: issueMonths,
        payment_term_month: paymentTermMonth,
        payment_term_day: paymentTermDay,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      return response.data.schedule
    } catch (err) {
      throw this.createResponseError(err)
    }
  }
}
export default new RecurringInvoiceScheduleAPI()
