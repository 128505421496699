import Vue from 'vue'
import axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'

function errorNotify(msg) {
  Snackbar.error(msg)
}

const state = {}

const getters = {}

const actions = {
  deleteAccount(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl
    const auth_token = data.auth_token
    return new Promise((resolve, reject) => {
      axios
        .delete(apiUrl, {
          data: { id: data.id },
          headers: { Authorization: auth_token },
        })
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  editAccount(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl
    const auth_token = data.auth_token
    return new Promise((resolve, reject) => {
      axios
        .put(
          apiUrl,
          {
            id: data.id,
            name: data.name,
            tax_classification_id: data.tax_classification_id,
            user_organization_id: data.user_organization_id,
            organization_id: data.organization_id,
            code: data.code,
            status: data.status,
            settings_subaccount: data.settings_subaccount,
            settings_department: data.settings_department,
            settings_vendor: data.settings_vendor,
            settings_project: data.settings_project,
            settings_segment: data.settings_segment,
            settings_item: data.settings_item,
            settings_tag: data.settings_tag,
            settings_walletable: data.settings_walletable,
            settings_tax: data.settings_tax,
            gensenzei_account: data.gensenzei_account, // 源泉対象フラグ（gensenzei_accountカラムの値）
            subjects: data.subjects,
          },
          {
            headers: { Authorization: auth_token },
          }
        )
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  addAccount(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl
    const auth_token = data.auth_token
    return new Promise((resolve, reject) => {
      axios
        .post(
          apiUrl,
          {
            name: data.name,
            tax_classification_id: data.tax_classification_id,
            user_organization_id: data.user_organization_id,
            organization_id: data.organization_id,
            code: data.code,
            status: data.status,
            gensenzei_account: data.gensenzei_account, // 源泉対象フラグ（gensenzei_accountカラムの値）
          },
          {
            headers: { Authorization: auth_token },
          }
        )
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  addSupplementary(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          context.getters.apiSupplementaryUrl,
          {
            name: payload.data.subject_name,
            company_account_title_id: payload.data.id,
            code: payload.data.code,
            status: payload.data.status,
            user_organization_id: context.getters.getUserOrganizationID,
            organization_id: context.getters.getOrganizationID,
          },
          {
            headers: { Authorization: context.getters.getAuthToken },
          }
        )
        .then(function (response) {
          if (response.data.error) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {})
    })
  },
  deleteSupplementary(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(context.getters.apiSupplementaryUrl, {
          data: { id: payload.data.id },
          headers: { Authorization: context.getters.getAuthToken },
        })
        .then(function (response) {
          if (response.data.error) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
    })
  },
  editSupplementary(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          context.getters.apiSupplementaryUrl,
          {
            id: payload.subAccount.id,
            name: payload.subAccount.name,
            company_account_title_id:
              payload.subAccount.company_account_title_id,
            code: payload.subAccount.code,
            status: payload.subAccount.status,
            user_organization_id: context.getters.getUserOrganizationID,
            organization_id: context.getters.getOrganizationID,
          },
          {
            headers: { Authorization: context.getters.getAuthToken },
          }
        )
        .then(function (response) {
          if ('error' in response.data) {
            reject(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error.message)
        })
    })
  },
}

export default {
  state,
  getters,
  actions,
}
