import { render, staticRenderFns } from "./STextField.vue?vue&type=template&id=dfdc5f98&scoped=true&"
import script from "./STextField.vue?vue&type=script&lang=ts&"
export * from "./STextField.vue?vue&type=script&lang=ts&"
import style0 from "./STextField.vue?vue&type=style&index=0&id=dfdc5f98&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfdc5f98",
  null
  
)

export default component.exports