import ja from './ja'
import en from './en'
import fr from './fr'
import he from './he'
import ru from './ru'
import ar from './ar'

export default {
  ja: {
    message: ja,
  },
  en: {
    message: en,
  },
  fr: {
    message: fr,
  },
  he: {
    message: he,
  },
  ru: {
    message: ru,
  },
  ar: {
    message: ar,
  },
}
