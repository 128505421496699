<template>
  <v-layout style="height: calc(90vh - 125px) !important">
    <v-flex xs6>
      <v-card class="invoice-content">
        <v-list>
          <v-toolbar flat class="py-0 my-0" style="max-height: 50px">
            <span class="pt-2 pb-3"> 仕訳パターン一覧 </span>
            <v-spacer></v-spacer>

            <v-autocomplete
              :items="companyNameList"
              v-model="search_vendor"
              label=""
              single-line
              box
              placeholder="取引先"
              clearable
              @input="filterCompanyList"
              class="flat-select-box mb-2"
            ></v-autocomplete>

            <!--v-text-field
              append-icon="search"
              placeholder="取引先"
              single-line
              hide-details
              clearable
              v-model="search_vendor"
              @input="filterCompanyList()"
              class="pt-0 pb-2 ml-5 flat-select-box;"
              style="width:350px;"
              v-tooltip="'入力した取引先とマッチ（部分一致）する仕訳パターンを抽出します。'"
            ></v-text-field-->
          </v-toolbar>

          <vue-perfect-scrollbar
            class="invoice-sidebar-scroll"
            style="height: calc(90vh - 180px) !important"
            :settings="settings"
            id="invoiceCompanyListScrollbar"
          >
            <template v-for="(item, index) in companyListFilterd">
              <v-list-item
                avatar
                :key="index"
                tile
                @click="onClick(item.id)"
                :class="{
                  [$style.invoiceCompanyListSelectedCompany]:
                    item.id === selectedId,
                }"
              >
                <v-list-item-content>
                  <span class="fs-12 fw-bold ellipsis w-100">
                    {{ item.company_name }}
                  </span>

                  <div class="w-100 py-1" style="line-height: 17px">
                    <span class="ml-3 sw-badge-info">借</span>
                    <span class="fs-10 fw-bold ellipsis w-100"
                      >{{ getName(item.debit_account_title) }}
                    </span>
                    <span class="ml-3 sw-badge-info">貸</span>
                    <span class="fs-10 fw-bold ellipsis w-100"
                      >{{ getName(item.credit_account_title) }}
                    </span>
                  </div>
                </v-list-item-content>
              </v-list-item>

              <v-divider
                style="margin: 0"
                :key="index + '-divider'"
              ></v-divider>
            </template>
          </vue-perfect-scrollbar>
        </v-list>
      </v-card>
    </v-flex>

    <v-flex xs6>
      <v-toolbar
        flat
        class="py-0 my-0"
        style="max-height: 50px; border-left: 2px solid rgb(230, 230, 230)"
      >
        <span class="pt-2 pb-3"> 仕訳内容 </span>
      </v-toolbar>

      <div
        v-if="loadingJournal"
        class="app-flex justify-center align-center h-vh-100"
        style="height: calc(90vh - 180px) !important"
      >
        <v-progress-circular indeterminate color="primary" />
      </div>

      <div v-else>
        <vue-perfect-scrollbar
          class="invoice-sidebar-scroll px-5"
          style="height: calc(90vh - 190px) !important"
        >
          <v-layout class="gray-dark white--text fw-bold py-1 mx-3">
            <v-flex xs6 class="text-center fs-12">借方</v-flex>
            <v-flex
              xs6
              class="text-center fs-12"
              style="border-left: 1px solid #ffffff"
              >貸方</v-flex
            >
          </v-layout>

          <div
            v-for="(item, i) in selectedJournal"
            :key="i"
            class="mx-3 journal-box"
          >
            <v-layout>
              <v-flex xs12>
                <v-btn
                  color=""
                  class="mini fs-10"
                  block
                  depressed
                  disabled
                  style="color: #000 !important"
                  >明細 - {{ i + 1 }}</v-btn
                >
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs6 class="pr-2">
                <div class="journal-row">
                  <div class="journal-cell">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.account_title"
                    >
                      {{ definition.account_title.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.debit.account.name_code">
                      {{ item.debit.account.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell" v-if="displaySetting.subaccount">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.sub_account"
                    >
                      {{ definition.sub_account.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.debit.subAccount.name_code">
                      {{ item.debit.subAccount.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell" v-if="displaySetting.department">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.department.charAt(0)"
                    >
                      {{ definition.department.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.debit.department.name_code">
                      {{ item.debit.department.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell" v-if="displaySetting.vendor">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.vendor"
                    >
                      {{ definition.vendor.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.debit.vendor.name_code">
                      {{ item.debit.vendor.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell" v-if="displaySetting.project">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.project"
                    >
                      {{ definition.project.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.debit.project.name">
                      {{ item.debit.project.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell" v-if="displaySetting.segment">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.segment"
                    >
                      {{ definition.segment.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.debit.segment.name">
                      {{ item.debit.segment.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell" v-if="displaySetting.walletable">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.walletable"
                    >
                      {{ definition.walletable.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.debit.walletable.name_code">
                      {{ item.debit.walletable.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell" v-if="displaySetting.item">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.item"
                    >
                      {{ definition.item.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.debit.item.name_code">
                      {{ item.debit.item.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell" v-if="displaySetting.tag">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.tag"
                    >
                      {{ definition.tag.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.debit.tag.name_code">
                      {{ item.debit.tag.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.tax_class"
                    >
                      {{ definition.tax_class.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.debit.tax.name_code">
                      {{ item.debit.tax.name }}
                    </span>
                  </div>
                </div>
              </v-flex>

              <v-flex xs6 class="pl-2" style="border-left: 1px solid #aaa">
                <div class="journal-row">
                  <div class="journal-cell">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.account_title"
                    >
                      {{ definition.account_title.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.credit.account.name_code">
                      {{ item.credit.account.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell" v-if="displaySetting.subaccount">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.sub_account"
                    >
                      {{ definition.sub_account.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.credit.subAccount.name_code">
                      {{ item.credit.subAccount.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell" v-if="displaySetting.department">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.department"
                    >
                      {{ definition.department.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.credit.department.name_code">
                      {{ item.credit.department.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell" v-if="displaySetting.vendor">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.vendor"
                    >
                      {{ definition.vendor.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.credit.vendor.name_code">
                      {{ item.credit.vendor.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell" v-if="displaySettings.project">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.project"
                    >
                      {{ definition.project.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.credit.project.name_code">
                      {{ item.credit.project.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell" v-if="displaySettings.segment">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.segment"
                    >
                      {{ definition.segment.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.credit.segment.name_code">
                      {{ item.credit.segment.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell" v-if="displaySetting.walletable">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.walletable"
                    >
                      {{ definition.walletable.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.credit.walletable.name_code">
                      {{ item.credit.walletable.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell" v-if="displaySetting.item">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.item"
                    >
                      {{ definition.item.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.credit.item.name_code">
                      {{ item.credit.item.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell" v-if="displaySetting.tag">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.tag"
                    >
                      {{ definition.tag.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.credit.tag.name_code">
                      {{ item.credit.tag.name }}
                    </span>
                  </div>
                </div>
                <div class="journal-row">
                  <div class="journal-cell">
                    <span
                      class="sw-badge-primary mr-1"
                      v-tooltip="definition.tax_class"
                    >
                      {{ definition.tax_class.charAt(0) }}
                    </span>
                    <span v-tooltip.left="item.credit.tax.name_code">
                      {{ item.credit.tax.name }}
                    </span>
                  </div>
                </div>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs12 class="border-top-1 pt-2">
                <div class="fw-bold">
                  <span class="sw-badge-primary mr-1" style="width: 50px">
                    {{ definition.description.slice(0, 4) }}
                  </span>
                  {{ item.description }}
                </div>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs12 class="pt-1" v-if="displaySetting.free_text_1">
                <div class="fw-bold">
                  <span class="sw-badge-primary mr-1" style="width: 50px">
                    {{ definition.free_text_1.slice(0, 4) }}
                  </span>
                  {{ item.free_text_1 }}
                </div>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs12 class="pt-1 pb-1" v-if="displaySetting.free_text_2">
                <div class="fw-bold">
                  <span class="sw-badge-primary mr-1" style="width: 50px">
                    {{ definition.free_text_2.slice(0, 4) }}
                  </span>
                  {{ item.free_text_2 }}
                </div>
              </v-flex>
            </v-layout>
          </div>
        </vue-perfect-scrollbar>
      </div>
    </v-flex>
  </v-layout>
</template>

<style module>
.invoiceCompanyListSelectedCompany {
  background: rgb(200, 220, 250);
}
</style>
<script>
import api from 'Api'
import AppConfig from 'Constants/AppConfig'
import axios from 'axios'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { textTruncate, getCurrentAppLayout } from 'Helpers/helpers'
import Snackbar from 'Helpers/snackbar/index'

export default {
  props: {
    definition: Object,
    selectedJournal: Array,
  },
  data() {
    return {
      loading: false,
      loadingJournal: true,
      selectedId: null,
      search_vendor: '',
      companyList: [],
      companyListFilterd: [
        {
          id: '',
          company_name: '',
          debit_account_title: { name: '' },
          credit_account_title: { name: '' },
        },
      ],
      companyNameList: [],
      settings: {
        maxScrollbarLength: 160,
      },
    }
  },
  mounted() {
    this.getCompanyList()
  },
  methods: {
    getName(obj) {
      try {
        return obj.name
      } catch {
        return ''
      }
    },
    onClick(company_id) {
      this.selectedId = company_id
      this.getCompanyJournal(company_id)
    },
    getCompanyList() {
      try {
        let url = this.$store.getters.apiCompanyListFunction
        const auth_token = this.$store.getters.getAuthToken
        url += '?organization_id=' + this.$store.getters.getOrganizationID
        axios
          .get(url, {
            headers: {
              Authorization: auth_token,
            },
          })
          .then((response) => {
            this.loading = false
            var data = response.data.company_list
            this.companyList = data
            this.companyListFilterd = data.slice()

            for (let company of data) {
              if (
                company.company_name &&
                !(company.company_name in this.companyNameList)
              ) {
                this.companyNameList.push(company.company_name)
              }
            }

            if (data.length > 0) {
              this.selectedId = data[0].id
              this.getCompanyJournal(data[0].id)
            }
          })
      } catch (error) {
        console.log(error)
      }
    },
    getCompanyJournal(company_id) {
      try {
        this.loadingJournal = true
        let url = this.$store.getters.apiCompanyInstanceFunction

        let data = {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
          params: {
            id: company_id,
            organization_id: this.$store.getters.getOrganizationID,
          },
        }

        axios.get(url, data).then((response) => {
          if (response.data.error) {
            this.Snackbar.error(response.data.error)
          } else {
            if (this.selectedId === company_id) {
              this.setJournal(response.data.detail)
            }
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    setJournal(detail) {
      try {
        var journals = detail[0].company_journals
        this.selectedJournal.splice(0, this.selectedJournal.length)
        for (var i = 0; i < journals.length; i++) {
          var journal = journals[i]
          this.selectedJournal.push({
            id: journal.id,
            description: journal.description ? journal.description : '',
            free_text_1: journal.free_text_1 ? journal.free_text_1 : '',
            free_text_2: journal.free_text_2 ? journal.free_text_2 : '',

            debit: {
              account: journal.debit_account_title,
              subAccount: journal.debit_subject,
              tax: journal.debit_tax,
              department: journal.debit_department,
              project: journal.debit_project,
              segment: journal.debit_segment,
              vendor: journal.debit_vendor,
              item: journal.debit_item,
              tag: journal.debit_tag,
              walletable: journal.debit_walletable,
            },

            credit: {
              account: journal.credit_account_title,
              subAccount: journal.credit_subject,
              tax: journal.credit_tax,
              department: journal.credit_department,
              project: journal.credit_project,
              segment: journal.credit_segment,
              vendor: journal.credit_vendor,
              item: journal.credit_item,
              tag: journal.credit_tag,
              walletable: journal.credit_walletable,
            },
          })
        }
        this.loadingJournal = false
      } catch (e) {
        console.log(e)
      }
    },
    filterCompanyList() {
      try {
        let search_vendor = this.search_vendor
        if (search_vendor) {
          this.companyListFilterd = this.companyList.filter(
            (item) => item.company_name === search_vendor
          )
        } else {
          this.companyListFilterd = this.companyList.slice()
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
