import { customRequest } from '@/clients/_helpers/custom_request'
import { createResponseError } from '@/clients/_helpers/utils'

class APIClient {
  /*
    get() {}
    post() {}
    put() {}
    delete() {}
    patch() {}
  */
}
APIClient.prototype.sendRequest = customRequest
APIClient.prototype.createResponseError = createResponseError

export default APIClient
