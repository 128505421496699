




























































import {Component, Prop, Emit, Vue} from "vue-property-decorator";

  @Component
export default class STextField extends Vue {

    @Prop({default: false})
    private disabled: boolean

    @Prop({default: false})
    private required: boolean | string

    @Prop({default: "gray"})
    private color: string

    @Prop({default: "indigo"})
    private focusedColor: string

    @Prop({default: ""})
    private label: string

    @Prop({default: ""})
    private hint: string

    @Prop({default: ""})
    public value: string

    @Prop({default: ""})
    private placeholder: string

    @Prop({default: false})
    private singleLine: boolean

    @Prop({default: false})
    private outlined: boolean

    @Prop({default: () => []})
    private rules: ((value: any) => string | boolean)[]

    private inputFocused: boolean = false

    private errorMessages: string[] = []

    @Emit()
    public input(value: string) {
      this.errorMessages = this.rules.map(validation => {
        const validationResult = validation(value)
        return typeof validationResult === 'string' ? validationResult : ''
      }).filter(message => message !== '')
    }

    created() {
      this.localValue = this.value
    }

    private get noteMessage(): string {
      if (this.hasError) {
        return this.errorMessage
      } else {
        return this.hint
      }
    }

    private get labelFloated(): boolean {
      return this.placeholder.length == 0
        && this.localValue.length == 0
        && !this.singleLine
    }

    private get localValue(): string {
      return this.value;
    }

    private set localValue(value: string) {
      this.input(value);
    }

    private get hasError(): boolean {
      return 0 < this.errorMessages.length
    }

    private get errorMessage(): string {
      if (this.errorMessages.length == 0) {
        return ""
      }
      return this.errorMessages[0]
    }

    private onFocus() {
      this.inputFocused = true
    }

    private onBlur() {
      this.inputFocused = false
    }
}
