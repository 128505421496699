import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class EmailAttachmentURLAPI extends APIClient {
  async get(token, params) {
    const payload = {
      url: URLs.EMAIL_ATTACHMENT_URL,
      method: 'GET',
      headers: {
        Authorization: token,
      },
      args: {
        email_id: params.emailId,
        attachment_id: params.attachmentId,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(response.data.error)
      }
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }
}
export default new EmailAttachmentURLAPI()
