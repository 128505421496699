import initialSettingsAPI from '@/clients/settings/initialSettings'

const actions = {
  async postInitialSettings(context, payload) {
    const token = context.getters.getAuthToken
    const data = await initialSettingsAPI.post(token, payload)
    return data
  },
}

export default {
  actions,
}
