import Full from 'Container/Full'

// component
const Master = () => import('@/pages/master/index')
const AccountTitle = () => import('@/pages/master/AccountTitle/index')
const TaxClass = () => import('@/pages/master/TaxClass/index')
const Department = () => import('@/pages/master/Department/index')
const Vendor = () => import('@/pages/master/Vendor/index')
const Project = () => import('@/pages/master/Project/index')
const Segment = () => import('@/pages/master/Segment/index')
const Item = () => import('@/pages/master/Item/index')
const Tag = () => import('@/pages/master/Tag/index')
const Walletable = () => import('@/pages/master/Walletable/index')
const TransferFee = () => import('@/pages/master/TransferFee/index')
const Company = () => import('@/pages/master/Company/index')

const Title = 'マスタ情報'

// helpers
import { checkAuthorization, checkIp } from '../helpers/helpers.js'

export default {
  path: '/master',
  component: Full,
  meta: { requiresAuth: true },
  beforeEnter(to, from, next) {
    checkAuthorization(next)
    checkIp(next)
    next()
  },
  children: [
    {
      path: '',
      component: Master,
      cache: false,
      meta: {
        requiresAuth: true,
        title: Title,
      },
      beforeEnter(to, from, next) {
        checkAuthorization(next)
        checkIp(next)
        next()
      },
      children: [
        {
          path: '/accounttitle',
          component: AccountTitle,
          cache: false,
          meta: { title: Title },
        },
        {
          path: '/taxclass',
          component: TaxClass,
          meta: { title: Title },
        },
        {
          path: '/department',
          component: Department,
          meta: { title: Title },
        },
        {
          path: '/vendor',
          component: Vendor,
          meta: { title: Title },
        },
        {
          path: '/project',
          component: Project,
          meta: { title: Title },
        },
        {
          path: '/segment',
          component: Segment,
          meta: { title: Title },
        },
        {
          path: '/item',
          component: Item,
          meta: { title: Title },
        },
        {
          path: '/tag',
          component: Tag,
          meta: { title: Title },
        },
        {
          path: '/walletable',
          component: Walletable,
          meta: { title: Title },
        },
        {
          path: '/transfer-fee',
          component: TransferFee,
          meta: { title: Title },
        },
        {
          path: '/company',
          component: Company,
          meta: { title: Title },
        },
        {
          path: '/company/:id/',
          component: Company,
          cache: false,
          meta: { title: Title },
        },
      ],
    },
  ],
}
