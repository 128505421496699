import Axios from 'axios'
import { store } from '../../store/store'
import { parseUrl } from './url'

export function customRequest({ method, url, data, params, args }) {
  const authToken = store.getters.getAuthToken

  if (args) {
    url = parseUrl(url, args)
  }

  method = method.toLowerCase()

  switch (method) {
    case 'get':
      return new Promise((resolve, reject) => {
        try {
          Axios.get(url, {
            headers: {
              Authorization: authToken,
            },
            params: params ? params : {},
          }).then((response) => {
            resolve(response.data)
          })
        } catch (error) {
          reject(new Error(error))
        }
      })

    case 'delete':
      return new Promise((resolve, reject) => {
        try {
          Axios.delete(url, {
            headers: {
              Authorization: authToken,
            },
            data: data ? data : {},
          }).then((response) => {
            resolve(response.data)
          })
        } catch (error) {
          reject(new Error(error))
        }
      })

    case 'put':
      return new Promise((resolve, reject) => {
        try {
          Axios.put(url, data ? data : {}, {
            headers: {
              Authorization: authToken,
            },
          }).then((response) => {
            resolve(response.data)
          })
        } catch (error) {
          reject(new Error(error))
        }
      })

    case 'post':
      return new Promise((resolve, reject) => {
        try {
          Axios.post(url, data ? data : {}, {
            headers: {
              Authorization: authToken,
            },
          }).then((response) => {
            resolve(response.data)
          })
        } catch (error) {
          reject(new Error(error))
        }
      })

    case 'patch':
      return new Promise((resolve, reject) => {
        try {
          Axios.patch(url, data ? data : {}, {
            headers: {
              Authorization: authToken,
            },
          }).then((response) => {
            resolve(response.data)
          })
        } catch (error) {
          reject(new Error(error))
        }
      })
  }
}
