import Axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'
import Vue from 'vue'

const state = {
  displaySetting: {},
  journalRules: {
    account_title: { input_debit: 1, input_credit: 1 },
    sub_account: { input_debit: 1, input_credit: 1 },
    department: { input_debit: 1, input_credit: 1 },
    tax: { input_debit: 1, input_credit: 1 },
    project: { input_debit: 1, input_credit: 1 },
    segment: { input_debit: 1, input_credit: 1 },
    vendor: { input_debit: 1, input_credit: 1 },
    item: { input_debit: 1, input_credit: 1 },
    tag: { input_debit: 1, input_credit: 1 },
    walletable: { input_debit: 1, input_credit: 1 },
  },
  definition: {},
  defaultPaySite: {},
  approveLevel: null,
  payConfirmLevel: null,
  sendApprovalRequest: true,
  settingJournal: null,
  settingJournalLockMode: null,
  settingPayJournal: null,
  settingAvoidBankHolidays: null,
  settingForeignCurrency: null,
  settingPaidByCustomer: false,
  settingTimestamp: false,
  freeeTokenExists: false,
  userOrganizations: [],
  contactNumberList: [],
  ownCompanyNames: [],
  freeeSeparateApproval: false,
}

const getters = {
  displaySetting: (state) => {
    return state.displaySetting
  },
  journalRules: (state) => {
    return state.journalRules
  },
  showJournalTextArea: (state) => {
    const displaySetting = state.displaySetting
    return (
      displaySetting.description ||
      displaySetting.free_text_1 ||
      displaySetting.free_text_2
    )
  },
  definition: (state) => {
    return state.definition
  },
  approveLevel: (state) => {
    return state.approveLevel
  },
  payConfirmLevel: (state) => {
    return state.payConfirmLevel
  },
  sendApprovalRequest: (state) => {
    return state.sendApprovalRequest
  },
  defaultPaySite: (state) => {
    return state.defaultPaySite
  },
  settingJournal: (state) => {
    return state.settingJournal
  },
  settingJournalLockMode: (state) => {
    return state.settingJournalLockMode
  },
  settingPayJournal: (state) => {
    return state.settingPayJournal
  },
  settingAvoidBankHolidays: (state) => {
    return state.settingAvoidBankHolidays
  },
  settingForeignCurrency: (state) => {
    return state.settingForeignCurrency
  },
  settingPaidByCustomer: (state) => {
    return state.settingPaidByCustomer
  },
  settingTimestamp: (state) => {
    return state.settingTimestamp
  },
  freeeTokenExists: (state) => {
    return state.freeeTokenExists
  },
  userOrganizations: (state) => state.userOrganizations,
  contactNumberList: (state) => state.contactNumberList,
  ownCompanyNames: (state) => state.ownCompanyNames,
  freeeSeparateApproval: (state) => {
    return state.freeeSeparateApproval
  },
}

const actions = {
  async getUserOrganizations(context) {
    const response = await Axios.get(context.getters.apiGetUserOrganizations, {
      headers: { Authorization: context.getters.getAuthToken },
    })
    if (response.data.status == 'success') {
      const userOrganizations = response.data.data
      context.commit('setOrganizationSetting', { userOrganizations })
    } else {
      throw new Error('Failed to get user organizations')
    }
  },
  getOrganizationSetting(context, payload) {
    Axios.get(context.getters.apiUserSessionFunction, {
      headers: { Authorization: context.getters.getAuthToken },
      params: { user_organization_id: context.getters.getUserOrganizationID },
    }).then((response) => {
      if (response.data.status === 'success') {
        var organization = response.data.organization
        organization.definition.tax = organization.definition.tax_class

        const setting = {
          displaySetting: {
            account_title: organization.settings_account_title,
            sub_account: organization.settings_subaccount,
            department: organization.settings_department,
            tax: organization.settings_tax,
            project: organization.settings_project,
            segment: organization.settings_segment,
            vendor: organization.settings_vendor,
            item: organization.settings_item,
            tag: organization.settings_tag,
            walletable: organization.settings_walletable,
            description: organization.settings_description,
            free_text_1: organization.settings_free_text_1,
            free_text_2: organization.settings_free_text_2,
            maxlen_description: organization.setting_maxlen_description,
            maxlen_free_text_1: organization.setting_maxlen_free_text_1,
            maxlen_free_text_2: organization.setting_maxlen_free_text_2,
            wordOrByte: organization.word_or_byte,
          },
          journalRules: {
            account_title: organization.journal_rules.account_title,
            sub_account: organization.journal_rules.sub_account,
            department: organization.journal_rules.department,
            tax: organization.journal_rules.tax_class,
            project: organization.journal_rules.project,
            segment: organization.journal_rules.segment,
            vendor: organization.journal_rules.vendor,
            item: organization.journal_rules.item,
            tag: organization.journal_rules.tag,
            walletable: organization.journal_rules.walletable,
          },
          approveLevel: organization.approve_level,
          payConfirmLevel: organization.pay_confirm_level,
          sendApprovalRequest: organization.send_approval_request,
          freeeTokenExists: organization.freee_token_exists,
          freeeSeparateApproval: organization.freee_separate_approval,
          defaultPaySite: {
            month: organization.setting_pay_month,
            day: organization.setting_pay_day,
          },
          settingJournal: organization.setting_journal,
          settingJournalLockMode: organization.setting_journal_lock_mode,
          settingPayJournal: organization.setting_pay_journal,
          settingPaidByCustomer: organization.setting_paid_by_customer,
          settingAvoidBankHolidays: organization.setting_avoid_bank_holidays,
          settingForeignCurrency: organization.setting_foreign_currency,
          settingTimestamp: organization.setting_timestamp,
          definition: organization.definition,
        }
        setting['contactNumberList'] = organization.contact_number_list
        setting['ownCompanyNames'] = response.data.exclude_company_names.map(
          (name) => name.exclude_company_name
        )
        context.commit('setOrganizationSetting', setting)
      } else {
        Snackbar.error(response.data.error)
      }
    })
  },
}

const mutations = {
  setOrganizationSetting(state, setting) {
    Object.keys(setting).forEach((key) => {
      Vue.set(state, key, setting[key])
    })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
