import Full from 'Container/Full'

// component
const Role = () => import('Views/settings/components/Role/Role')
const RoleDetail = () => import('Views/settings/components/Role/RoleDetail')
const Settings = () => import('Views/settings/Settings')

// helpers
import { checkAuthorization, checkIp } from '../helpers/helpers.js'

export default {
  meta: { requiresAuth: true },
  beforeEnter(to, from, next) {
    checkAuthorization(next)
    checkIp(next)
    next()
  },
  path: '/role',
  component: Full,
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      component: Settings,
      children: [
        {
          path: '',
          component: Role,
          meta: { title: '管理者設定' },
        },
        {
          path: ':id',
          component: RoleDetail,
          meta: { title: '管理者設定' },
        },
      ],
    },
  ],
}
