import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class UserBankAPI extends APIClient {
  async get(token) {
    const payload = {
      url: URLs.USER_BANK,
      method: 'GET',
      headers: { Authorization: token },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(response.data.error)
      }
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }

  async post(token, data) {
    const payload = {
      data,
      url: URLs.USER_BANK,
      method: 'POST',
      headers: { Authorization: token },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(response.data.error)
      }
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }

  async put(token, data) {
    const payload = {
      data,
      url: URLs.USER_BANK,
      method: 'PUT',
      headers: { Authorization: token },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(response.data.error)
      }
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }

  async delete(token, data) {
    const payload = {
      data,
      url: URLs.USER_BANK,
      method: 'DELETE',
      headers: { Authorization: token },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(response.data.error)
      }
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }
}
export default new UserBankAPI()
