<template>
  <v-alert dense :color="color" text border="left" :class="`fs-${fontsize}`">
    <slot />
  </v-alert>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'blue',
    },
    fontsize: {
      type: Number,
      default: 10,
    },
  },
}
</script>
