// component
const SettingWizard = () => import('Views/session/SettingWizard')

// helpers
import { checkAuthorization, checkIp } from '../helpers/helpers.js'

export default {
  path: '/initial-settings',
  component: SettingWizard,
  beforeEnter(to, from, next) {
    localStorage.removeItem('user')
    checkAuthorization(next)
    checkIp(next)
    next()
  },
  meta: {
    title: '初期設定',
  },
}
