<template functional>
  <div
    v-tooltip="props.tooltip"
    :style="props.editable ? 'margin: 10px 0px 10px;' : 'margin: 0px 0px;'"
  >
    <s-chip class="px-1 tw-bg-gray-300 tw-border-none tw-font-normal">
      {{ props.text }}
    </s-chip>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
      default: '',
    },
    tooltip: {
      type: String,
      required: true,
      default: '',
    },
    editable: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
}
</script>
