import Vue from 'vue'
//import Nprogress from 'nprogress';
//import router from '../../../router';
import axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'

function errorNotify(msg) {
  Snackbar.error(msg)
}

const state = {}

const getters = {}

const actions = {
  deleteTax(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(context.getters.apiTaxclassUrl, {
          data: { id: payload.data.id },
          headers: { Authorization: context.getters.getAuthToken },
        })
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  editTax(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          context.getters.apiTaxclassUrl,
          {
            id: payload.data.id,
            name: payload.data.name,
            rate: payload.data.rate,
            code: payload.data.code,
            status: payload.data.status,
            user_organization_id: context.getters.getUserOrganizationID,
            organization_id: context.getters.getOrganizationID,
          },
          {
            headers: { Authorization: context.getters.getAuthToken },
          }
        )
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  addTax(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          context.getters.apiTaxclassUrl,
          {
            name: payload.data.name,
            rate: payload.data.rate,
            code: payload.data.code,
            status: payload.data.status,
            is_reduced_tax: payload.data.reducedTax,
            export_code: payload.data.exportCode,
            user_organization_id: context.getters.getUserOrganizationID,
            organization_id: context.getters.getOrganizationID,
          },
          {
            headers: { Authorization: context.getters.getAuthToken },
          }
        )
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
}

export default {
  state,
  getters,
  actions,
}
