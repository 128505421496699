import Full from 'Container/Full'

// component
const Report = () => import('@/pages/report')

// helpers
import {
  checkAuthorization,
  checkIp,
  checkCompanySettingsRoute,
} from '../helpers/helpers.js'

export default {
  path: '/report',
  component: Full,
  meta: { requiresAuth: true },
  beforeEnter(to, from, next) {
    checkAuthorization(next)
    checkIp(next)
    next()
  },
  children: [
    {
      path: '',
      component: Report,
      meta: { title: 'レポート' },
    },
  ],
}
