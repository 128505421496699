export const emailList = [
  {
    value: false,
    id: 1,
    starred: true,
    inbox: true,
    draft: false,
    month: '2019年05月',
    amount: '12,000',
    trash: false,
    companyName: '株式会社ABC商事',
    senderEmail: 'Mitchell@example.com',
    time: '2020-02-02 12:35',
    senderName: '斎藤健',
    subject: 'コンサルティング費用',
    comment: 'よろしくお願い致します。',
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg',
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg',
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg',
      },
    ],
  },
  {
    value: false,
    id: 2,
    starred: false,
    inbox: true,
    draft: false,
    month: '2019年05月',
    amount: '12,000',
    trash: false,
    companyName: '株式会社ABC商事',
    senderEmail: 'Jhon@example.com',
    time: '2020-02-02 12:35',
    senderName: '斎藤健',
    subject: 'コンサルティング費用',
    comment: 'よろしくお願い致します。',
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg',
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg',
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg',
      },
    ],
  },
  {
    value: false,
    id: 3,
    starred: true,
    inbox: true,
    draft: false,
    month: '2019年05月',
    amount: '12,000',
    trash: false,
    companyName: '株式会社ABC商事',
    senderEmail: 'Merry@example.com',
    time: '2020-02-02 12:35',
    senderName: '斎藤健',
    subject: 'コンサルティング費用',
    comment: 'よろしくお願い致します。',
    attachments: null,
  },
  {
    value: false,
    id: 4,
    starred: false,
    inbox: true,
    draft: false,
    month: '2019年05月',
    amount: '12,000',
    trash: false,
    companyName: '株式会社ABC商事',
    senderEmail: 'Cristel@example.com',
    time: '2020-02-02 12:35',
    senderName: '斎藤健',
    subject: 'コンサルティング費用',
    comment: 'よろしくお願い致します。',
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg',
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg',
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg',
      },
    ],
  },
  {
    value: false,
    id: 5,
    starred: true,
    inbox: true,
    draft: false,
    month: '2019年05月',
    amount: '12,000',
    trash: false,
    companyName: '株式会社ABC商事',
    senderEmail: 'Jone@example.com',
    time: '2020-02-02 12:35',
    senderName: '斎藤健',
    subject: 'コンサルティング費用',
    comment: 'よろしくお願い致します。',
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg',
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg',
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg',
      },
    ],
  },
  {
    value: false,
    id: 6,
    starred: false,
    inbox: true,
    draft: false,
    month: '2019年05月',
    amount: '12,000',
    trash: false,
    companyName: '株式会社ABC商事',
    senderEmail: 'Michall@example.com',
    time: '2020-02-02 12:35',
    senderName: '斎藤健',
    subject: 'コンサルティング費用',
    comment: 'よろしくお願い致します。',
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg',
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg',
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg',
      },
    ],
  },
  {
    value: false,
    id: 7,
    starred: true,
    inbox: true,
    draft: false,
    month: '2019年05月',
    amount: '12,000',
    trash: false,
    companyName: '株式会社ABC商事',
    senderEmail: 'Rose@example.com',
    time: '2020-02-02 12:35',
    senderName: '斎藤健',
    subject: 'コンサルティング費用',
    comment: 'よろしくお願い致します。',
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg',
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg',
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg',
      },
    ],
  },
  {
    value: false,
    id: 8,
    starred: false,
    inbox: true,
    draft: false,
    month: '2019年05月',
    amount: '12,000',
    trash: false,
    companyName: '株式会社ABC商事',
    senderEmail: 'Krishna@example.com',
    time: '2020-02-02 12:35',
    senderName: '斎藤健',
    subject: 'コンサルティング費用',
    comment: 'よろしくお願い致します。',
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg',
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg',
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg',
      },
    ],
  },
  {
    value: false,
    id: 9,
    starred: false,
    inbox: true,
    draft: false,
    month: '2019年05月',
    amount: '12,000',
    trash: false,
    companyName: '株式会社ABC商事',
    senderEmail: 'Mitchell@example.com',
    time: '2020-02-02 12:35',
    senderName: '斎藤健',
    subject: 'コンサルティング費用',
    comment: 'よろしくお願い致します。',
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg',
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg',
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg',
      },
    ],
  },
  {
    value: false,
    id: 10,
    starred: false,
    inbox: true,
    draft: false,
    month: '2019年05月',
    amount: '12,000',
    trash: false,
    companyName: '株式会社ABC商事',
    senderEmail: 'Jennifer@example.com',
    time: '2020-02-02 12:35',
    senderName: '斎藤健',
    subject: 'コンサルティング費用',
    comment: 'よろしくお願い致します。',
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg',
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg',
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg',
      },
    ],
  },
  {
    value: false,
    id: 11,
    starred: false,
    inbox: false,
    draft: false,
    month: '2019年05月',
    amount: '12,000',
    trash: true,
    companyName: '株式会社ABC商事',
    senderEmail: 'Jennifer@example.com',
    time: '2020-02-02 12:35',
    senderName: '斎藤健',
    subject: 'コンサルティング費用',
    comment: 'よろしくお願い致します。',
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg',
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg',
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg',
      },
    ],
  },
]
