<template>
  <div>
    <template v-for="(field, i) in journalFields">
      <v-row
        v-if="displaySetting[field]"
        :key="`${field}_${i}`"
        :ref="`${entrySide}_${field}`"
        no-gutters
      >
        <journal-select
          :ref="`${field}`"
          :value="journal[entrySide + '_' + field]"
          :items="properties[i]['masterList']"
          :placeholder="properties[i]['placeHolder']"
          :chip="properties[i]['chip']"
          :tooltip="definition[field]"
          :editable="editable"
          :disabled="properties[i]['disabled']"
          :rules="properties[i]['rules']"
          :error="properties[i]['error']"
          :display-name-key="properties[i]['displayNameKey']"
          :field-name="properties[i]['fieldName']"
          :show-auto-complete-tooltip="showAutoCompleteTooltip"
          :disables-auto-complete="disablesAutoComplete"
          :tabindex="tabindex"
          @change="$emit('change', { obj: $event, journal, entrySide, field })"
        />
      </v-row>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { sliceText, journalFields } from 'Helpers/journal'
import JournalSelect from 'Components/Journal/JournalSelect.vue'

export default {
  components: {
    JournalSelect,
  },
  props: {
    journal: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    entrySide: {
      type: String,
      required: true,
    },
    getValidationRule: {
      type: Function,
      default() {
        return []
      },
    },
    getFormError: {
      type: Function,
      default() {
        return false
      },
    },
    getFormDisable: {
      type: Function,
      default() {
        return false
      },
    },
    displayNameKey: {
      type: String,
      default: 'name_code',
    },
    showAutoCompleteTooltip: {
      type: Boolean,
      default: false,
    },
    disablesAutoComplete: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      journalFields,
      formFieldNames: {
        debit: {
          account_title: 'debit_account_title',
          sub_account: 'debit_sub_account',
          department: 'debit_department',
          vendor: 'debit_vendor',
          project: 'debit_project',
          segment: 'debit_segment',
          item: 'debit_item',
          tag: 'debit_tag',
          walletable: 'debit_walletable',
          tax: 'debit_tax',
          amount: 'debit_amount',
          amount_without_tax: 'debit_amount_without_tax',
          tax_amount: 'debit_tax_amount',
          amount_input_mode: 'debit_amount_input_mode',
        },
        credit: {
          account_title: 'credit_account_title',
          sub_account: 'credit_sub_account',
          department: 'credit_department',
          vendor: 'credit_vendor',
          project: 'credit_project',
          segment: 'credit_segment',
          item: 'credit_item',
          tag: 'credit_tag',
          walletable: 'credit_walletable',
          tax: 'credit_tax',
          amount: 'credit_amount',
          amount_without_tax: 'credit_amount_without_tax',
          tax_amount: 'credit_tax_amount',
          amount_input_mode: 'credit_amount_input_mode',
        },
      },
    }
  },
  computed: {
    ...mapGetters(['displaySetting', 'definition', 'masterList']),
    properties: function () {
      const journal = this.journal
      const entrySide = this.entrySide
      const definition = this.definition
      const _displayNameKey = this.displayNameKey
      const properties = this.journalFields.map((field) => {
        let disabled = this.getFormDisable(journal, entrySide, field)
        let rules = this.getValidationRule(journal, entrySide, field)
        let error = this.getFormError(journal, entrySide, field)
        let chip = this.sliceText(definition[field], 0, 1)
        let placeHolder = this.getPlaceholder(entrySide, definition[field])
        let masterList = this.getMasterList(entrySide, field, journal)
        let displayNameKey = this.getNamekey(field, _displayNameKey)
        let fieldName = this.getJournalField(entrySide, field)
        let property = {
          disabled,
          rules,
          error,
          masterList,
          chip,
          placeHolder,
          displayNameKey,
          fieldName,
        }
        return property
      })
      return properties
    },
  },
  methods: {
    sliceText(text, i1, i2) {
      return sliceText(text, i1, i2)
    },
    getMasterList(dc, field, value) {
      if (field === 'sub_account') {
        let account = value[dc + '_account_title']
        return this.getSubjectList(account, this.masterList)
      } else {
        return this.masterList[field].filter((item) => item.status == 1)
      }
    },
    getPlaceholder(dc, text) {
      try {
        if (dc === 'debit') {
          return '借方' + text
        } else {
          return '貸方' + text
        }
      } catch (e) {
        return ''
      }
    },
    getSubjectList(obj, masterList) {
      try {
        let subjects = masterList.account_title.find(
          (item) => item.id == obj.id
        ).subjects
        return subjects.filter((item) => item.status == 1)
      } catch (e) {
        return []
      }
    },
    getJournalField(dc, name) {
      try {
        return this.formFieldNames[dc][name]
      } catch (e) {
        console.log(e)
      }
    },
    getNamekey(field, displayNameKey) {
      const fieldsNoFreeeId = ['sub_account', 'project']
      if (fieldsNoFreeeId.includes(field)) {
        return 'name_code'
      } else if (field === 'tax') {
        return 'rate_name_code'
      } else {
        // name code or display
        return displayNameKey
      }
    },
    focusAutoComplete(refName = 'account_title') {
      const refs = this.$refs[refName]
      if (refs) {
        refs[0].makeElementFocused()
      }
    },
  },
}
</script>
