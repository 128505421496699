import PaymentDetailAPI from '@/clients/payment/paymentDetail'
import PaymentBulkChangeUserBankAPI from '@/clients/payment/paymentBulkChangeUserBank'

const state = {}

const getters = {}

const actions = {
  async updatePaymentDetail(context, payload) {
    const token = context.getters.getAuthToken
    const data = await PaymentDetailAPI.put(token, {
      pay_key: payload.pay_key,
      transfer_fee: payload.transfer_fee,
    })
    return data.transfer_fee
  },
  async bulkChangePaymentUserBank(context, payload) {
    const token = context.getters.getAuthToken
    const data = await PaymentBulkChangeUserBankAPI.put(token, {
      user_bank_id: payload.user_bank_id,
      pay_key_list: payload.pay_key_list,
    })
    return data.pay_keys
  },
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
