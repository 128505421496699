import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class InvoiceReportAPI extends APIClient {
  async get(token) {
    const payload = {
      url: URLs.INVOICE_REPORT,
      method: 'GET',
      headers: {
        Authorization: token,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(response.data.error)
      }
      return response.data
    } catch (err) {
      throw this.createResponseError(err)
    }
  }
}
export default new InvoiceReportAPI()
