/**
 * Auth Module
 */
import Axios from 'axios'
import FreeeSeparateApprovalAPI from '@/clients/freee/separateApproval'

const state = {}

// getters
const getters = {}

// actions
const actions = {
  getFreeeAccessToken(context) {
    return new Promise((resolve) => {
      Axios.get(context.getters.apiFreeeCompanyUrl, {
        headers: { Authorization: context.getters.getAuthToken },
        params: { organization_id: context.getters.getOrganizationID },
      })
        .then((response) => {
          if (response.data.status === 'no_token') {
            resolve(false)
          } else if (['ok', 'not_set'].includes(response.data.status)) {
            resolve({
              companyId: response.data.company_id,
              tokenExists: response.data.token_exists,
              separateApproval: response.data.separate_approval,
            })
          }
        })
        .catch((error) => error.response)
    })
  },
  checkFreeeAccessToken(context) {
    return new Promise((resolve) => {
      Axios.get(context.getters.apiFreeeCompaniesUrl, {
        headers: { Authorization: context.getters.getAuthToken },
      })
        .then((response) => {
          resolve({
            authorized: true,
            companyList: response.data.companies,
          })
        })
        .catch(() => {
          resolve({
            authorized: false,
            companyList: [],
          })
        })
    })
  },
  postFreeeCompany(context, freeeCompanyId) {
    return new Promise((resolve) => {
      const data = {
        organization_id: context.getters.getOrganizationID,
        company_id: freeeCompanyId,
      }
      Axios.post(context.getters.apiFreeeCompanyUrl, data, {
        headers: { Authorization: context.getters.getAuthToken },
      })
        .then(() => {
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    })
  },
  async postFreeeApprovalType(context, separateApproval) {
    const token = context.getters.getAuthToken
    const updateApprovalType = await FreeeSeparateApprovalAPI.post(token, {
      separate_approval: separateApproval,
    })
    return updateApprovalType
  },
  postSingleFreeeDeal(
    context,
    { accessToken, freeeCompanyId, localId, invoiceId, separateApproval }
  ) {
    return new Promise((resolve) => {
      let errorList = []
      let foundError = false
      let hasWarning = false

      Axios.post(
        context.getters.apiFreeeDealsUrl,
        {
          post_type: 'single',
          organization_id: context.getters.getOrganizationID,
          access_token: accessToken,
          company_id: freeeCompanyId,
          image_summary_id: invoiceId,
          image_local_id: localId,
          separate_approval: separateApproval,
        },
        {
          headers: {
            Authorization: context.getters.getAuthToken,
          },
        }
      ).then((response) => {
        try {
          if ('error' in response.data) {
            const resError = response.data.error
            foundError = true

            if (Array.isArray(resError)) {
              for (let x = 0; x < resError.length; x++) {
                errorList.push(resError[x])
              }
            } else {
              errorList.push(resError)
            }
          } else {
            const freeeResponses = response.data.data
            if (freeeResponses.length === 0) {
              return
            }
            if ('errors' in freeeResponses[0]) {
              for (let b = 0; b < freeeResponses.length; b++) {
                if ('errors' in freeeResponses[b]) {
                  foundError = true
                  for (let x = 0; x < freeeResponses[b].errors.length; x++) {
                    for (
                      let y = 0;
                      y < freeeResponses[b].errors[x].messages.length;
                      y++
                    ) {
                      errorList.push(freeeResponses[b].errors[x].messages[y])
                    }
                  }
                }
              }
            }
          }
          const warnings = response.data.warnings
          if (warnings && warnings.length > 0) {
            hasWarning = true
          }
          if (foundError) {
            resolve({
              status: 'error',
              errors: errorList,
            })
          } else if (hasWarning) {
            resolve({
              status: 'warning',
              warnings: response.data.warnings,
            })
          } else {
            resolve({
              status: 'success',
              data: response.data.data,
            })
          }
        } catch (err) {
          console.log(err)
        }
      })
    })
  },
  async postMultiFreeeDeals(
    context,
    { accessToken, freeeCompanyId, items, separateApproval }
  ) {
    let errorList = []
    let foundError = false
    let foundWarnings = false

    const payload = {
      post_type: 'multiple',
      organization_id: context.getters.getOrganizationID,
      access_token: accessToken,
      company_id: freeeCompanyId,
      items,
      separate_approval: separateApproval,
    }
    const response = await Axios.post(
      context.getters.apiFreeeDealsUrl,
      payload,
      {
        headers: { Authorization: context.getters.getAuthToken },
      }
    )
    if (response.data.status === 'success') {
      for (let x = 0; x < response.data.list.length; x++) {
        if ('error' in response.data.list[x]) {
          foundError = true
          let resError = response.data.list[x].error
          errorList.push(resError)
        }
      }
      const warnings = response.data.warnings
      if (warnings && warnings.length > 0) {
        foundWarnings = true
      }

      if (foundError) {
        return {
          status: 'error',
          errors: errorList.flat(),
        }
      } else if (foundWarnings) {
        return {
          status: 'warning',
          warnings,
        }
      } else {
        return {
          status: 'success',
          data: response.data.list,
        }
      }
    } else {
      errorList.push(response.data.error)
      return {
        status: 'error',
        errors: errorList,
      }
    }
  },
  openFreeeDeal(context, dealId) {
    if (!dealId) {
      return
    }
    const url = 'https://secure.freee.co.jp/reports/journals/?deal_id=' + dealId
    window.open(url, '_blank')
  },
}

// mutations
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
