import Vue from 'vue'
import * as Sentry from '@sentry/browser'

const USE_SENTRY_LOCAL = false
export const inializeSentry = () => {
  /**
   * Staging と Production のみ Sentry にエラーレポートを送る。
   * ローカルのデバッグを行う際は USE_SENTRY_LOCAL = true にする。
   */
  if (
    USE_SENTRY_LOCAL ||
    ['production', 'staging'].includes(process.env.VUE_APP_ENV_TYPE)
  ) {
    Sentry.init({
      dsn: 'https://01d10a35433a45578b1ad353e490556d@sentry.io/1365613',
      integrations: [new Sentry.Integrations.Vue({ Vue })],
      environment: window.location.host.slice(
        0,
        window.location.host.indexOf('.')
      ),
    })
    return true
  }
  return false
}
