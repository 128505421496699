import Full from 'Container/Full'

// invoice component
const Invoice = () => import('Views/invoice/Invoice')
const InvoiceDetail = () => import('Views/invoice/InvoiceDetail')

// helpers
import { checkAuthorization, checkIp } from '../helpers/helpers.js'

export default {
  path: '/invoice',
  component: Full,
  meta: { requiresAuth: true },
  beforeEnter(to, from, next) {
    if (
      from.path == '/session/login' ||
      from.path.includes('/session/invite/confirm')
    ) {
      var nextUrl = decodeURIComponent(window.location.search.slice(6))
      if (nextUrl.length > 0) {
        window.location.href = process.env.VUE_APP_SUBDOMAIN_APP_FULL + nextUrl
      } else {
        window.location.href =
          process.env.VUE_APP_SUBDOMAIN_APP_FULL + '/invoice'
      }
    } else {
      checkAuthorization(next)
      checkIp(next)
    }
    next()
  },
  children: [
    {
      path: '',
      component: Invoice,
      name: 'invoice',
      meta: { title: '請求書' },
    },
    {
      path: ':id',
      component: Invoice,
      meta: { title: '請求書' },
    },
  ],
}
