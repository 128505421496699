import axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'

const state = {}

const getters = {}

const actions = {
  deleteCompany(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl
    const auth_token = data.auth_token
    return new Promise((resolve, reject) => {
      axios
        .delete(apiUrl, {
          data: { id: data.id },
          headers: { Authorization: auth_token },
        })
        .then(function (response) {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          }
          if (response.data.status == 'success') {
            resolve(response)
          }
        })
    })
  },
}
export default {
  state,
  getters,
  actions,
}
