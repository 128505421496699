import { checkPrivilege } from './index'

export default {
  methods: {
    checkPrivilege: function (ability) {
      try {
        const hasPrivilege = checkPrivilege(ability)
        return hasPrivilege
      } catch (err) {
        console.log(err)
        return false
      }
    },
  },
}
