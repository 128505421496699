export function parseUrl(url, args) {
  const fields = url.match(/<[^/]+>/g)
  if (fields) {
    for (let field of fields) {
      const field_ex = field.replace('<', '').replace('>', '')
      url = url.replace(field, args[field_ex])
    }
  }
  return url
}
