import Axios from 'axios'

function parseUrl(url, args) {
  // example: parseUrl(someurl/<arg>,{arg: 'value'}
  //          => someurl/value
  const fields = url.match(/<[^/?]+>/g)
  if (fields) {
    for (let field of fields) {
      const fieldEx = field.replace('<', '').replace('>', '')
      url = url.replace(field, args[fieldEx])
    }
  }
  return encodeURI(url)
}

export function customRequest({ method, url, headers, params, data, args }) {
  url = args ? parseUrl(url, args) : url
  method = method.toLowerCase()
  data = data ? data : {}
  headers = headers ? headers : {}
  params = params ? params : {}

  let request

  if (method === 'get') {
    request = Axios.get(url, { headers, params })
  } else if (method === 'post') {
    request = Axios.post(url, data, { headers })
  } else if (method === 'put') {
    request = Axios.put(url, data, { headers })
  } else if (method === 'patch') {
    request = Axios.patch(url, data, { headers })
  } else if (method === 'delete') {
    request = Axios.delete(url, { headers, data })
  } else {
    throw new Error('invalid method')
  }

  return new Promise((resolve, reject) => {
    request
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
