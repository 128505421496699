import Vue from 'vue'
import Nprogress from 'nprogress'
import router from '../../../router'
import axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'

function errorNotify(msg) {
  Snackbar.error(msg)
}

const state = {}

const getters = {}

const actions = {
  multipleChangeDate(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl
    const issue_date = payload.issue_date
    var array_items = []
    for (var x = 0; x < data.length; x++) {
      array_items.push(data[x].id)
    }
    return new Promise((resolve) => {
      axios
        .post(
          apiUrl,
          {
            item: array_items,
            issue_date: issue_date,
            user_organization_id: context.getters.getUserOrganizationID,
          },
          {
            headers: {
              Authorization: context.getters.getAuthToken,
            },
          }
        )
        .then(function (response) {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          }
          resolve(response)
        })
        .catch(function (error) {})
    })
  },
  multipleEditInvoice(context, payload) {
    return new Promise((resolve) => {
      axios
        .post(
          context.getters.apiMuitipleUpdateInvoiceUrl,
          {
            item: payload.data,
            journal_form: payload.journal_form,
            user_organization_id: context.getters.getUserOrganizationID,
            user_id: context.getters.getUserID,
            org_id: context.getters.getOrganizationID,
          },
          {
            headers: {
              Authorization: context.getters.getAuthToken,
            },
          }
        )
        .then(function (response) {
          if (response.data.error) {
            const error = response.data.error
            const errorMessages = {
              no_privilege: '権限がないデータは更新できません。',
              no_record: '変更可能なデータが存在しません。',
              unable_to_change_locked_pay_record:
                '支払連携済データの支払情報は変更できません。',
              unable_to_change_locked_gensen_record:
                '支払連携済データの源泉税は変更できません。',
              unable_to_change_locked_invoice_journal:
                '仕訳承認済の請求書仕訳は更新できません。',
              unable_to_change_locked_invoice_data:
                '仕訳承認済の請求書データは更新できません。',
            }
            if (errorMessages[error]) {
              errorNotify(errorMessages[error])
            } else {
              errorNotify(error)
            }
          }
          resolve(response)
        })
        .catch(function (error) {})
    })
  },
  multipleApproveInvoice(context, payload) {
    const data = payload.data
    const apiUrl = payload.apiUrl
    var array_items = []
    for (var x = 0; x < data.length; x++) {
      array_items.push(data[x].id)
    }
    return new Promise((resolve, reject) => {
      if (data.length === 0) {
        reject('データを選択して下さい')
      }
      axios
        .post(
          apiUrl,
          {
            item: array_items,
            user_organization_id: context.getters.getUserOrganizationID,
            user_id: context.getters.getUserID,
          },
          {
            headers: {
              Authorization: context.getters.getAuthToken,
            },
          }
        )
        .then(function (response) {
          if (response.data.error) {
            reject(response.data.error)
          }
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  multipleDeleteInvoice(context, payload) {
    const data = payload.data
    var array_items = []
    var array_image_id = []
    for (var x = 0; x < data.length; x++) {
      array_items.push(data[x].id)
      array_image_id.push(data[x].image_id)
    }
    return new Promise((resolve) => {
      axios
        .delete(context.getters.apiInvoiceMultiDeleteFunction, {
          data: {
            item: array_items,
            item_image_id: array_image_id,
            user_organization_id: context.getters.getUserOrganizationID,
          },
          headers: {
            Authorization: context.getters.getAuthToken,
          },
        })
        .then(function (response) {
          if ('error' in response.data) {
            const error = response.data.error
            const errorMessages = {
              no_privilege: '権限がないデータは更新できません。',
            }
            if (errorMessages[error]) {
              errorNotify(errorMessages[error])
            } else {
              errorNotify(error)
            }
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  multipleDeletePayment(context, payload) {
    const data = payload.data
    var payment_summary_id_list = []
    for (var x = 0; x < data.length; x++) {
      payment_summary_id_list.push(data[x].id)
    }
    return new Promise((resolve) => {
      axios
        .delete(context.getters.apiPaymentMultiDeleteFunction, {
          data: { payment_summary_id_list: payment_summary_id_list },
          headers: {
            Authorization: context.getters.getAuthToken,
          },
        })
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  updateInvoiceNote(context, payload) {
    return new Promise((resolve) => {
      axios
        .patch(
          context.getters.apiInvoiceNoteUrl,
          {
            image_summary_id: payload.image_summary_id,
            note_1: payload.note_1,
            note_2: payload.note_2,
            note_3: payload.note_3,
          },
          {
            headers: {
              Authorization: context.getters.getAuthToken,
            },
          }
        )
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
        })
    })
  },
  requestNextApproval(context, payload) {
    const params = {
      user_org_ids: payload.userOrgIds,
      local_id: payload.localId,
      is_approval: payload.isApproval,
      next_approval_level: payload.nextLevel,
    }
    return new Promise((resolve) => {
      axios
        .post(context.getters.apiNextApprovalUrl, params, {
          headers: {
            Authorization: context.getters.getAuthToken,
          },
        })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
        })
    })
  },
  approveInvoice(context, payload) {
    return new Promise((resolve) => {
      axios
        .post(
          context.getters.apiInvoiceConfirmFunction,
          {
            id: payload.id,
            approve_status: true,
            approve_level: payload.level,
          },
          {
            headers: {
              Authorization: context.getters.getAuthToken,
            },
          }
        )
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
        })
    })
  },
  cancelApproveInvoice(context, payload) {
    return new Promise((resolve) => {
      axios
        .post(
          context.getters.apiInvoiceConfirmFunction,
          {
            id: payload.id,
            approve_status: false,
            approve_level: payload.level,
          },
          {
            headers: {
              Authorization: context.getters.getAuthToken,
            },
          }
        )
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
        })
    })
  },
  finalApproveInvoice(context, payload) {
    return new Promise((resolve) => {
      axios
        .post(
          context.getters.apiInvoiceApproveFunction,
          {
            id: payload.id,
          },
          {
            headers: {
              Authorization: context.getters.getAuthToken,
            },
          }
        )
        .then(function (response) {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          }
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
        })
    })
  },
  cancelFinalApproveInvoice(context, payload) {
    return new Promise((resolve) => {
      axios
        .post(
          context.getters.apiInvoiceCancelApproveUrl,
          {
            id: payload.id,
          },
          {
            headers: {
              Authorization: context.getters.getAuthToken,
            },
          }
        )
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  confirmPayment(context, payload) {
    return new Promise((resolve) => {
      axios
        .post(
          context.getters.apiInvoicePayConfirmFunction,
          {
            id: payload.id,
            confirm_level: payload.level,
            confirm_status: true,
          },
          {
            headers: {
              Authorization: context.getters.getAuthToken,
            },
          }
        )
        .then(function (response) {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          }
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
        })
    })
  },
  cancelConfirmPayment(context, payload) {
    return new Promise((resolve) => {
      axios
        .post(
          context.getters.apiInvoicePayConfirmFunction,
          {
            id: payload.id,
            confirm_level: payload.level,
            confirm_status: false,
          },
          {
            headers: {
              Authorization: context.getters.getAuthToken,
            },
          }
        )
        .then(function (response) {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          }
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
        })
    })
  },
  approvePayment(context, payload) {
    return new Promise((resolve) => {
      axios
        .post(
          context.getters.apiInvoicePayApproveFunction,
          {
            id: payload.id,
          },
          {
            headers: {
              Authorization: context.getters.getAuthToken,
            },
          }
        )
        .then(function (response) {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          }
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
        })
    })
  },
  cancelApprovePayment(context, payload) {
    return new Promise((resolve) => {
      axios
        .post(
          context.getters.apiInvoicePayCancelApproveUrl,
          {
            id: payload.id,
          },
          {
            headers: {
              Authorization: context.getters.getAuthToken,
            },
          }
        )
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  approvePaid(context, payload) {
    return new Promise((resolve) => {
      axios
        .post(
          context.getters.apiInvoicePaid,
          {
            id: payload.id,
            status: true,
          },
          {
            headers: {
              Authorization: context.getters.getAuthToken,
            },
          }
        )
        .then(function (response) {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          }
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
        })
    })
  },
  cancelApprovePaid(context, payload) {
    return new Promise((resolve) => {
      axios
        .post(
          context.getters.apiInvoicePaid,
          {
            id: payload.id,
            status: false,
          },
          {
            headers: {
              Authorization: context.getters.getAuthToken,
            },
          }
        )
        .then(function (response) {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          }
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
        })
    })
  },
  editInvoice(context, payload) {
    return new Promise((resolve) => {
      axios
        .post(context.getters.apiInvoiceInstanceFunction, payload.data, {
          headers: { Authorization: context.getters.getAuthToken },
        })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  deleteInvoice(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(context.getters.apiInvoiceInstanceFunction, {
          data: {
            image_summary_id: payload.data.image_summary_id,
            organization_id: context.getters.getOrganizationID,
          },
          headers: {
            Authorization: context.getters.getAuthToken,
          },
        })
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  restoreInvoice(context, payload) {
    return new Promise((resolve) => {
      axios
        .post(
          context.getters.apiInvoiceRestoreFunction,
          {
            image_summary_id: payload.data.image_summary_id,
            organization_id: context.getters.getOrganizationID,
          },
          {
            headers: {
              Authorization: context.getters.getAuthToken,
            },
          }
        )
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
}

export default {
  state,
  getters,
  actions,
}
