/**
 * Inbox Module
 */
import Vue from 'vue'
import Axios from 'axios'

import { customRequest } from 'Helpers/api/request'
import Snackbar from 'Helpers/snackbar/index'

import { deepCopy } from 'Helpers/helpers'

import CustomerApprovalStageAPI from '@/clients/customer/customerApprovalStage'
import inboxItemsAPI from '@/clients/inbox/inboxItems'
import InboxNextApprovalAPI from '@/clients/inbox/inboxNextApproval'

function errorNotify(msg) {
  Snackbar.error(msg)
}

function formatDate(date) {
  var Y = date.getFullYear()
  var M = date.getMonth() + 1
  var D = date.getDate()
  var yyyy = ('000' + Y).slice(-4)
  var mm = ('0' + M).slice(-2)
  var dd = ('0' + D).slice(-2)
  return yyyy + '-' + mm + '-' + dd
}

function checkTableParams(params) {
  if (!params) {
    params = {}
  }
  if (!params.text) {
    params.text = ''
  }
  if (!params.ownDepartmentId) {
    params.ownDepartmentId = ''
  }
  if (![1, 2, 3].includes(params.status)) {
    params.status = 1
  }
  return checkTableDateParams(params)
}

function checkTableDateParams(params) {
  if (!params.year) {
    params.year = 0
  }
  if (!params.month) {
    params.month = 0
  }

  if (params.year === 0) {
    // デフォルトで現在の3ヶ月前を採用
    let today = new Date()
    params.year = today.getFullYear()
    params.month = today.getMonth() - 2
  }
  if (params.month > 12) {
    params.year += 1
    params.month += -12
  } else if (params.month <= 0) {
    params.year += -1
    params.month += 12
  }
  return params
}

function sortTableData(tableData) {
  let uncategorizedData = tableData.filter(
    (item) => item.customer.is_uncategorized
  )
  let categorizedData = tableData.filter(
    (item) => !item.customer.is_uncategorized
  )

  uncategorizedData.sort(function (a, b) {
    if (a.customer.own_department && b.customer.own_department) {
      if (a.customer.own_department == b.customer.own_department) {
        return a.customer.id - b.customer.id
      }
      return (
        a.customer.own_department_sort_num - b.customer.own_department_sort_num
      )
    } else if (a.customer.own_department && !b.customer.own_department) {
      return 1
    } else if (!a.customer.own_department && b.customer.own_department) {
      return -1
    }
    return a.customer.sort_num - b.customer.sort_num
  })

  categorizedData.sort(function (a, b) {
    if (a.customer.own_department && b.customer.own_department) {
      if (a.customer.own_department == b.customer.own_department) {
        return a.customer.sort_num - b.customer.sort_num
      }
      return (
        a.customer.own_department_sort_num - b.customer.own_department_sort_num
      )
    } else if (a.customer.own_department && !b.customer.own_department) {
      return 1
    } else if (!a.customer.own_department && b.customer.own_department) {
      return -1
    }
    return a.customer.sort_num - b.customer.sort_num
  })

  return uncategorizedData.concat(categorizedData)
}

const state = {
  imageData: {
    invoiceImage: null,
    hiddenImage: null,
    upload_source: '',
    pdfUrl: '',
  },
  inbox: {
    menu: 'collect',
    departments: [],
    customers: [],
    userOrganizations: [],
    userOrgEmail: '',
    emailTemplates: [],
    selectedItemId: null,
  },
  selectedInboxItem: null,
  inboxCustomers: {
    isUnnecessaryImpress: false,
    tableData: [],
    tableParams: {
      year: 2020,
      month: 1,
      status: 1,
      text: '',
      ownDepartmentId: '',
      isAll: false,
      showUncategorized: false,
    },
  },
  inboxDetail: {
    loading: false,
    listItems: [],
    listParams: {
      status: 1,
      isSelectedReceiptDate: true,
      dateFrom: null,
      dateTo: null,
      ownDepartmentId: '',
    },
  },
}

const getters = {
  inboxMenu: (state) => {
    return state.inbox.menu
  },
  inboxDepartments: (state) => {
    return state.inbox.departments
  },
  inboxUserOrganizations: (state) => {
    return state.inbox.userOrganizations
  },
  inboxEmailTemplates: (state) => {
    return state.inbox.emailTemplates
  },
  getInboxEmailTemplate: (state) => (templateId) => {
    let obj = state.inbox.emailTemplates.find((obj) => obj.id === templateId)
    if (obj) {
      return obj
    }
    return {}
  },
  inboxCustomers: (state) => {
    return state.inbox.customers
  },
  inboxSelectedItemId: (state) => {
    return state.inbox.selectedItemId
  },
  inboxCustomersTableData: (state) => {
    return state.inboxCustomers.tableData
  },
  inboxCustomersTableParams: (state) => {
    return state.inboxCustomers.tableParams
  },
  inboxCustomersIsUnnecessaryImpress: (state) => {
    return state.inboxCustomers.isUnnecessaryImpress
  },
  inboxItems: (state) => {
    return state.inboxDetail.listItems
  },
  inboxItemsListParams: (state) => {
    return state.inboxDetail.listParams
  },
  inboxItemsLoading: (state) => {
    return state.inboxDetail.loading
  },
  imageData: (state) => {
    return state.imageData
  },
}

const actions = {
  async getInboxItems(context) {
    const token = context.getters.getAuthToken
    const params = {
      isSelectedReceiptDate:
        context.getters.inboxItemsListParams.isSelectedReceiptDate,
      dateFrom: formatDate(context.getters.inboxItemsListParams.dateFrom),
      dateTo: formatDate(context.getters.inboxItemsListParams.dateTo),
    }

    context.commit('setInboxItemsLoading', true)
    const data = await inboxItemsAPI.get(token, params)
    context.commit('setInboxItemsLoading', false)
    const listItems = data.inbox_items
    for (let item of listItems) {
      item.isCheck = false
    }
    context.commit('setInboxDetailStates', {
      listItems,
    })
    return true
  },
  getInboxDepartments(context) {
    return new Promise((resolve, reject) => {
      Axios.get(context.getters.apiInboxDepartmentsUrl, {
        headers: {
          Authorization: context.getters.getAuthToken,
        },
      })
        .then((response) => {
          if ('error' in response.data) {
            throw new Error(response.data.error)
          }
          response.data.departments.forEach((item) => {
            if (item.name_code == '' || !item.name_code) {
              item.name_code = '部門未指定'
            }
          })
          context.commit('setInboxStates', {
            departments: response.data.departments,
          })
          resolve(true)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getInboxCustomers(context) {
    return new Promise((resolve, reject) => {
      Axios.get(context.getters.apiCustomersFunction, {
        headers: {
          Authorization: context.getters.getAuthToken,
        },
      })
        .then((response) => {
          if (response.data.error) {
            return reject(response.data.error)
          }
          const customers = response.data.customers
          const userOrgEmail = response.data.user_org_email
          let userOrganizations = []

          for (let userOrg of response.data.user_list) {
            userOrganizations.push({
              id: String(userOrg.id),
              name: userOrg.name,
              status: userOrg.status,
              privileges: userOrg.privileges,
            })
          }
          context.commit('setInboxStates', {
            customers: customers,
            userOrgEmail: userOrgEmail,
            userOrganizations: userOrganizations,
          })
          resolve(true)
        })
        .catch((err) => {
          return reject(err)
        })
    })
  },
  getInboxPDFUrl(context, inboxId) {
    const url = context.getters.apiInboxImageFunction
    const params = {
      inbox_id: inboxId,
    }
    const headers = { Authorization: context.getters.getAuthToken }

    return new Promise((resolve, reject) => {
      Axios.get(url, { params, headers })
        .then((response) => {
          if (response.data.error) {
            throw new Error(response.data.error)
          }
          resolve(response)
        })
        .catch(function (error) {
          Snackbar.error(error.message)
          reject(error)
        })
    })
  },
  getInboxImage(context, inboxId) {
    return new Promise((resolve, reject) => {
      context
        .dispatch('getInboxPDFUrl', inboxId)
        .then((response) => {
          const imageData = {
            pdfUrl: response.data.pdf_url,
          }
          if (inboxId == context.getters.inboxSelectedItemId) {
            context.commit('setImageData', imageData)
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  getInboxItem(context, inboxId) {
    const url = context.getters.apiInboxItemsFunction + inboxId
    const params = {}
    const headers = { Authorization: context.getters.getAuthToken }

    return new Promise((resolve, reject) => {
      Axios.get(url, { params, headers })
        .then((response) => {
          context.dispatch('setSelectedItemId', inboxId)
          if (response.data.error) {
            throw new Error(response.data.message)
          }

          resolve(response.data)
        })
        .catch(function (error) {
          Snackbar.error(error.message)
          reject(error)
        })
    })
  },
  setSelectedInboxItemState(context, inboxItem) {
    context.commit('setSelectedInboxItemState', inboxItem)
  },
  updateSubmitStatusOfSelectedInboxItem(context, status) {
    const params = {
      submit_status: status,
    }
    context.commit('updateSelectedInboxItemState', params)
  },
  updateMemoOfSelectedInboxItem(context, memo) {
    const params = {
      memo: memo,
    }
    context.commit('updateSelectedInboxItemState', params)
  },
  downloadInboxPdfFile(context, inboxId) {
    return new Promise((resolve, reject) => {
      try {
        context.dispatch('getInboxPDFUrl', inboxId).then((response) => {
          Axios.get(response.data.pdf_url, { responseType: 'blob' }).then(
            (fileResponse) => {
              let a = document.createElement('a')
              let blob = new Blob([fileResponse.data], {
                type: 'application/pdf',
              })
              let pdfurl = window.URL.createObjectURL(blob)
              a.href = pdfurl
              a.download = response.data.file_name
              document.body.appendChild(a)
              a.click()
              window.URL.revokeObjectURL(pdfurl)
            }
          )
        })
      } catch (e) {
        reject(new Error(e))
      }
    })
  },
  getInboxCustomersTableData(context, payload) {
    var url = context.getters.apiCustomerTableFunction

    let yearFrom = Number(context.getters.inboxCustomersTableParams.year)
    if (
      payload !== undefined &&
      payload.year !== null &&
      payload.year !== undefined
    ) {
      yearFrom = Number(payload.year)
    }
    let monthFrom = Number(context.getters.inboxCustomersTableParams.month)
    if (
      payload !== undefined &&
      payload.month !== null &&
      payload.month !== undefined
    ) {
      monthFrom = Number(payload.month)
    }

    let yearTo = yearFrom
    let monthTo = monthFrom + 3
    if (monthTo > 12) {
      yearTo += 1
      monthTo -= 12
    }

    var params = {
      year_from: yearFrom,
      month_from: monthFrom,
      year_to: yearTo,
      month_to: monthTo,
      is_list: true,
    }
    var headers = { Authorization: context.getters.getAuthToken }

    return new Promise((resolve, reject) => {
      Axios.get(url, { params, headers })
        .then((response) => {
          if (response.data.error) {
            throw new Error(response.data.error)
          }
          let tableData = response.data.customer_table
          tableData.forEach((data) => {
            data.customer.isChecked = false
            let inboxData = data.items
            inboxData.forEach((items) => {
              if (items) {
                items.forEach((inbox) => {
                  ;(inbox.year = inbox.transaction_year),
                    (inbox.month = inbox.transaction_month)
                })
              }
            })
          })
          context.commit('setInboxCustomersStates', {
            tableData: tableData,
          })
          resolve(response)
        })
        .catch(function (error) {
          Snackbar.error(error.message)
          reject(error)
        })
    })
  },
  createInboxItem(context, payload) {
    let url = context.getters.apiInboxItemsFunction
    let params = {
      customer_id: payload.customerId,
      submit_status: payload.status,
      year: payload.year,
      month: payload.month,
    }
    if (payload.memo) {
      params.memo = payload.memo
    }
    let headers = { Authorization: context.getters.getAuthToken }

    return new Promise((resolve, reject) => {
      Axios.post(url, params, {
        headers: headers,
      })
        .then((response) => {
          if (response.data.status !== 'success') {
            throw new Error(response.data.error)
          }
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  createCustomer(context, payload) {
    let url = context.getters.apiCustomersFunction

    let selectedUsers = {}
    for (let [approvalKey, userIds] of Object.entries(payload.selectedUsers)) {
      selectedUsers[approvalKey] = []
      for (let userId of userIds) {
        selectedUsers[approvalKey].push(Number(userId))
      }
    }

    let params = {
      customer_name: payload.name,
      department_name: payload.departmentName,
      represent_name: payload.representName,
      email: payload.email,
      own_represent_user_org_id: payload.ownRepresentUserOrg,
      own_department_id: payload.ownDepartmentId,
      organization_id: context.getters.getOrganizationID,
      approval_stage: payload.approvalStage,
      approval_type: payload.approvalType,
      default_approve_users: selectedUsers,
      start_at: payload.startAt,
      end_at: payload.endAt,
      tran_months: payload.tranMonths,
      is_send_schedule_email: payload.isSendScheduleEmail,
      beginning_day: Number(payload.beginningDay),
      increment_day: Number(payload.incrementDay),
      email_template_id: payload.emailTemplateId,
      use_issue_date: payload.useIssueDate,
    }
    let headers = {
      Authorization: context.getters.getAuthToken,
    }

    return new Promise((resolve, reject) => {
      Axios.post(url, params, {
        headers: headers,
      })
        .then((response) => {
          if (response.data.status !== 'success') {
            throw new Error(response.data.error)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateCustomer(context, payload) {
    let url = context.getters.apiCustomersFunction + '/' + payload.id

    let selectedUsers = {}
    for (let [approvalKey, userIds] of Object.entries(payload.selectedUsers)) {
      selectedUsers[approvalKey] = []
      for (let userId of userIds) {
        selectedUsers[approvalKey].push(Number(userId))
      }
    }

    let params = {
      customer_name: payload.name,
      department_name: payload.departmentName,
      represent_name: payload.representName,
      email: payload.email,
      own_represent_user_org_id: payload.ownRepresentUserOrg,
      own_department_id: payload.ownDepartmentId,
      status: payload.status,
      is_uncategorized: payload.isUncategorized,
      approval_stage: payload.approvalStage,
      approval_type: payload.approvalType,
      default_approve_users: selectedUsers,
      start_at: payload.startAt,
      end_at: payload.endAt,
      tran_months: payload.tranMonths,
      is_send_schedule_email: payload.isSendScheduleEmail,
      beginning_day: Number(payload.beginningDay),
      increment_day: Number(payload.incrementDay),
      email_template_id: payload.emailTemplateId,
      use_issue_date: payload.useIssueDate,
    }
    let headers = {
      Authorization: context.getters.getAuthToken,
    }

    return new Promise((resolve, reject) => {
      Axios.put(url, params, {
        headers: headers,
      })
        .then((response) => {
          if (response.data.status !== 'success') {
            throw new Error(response.data.error)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateCustomers(context, payload) {
    const url = context.getters.apiCustomersFunction

    let selectedUsers = {}
    for (let [approvalKey, userIds] of Object.entries(payload.selectedUsers)) {
      selectedUsers[approvalKey] = []
      for (let userId of userIds) {
        selectedUsers[approvalKey].push(Number(userId))
      }
    }
    const params = {
      customer_ids: payload.customerIds,
      status: payload.status,
      own_represent_user_org_id: payload.ownRepresentUserOrg,
      own_department_id: payload.ownDepartmentId,
      approval_stage: payload.approvalStage,
      approval_type: payload.approvalType,
      is_update_default_approve_users: payload.isUpdateDefaultApproveUsers,
      default_approve_users: selectedUsers,
      is_update_tran_months: payload.isUpdateTranMonths,
      start_at: payload.startAt,
      end_at: payload.endAt,
      tran_months: payload.tranMonths,
      is_update_schedule_email: payload.isUpdateScheduleEmail,
      is_send_schedule_email: payload.isSendScheduleEmail,
      beginning_day: Number(payload.beginningDay),
      increment_day: Number(payload.incrementDay),
      email_template_id: payload.emailTemplateId,
      use_issue_date: payload.useIssueDate,
    }
    const headers = {
      Authorization: context.getters.getAuthToken,
    }

    return new Promise((resolve, reject) => {
      Axios.put(url, params, {
        headers: headers,
      })
        .then((response) => {
          if (response.data.status !== 'success') {
            throw new Error(response.data.error)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteCustomer(context, customerId) {
    const url = context.getters.apiCustomersFunction + '/' + customerId
    const headers = {
      Authorization: context.getters.getAuthToken,
    }

    return new Promise((resolve, reject) => {
      Axios.delete(url, {
        headers: headers,
      })
        .then((response) => {
          if (response.data.status !== 'success') {
            throw new Error(response.data.error)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteCustomers(context, payload) {
    const url = context.getters.apiCustomersFunction
    const data = {
      customer_ids: payload.customerIds,
    }
    const headers = {
      Authorization: context.getters.getAuthToken,
    }

    return new Promise((resolve, reject) => {
      Axios.delete(url, {
        data: data,
        headers: headers,
      })
        .then((response) => {
          if (response.data.status !== 'success') {
            throw new Error(response.data.error)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getInboxEmailTemplates(context) {
    let url = context.getters.apiInboxEmailTemplatesUrl
    const headers = {
      Authorization: context.getters.getAuthToken,
    }
    return new Promise((resolve, reject) => {
      Axios.get(url, { headers: headers })
        .then((response) => {
          if (response.data.status !== 'success') {
            throw new Error(response.data.error)
          }
          const templates = response.data.templates
          context.commit('setInboxStates', {
            emailTemplates: templates,
          })
          resolve(true)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createInboxEmailTemplate(context, payload) {
    let url = context.getters.apiInboxEmailTemplatesUrl
    const headers = {
      Authorization: context.getters.getAuthToken,
    }
    const params = {
      name: payload.name,
      body: payload.body,
    }
    return new Promise((resolve, reject) => {
      Axios.post(url, params, {
        headers: headers,
      })
        .then((response) => {
          if (response.data.status !== 'success') {
            throw new Error(response.data.error)
          }
          let templates = deepCopy(context.getters.inboxEmailTemplates)
          templates.push(response.data.template)
          context.commit('setInboxStates', {
            emailTemplates: templates,
          })
          resolve(response.data.template)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateInboxEmailTemplate(context, payload) {
    let url = context.getters.apiInboxEmailTemplatesUrl + payload.id
    const headers = {
      Authorization: context.getters.getAuthToken,
    }
    const params = {
      name: payload.name,
      body: payload.body,
    }
    return new Promise((resolve, reject) => {
      Axios.put(url, params, {
        headers: headers,
      })
        .then((response) => {
          if (response.data.status !== 'success') {
            throw new Error(response.data.error)
          }
          let templates = deepCopy(context.getters.inboxEmailTemplates)
          let index = templates.findIndex((obj) => obj.id === payload.id)
          templates[index].name = payload.name
          templates[index].body = payload.body
          context.commit('setInboxStates', {
            emailTemplates: templates,
          })
          resolve(true)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteInboxEmailTemplate(context, payload) {
    let url = context.getters.apiInboxEmailTemplatesUrl + payload.id
    const headers = { Authorization: context.getters.getAuthToken }
    return new Promise((resolve, reject) => {
      Axios.delete(url, {
        headers: headers,
      })
        .then((response) => {
          if (response.data.status !== 'success') {
            throw new Error(response.data.error)
          }
          let templates = deepCopy(context.getters.inboxEmailTemplates)
          let index = templates.findIndex((obj) => obj.id === payload.id)
          templates.splice(index, 1)
          context.commit('setInboxStates', {
            emailTemplates: templates,
          })
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateMemo(context, payload) {
    let url = context.getters.apiInboxItemsFunction + payload.inboxId
    let params = {
      memo: payload.memo,
    }
    let headers = { Authorization: context.getters.getAuthToken }

    return new Promise((resolve, reject) => {
      Axios.patch(url, params, {
        headers: headers,
      })
        .then((response) => {
          if (response.data.error) {
            throw new Error(response.data.message)
          }
          resolve(response)
        })
        .catch(function (error) {
          Snackbar.error(error.message)
        })
    })
  },
  patchInboxItem(
    context,
    {
      inboxId,
      customerId,
      status,
      purpose,
      comment,
      transactionMonth,
      transactionYear,
    }
  ) {
    let url = context.getters.apiInboxItemsFunction + inboxId

    let params = {}
    if (status !== null && status !== undefined) {
      params.submit_status = status
    }
    if (comment !== null && comment !== undefined) {
      params.submit_comment = comment
    }
    if (purpose !== null && purpose !== undefined) {
      params.purpose = purpose
    }
    if (customerId) {
      params.customer_id = customerId
    }
    if (transactionYear && transactionMonth) {
      params.transaction_year = transactionYear
      params.transaction_month = transactionMonth
    }

    let headers = { Authorization: context.getters.getAuthToken }

    return new Promise((resolve, reject) => {
      Axios.patch(url, params, { headers })
        .then((response) => {
          if (response.data.error) {
            throw new Error(response.data.message)
          }
          resolve(response)
        })
        .catch((err) => {
          Snackbar.error(err.message)
          reject(err)
        })
    })
  },
  async getCustomerApprovalStage(context, payload) {
    const token = context.getters.getAuthToken
    const data = await CustomerApprovalStageAPI.get(token, payload)
    return data
  },
  async getNextApprovalRequests(context, payload) {
    const token = context.getters.getAuthToken
    const data = await InboxNextApprovalAPI.get(token, payload)
    return data
  },
  async putNextApprovalRequests(context, payload) {
    const token = context.getters.getAuthToken
    let nextApproveUsers = {}
    for (let [approvalKey, userIds] of Object.entries(
      payload.nextApprovalUsers
    )) {
      nextApproveUsers[approvalKey] = []
      for (let userId of userIds) {
        nextApproveUsers[approvalKey].push(Number(userId))
      }
    }
    payload.nextApproveUsers = nextApproveUsers

    const data = await InboxNextApprovalAPI.put(token, payload)
    return data
  },
  approveInboxItem(context, payload) {
    const url =
      context.getters.apiInboxItemsFunction +
      payload.inboxId +
      '/approval/' +
      payload.stage
    const params = {
      approved_user_id: context.getters.getUserOrganizationID,
    }
    const headers = { Authorization: context.getters.getAuthToken }

    return new Promise((resolve, reject) => {
      Axios.put(url, params, {
        headers: headers,
      })
        .then((response) => {
          if (response.data.status != 'success') {
            throw new Error(response.data.message)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  finalApproveInboxItem(context, payload) {
    const url =
      context.getters.apiInboxItemsFunction +
      payload.inboxId +
      '/final-approval'

    const params = {
      approved_user_id: context.getters.getUserOrganizationID,
    }
    const headers = { Authorization: context.getters.getAuthToken }

    return new Promise((resolve, reject) => {
      Axios.put(url, params, {
        headers: headers,
      })
        .then((response) => {
          if (response.data.status != 'success') {
            throw new Error(response.data.message)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  approveCancelInboxItem(context, payload) {
    const url =
      context.getters.apiInboxItemsFunction +
      payload.inboxId +
      '/approval/' +
      payload.stage
    const params = {
      headers: { Authorization: context.getters.getAuthToken },
    }

    return new Promise((resolve, reject) => {
      Axios.delete(url, params)
        .then((response) => {
          if (response.data.status != 'success') {
            throw new Error(response.data.message)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateSortNum(context, payload) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          url: context.getters.apiCustomersSortsUrl,
          method: 'PUT',
          args: {
            customer_id: payload.customerId,
          },
          data: {
            sort_num: payload.sortNum,
          },
        }
        customRequest(data).then((response) => {
          if (response.status == 'success') {
            state.inboxCustomers.tableData.forEach((item) => {
              if (item.customer.id == payload.customerId) {
                item.customer.sort_num = payload.sortNum
              }
            })
            state.inboxCustomers.tableData = sortTableData(
              state.inboxCustomers.tableData
            )
          }
          resolve(response)
        })
      } catch (e) {
        reject(new Error(e))
      }
    })
  },
  updateInboxItemState(context, payload) {
    try {
      const inboxItems = context.getters.inboxItems
      const inboxId = payload.id
      const index = inboxItems.findIndex((obj) => obj.id === inboxId)
      if (!index) {
        return
      }
      let inboxItem = deepCopy(inboxItems[index])
      Object.keys(payload).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(inboxItem, key)) {
          inboxItem[key] = payload[key]
        }
      })
      state.inboxDetail.listItems[index] = inboxItem
    } catch (err) {
      console.log(err)
    }
  },
  sendBillRequest(context, { customerId, inboxId, form }) {
    const url = context.getters.apiCustomerEmailFunction
    const headers = {
      Authorization: context.getters.getAuthToken,
    }
    const body = {
      customer_id: customerId,
      inbox_id: inboxId,
      email_form: form,
    }
    return new Promise((resolve, reject) => {
      Axios.post(url, body, { headers }).then((response) => {
        if ('error' in response.data) {
          reject(new Error(response.data.message))
        } else {
          context.dispatch('getInboxCustomersTableData')
          context.dispatch('getInboxItems')
          resolve()
        }
      })
    })
  },
  bulkSendBillRequests(context, { customerIds, form }) {
    const url = context.getters.apiCustomerEmailsFunction
    const headers = {
      Authorization: context.getters.getAuthToken,
    }
    const body = {
      customer_ids: customerIds,
      email_form: form,
    }
    return new Promise((resolve, reject) => {
      Axios.post(url, body, { headers }).then((response) => {
        if ('error' in response.data) {
          reject(new Error(response.data.message))
        } else {
          context.dispatch('getInboxCustomersTableData')
          context.dispatch('getInboxItems')
          resolve()
        }
      })
    })
  },
  sendBillRequestExam(context, { customerIds, form }) {
    const url = context.getters.apiCustomerEmailExamFunction
    const headers = {
      Authorization: context.getters.getAuthToken,
    }
    const body = {
      customer_ids: customerIds,
      email_form: form,
    }
    return new Promise((resolve, reject) => {
      Axios.post(url, body, { headers: headers }).then((response) => {
        if ('error' in response.data) {
          reject(new Error(response.data.message))
        } else {
          resolve()
        }
      })
    })
  },
  initInboxItemsStates(context) {
    let listParams = JSON.parse(localStorage.getItem('inboxItems.listParams'))
    if (!listParams) {
      let params = {
        status: -1,
        isSelectedReceiptDate: true,
        dateTo: new Date(),
        dateFrom: new Date(),
        ownDepartmentId: '',
      }

      context.dispatch('setInboxDetailState', {
        field: 'listParams',
        value: params,
        setLocalStorage: true,
      })
    }

    listParams.dateTo = new Date(listParams.dateTo)
    listParams.dateFrom = new Date(listParams.dateFrom)

    context.commit('setInboxDetailStates', {
      listParams,
    })
  },
  initInboxCustomersStates(context) {
    // get localStorage
    let tableParams = JSON.parse(
      localStorage.getItem('inboxCustomers.tableParams')
    )
    let isUnnecessaryImpress = localStorage.getItem(
      'inboxCustomers.isUnnecessaryImpress'
    )

    tableParams = checkTableParams(tableParams)

    // isUnnecessaryImpress
    if (isUnnecessaryImpress == 'true') {
      isUnnecessaryImpress = true
    } else {
      isUnnecessaryImpress = false
    }

    // set
    context.commit('setInboxCustomersStates', {
      tableParams,
      isUnnecessaryImpress,
    })
  },
  updateInboxItemsParams(context, params) {
    const keys = [
      'status',
      'isSelectedReceiptDate',
      'dateFrom',
      'dateTo',
      'ownDepartmentId',
    ]
    const paramsFrom = context.getters.inboxItemsListParams
    let paramsTo = {}
    keys.forEach((key) => {
      let value = params[key]
      if (value === undefined && paramsFrom[key] !== undefined) {
        value = paramsFrom[key]
      }
      if (value === undefined && paramsFrom[key] === undefined) {
        value = ''
      }
      paramsTo[key] = value
    })

    const updateItemsKeys = ['isSelectedReceiptDate', 'dateFrom', 'dateTo']
    const isUpdateItems = Object.keys(params).some((key) =>
      updateItemsKeys.includes(key)
    )
    context
      .dispatch('setInboxDetailState', {
        field: 'listParams',
        value: paramsTo,
        setLocalStorage: true,
      })
      .then(() => {
        if (isUpdateItems) {
          context.dispatch('getInboxItems')
        }
      })
  },
  toNextMonth(context) {
    let params = {
      year: Number(context.getters.inboxCustomersTableParams.year),
      month: Number(context.getters.inboxCustomersTableParams.month) + 1,
    }
    params = checkTableDateParams(params)
    context.dispatch('getInboxCustomersTableData', params).then((response) => {
      if (response.data.status == 'success') {
        const paramsTo = context.getters.inboxCustomersTableParams
        paramsTo.year = params.year
        paramsTo.month = params.month
        context.dispatch('setInboxCustomersState', {
          field: 'tableParams',
          value: paramsTo,
          setLocalStorage: true,
        })
      }
    })
  },
  toPrevMonth(context) {
    let params = {
      year: Number(context.getters.inboxCustomersTableParams.year),
      month: Number(context.getters.inboxCustomersTableParams.month) - 1,
    }
    params = checkTableDateParams(params)
    context.dispatch('getInboxCustomersTableData', params).then((response) => {
      if (response.data.status == 'success') {
        const paramsTo = context.getters.inboxCustomersTableParams
        paramsTo.year = params.year
        paramsTo.month = params.month
        context.dispatch('setInboxCustomersState', {
          field: 'tableParams',
          value: paramsTo,
          setLocalStorage: true,
        })
      }
    })
  },
  updateInboxCustomersTableParams(context, params) {
    const keys = [
      'year',
      'month',
      'text',
      'status',
      'ownDepartmentId',
      'isAll',
      'showUncategorized',
    ]
    const paramsFrom = context.getters.inboxCustomersTableParams
    let paramsTo = {}
    keys.forEach((key) => {
      let value = params[key]
      if (value === undefined && paramsFrom[key] !== undefined) {
        value = paramsFrom[key]
      }
      if (value === undefined && paramsFrom[key] === undefined) {
        value = ''
      }
      paramsTo[key] = value
    })
    paramsTo = checkTableParams(paramsTo)
    context.dispatch('setInboxCustomersState', {
      field: 'tableParams',
      value: paramsTo,
      setLocalStorage: true,
    })
  },
  setInboxMenu(context, menu) {
    context.commit('setInboxStates', { menu })
    if (menu === 'inbox') {
      context.dispatch('getInboxItems')
    }
  },
  setInboxCustomersState(context, { field, value, setLocalStorage }) {
    let param = {}
    param[field] = value
    context.commit('setInboxCustomersStates', param)
    if (setLocalStorage) {
      const storageKey = 'inboxCustomers.' + field
      localStorage.setItem(storageKey, JSON.stringify(value))
    }
  },
  setInboxDetailState(context, { field, value, setLocalStorage }) {
    let param = {}
    param[field] = value
    context.commit('setInboxDetailStates', param)
    if (setLocalStorage) {
      const storageKey = 'inboxItems.' + field
      localStorage.setItem(storageKey, JSON.stringify(value))
    }
  },
  setSelectedItemId(context, id) {
    context.commit('setInboxStates', {
      selectedItemId: id,
    })
  },
}

const mutations = {
  setInboxStates(state, params) {
    Object.keys(params).forEach((field) => {
      Vue.set(state.inbox, field, params[field])
    })
  },
  setImageData(state, params) {
    Object.keys(params).forEach((field) => {
      Vue.set(state.imageData, field, params[field])
    })
  },
  setInboxCustomersStates(state, params) {
    Object.keys(params).forEach((field) => {
      Vue.set(state.inboxCustomers, field, params[field])
    })
  },
  setInboxDetailStates(state, params) {
    Object.keys(params).forEach((field) => {
      Vue.set(state.inboxDetail, field, params[field])
    })
  },
  setSelectedInboxItemState(state, inboxItem) {
    state.selectedInboxItem = inboxItem
  },
  updateSelectedInboxItemState(state, params) {
    Object.keys(params).forEach((field) => {
      Vue.set(state.selectedInboxItem, field, params[field])
    })
  },
  setInboxItemsLoading(state, isLoading) {
    state.inboxDetail.loading = isLoading
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
