<template>
  <base-dialog
    ref="dialog"
    size="small"
    :title="$t('message.confirm')"
    :message="'権限がありません'"
  >
    <template #card-actions>
      <v-spacer />
      <v-btn depressed @click="close">
        {{ $t('message.modal_close_btn') }}
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
export default {
  methods: {
    open() {
      this.$refs.dialog.open()
    },
    close() {
      this.$refs.dialog.close()
    },
  },
}
</script>
