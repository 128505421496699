import Vue from 'vue'
import Axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'
import { splitLine } from 'Helpers/helpers'

const state = {
  loadedCount: 0,
  accountTitleList: [],
  departmentList: [],
  vendorList: [],
  projectList: [],
  segmentList: [],
  walletableList: [],
  itemList: [],
  tagList: [],
  taxList: [],
}

const getters = {
  loadedCount: (state) => {
    return state.loadedCount
  },
  masterList: (state) => {
    return {
      account_title: state.accountTitleList,
      department: state.departmentList,
      vendor: state.vendorList,
      project: state.projectList,
      segment: state.segmentList,
      walletable: state.walletableList,
      item: state.itemList,
      tag: state.tagList,
      tax: state.taxList,
    }
  },
  accountTitleList: (state) => {
    return state.accountTitleList
  },
  departmentList: (state) => {
    return state.departmentList
  },
  vendorList: (state) => {
    return state.vendorList
  },
  projectList: (state) => {
    return state.projectList
  },
  segmentList: (state) => {
    return state.segmentList
  },
  walletableList: (state) => {
    return state.walletableList
  },
  itemList: (state) => {
    return state.itemList
  },
  tagList: (state) => {
    return state.tagList
  },
  taxList: (state) => {
    return state.taxList
  },
}

function errorNotify(msg) {
  Snackbar.error(msg)
}

const actions = {
  // actionを分けて、変更に必要なマスタだけを取得したい
  getMasterList(context, payload) {
    try {
      const authToken = context.getters.getAuthToken
      const organization_id = context.getters.getOrganizationID
      context.commit('initLoadedCount')

      Axios.get(context.getters.apiAccountTitlesUrl, {
        headers: { Authorization: authToken },
        params: { organization_id: organization_id },
      }).then((response) => {
        context.commit('setMasterList', {
          data: response.data,
          propertyName: 'accountTitleList',
        })
      })
      Axios.get(context.getters.apiDepartmentsUrl, {
        headers: { Authorization: authToken },
        params: { organization_id: organization_id },
      }).then((response) => {
        context.commit('setMasterList', {
          data: response.data,
          propertyName: 'departmentList',
        })
      })
      Axios.get(context.getters.apiVendorsUrl, {
        headers: { Authorization: authToken },
        params: { organization_id: organization_id },
      }).then((response) => {
        context.commit('setMasterList', {
          data: response.data,
          propertyName: 'vendorList',
        })
      })
      Axios.get(context.getters.apiSegmentsUrl, {
        headers: { Authorization: authToken },
        params: { organization_id: organization_id },
      }).then((response) => {
        context.commit('setMasterList', {
          data: response.data,
          propertyName: 'segmentList',
        })
      })
      Axios.get(context.getters.apiProjectsUrl, {
        headers: { Authorization: authToken },
        params: { organization_id: organization_id },
      }).then((response) => {
        context.commit('setMasterList', {
          data: response.data,
          propertyName: 'projectList',
        })
      })

      Axios.get(context.getters.apiWalletablesUrl, {
        headers: { Authorization: authToken },
        params: { organization_id: organization_id },
      }).then((response) => {
        context.commit('setMasterList', {
          data: response.data,
          propertyName: 'walletableList',
        })
      })

      Axios.get(context.getters.apiItemsUrl, {
        headers: { Authorization: authToken },
        params: { organization_id: organization_id },
      }).then((response) => {
        context.commit('setMasterList', {
          data: response.data,
          propertyName: 'itemList',
        })
      })

      Axios.get(context.getters.apiTagsUrl, {
        headers: { Authorization: authToken },
        params: { organization_id: organization_id },
      }).then((response) => {
        context.commit('setMasterList', {
          data: response.data,
          propertyName: 'tagList',
        })
      })
      Axios.get(context.getters.apiTaxclassesUrl, {
        headers: { Authorization: authToken },
        params: { organization_id: organization_id },
      }).then((response) => {
        context.commit('setMasterList', {
          data: response.data,
          propertyName: 'taxList',
        })
      })
    } catch (e) {
      console.log(e)
    }
  },
  checkDuplicates(context, payload) {
    function getLength(array) {
      try {
        return array.length
      } catch (e) {
        return 0
      }
    }

    function getVal(item, multiKey) {
      // objectから要素を取得(ダブルアンダーバー区切りで２階層以上指定できる)
      let keys = multiKey.split('__')
      for (let key of keys) {
        item = item[key]
      }
      return item
    }

    let data = payload.data
    let item = payload.item
    let keys = payload.keys

    try {
      var duplicateItems = {}
      return new Promise((resolve) => {
        let count = 0
        for (let k of keys) {
          duplicateItems[k] = data.filter(
            (v) => getVal(v, k) === getVal(item, k) && getVal(v, k) !== ''
          )
          count += getLength(duplicateItems[k])
        }

        if (count) {
          resolve({ isDuplicate: true, duplicateItems: duplicateItems })
        } else {
          resolve({ isDuplicate: false, duplicateItems: {} })
        }
      })
    } catch (e) {
      console.log(e)
    }
  },
  GetMaster_API(context, name) {
    try {
      var url = context.getters.apiGetMaster
      var data = {
        name: name,
        user_organization_id: context.getters.getUserOrganizationID,
        organization_id: context.getters.getOrganizationID,
      }
      var headers = { Authorization: context.getters.getAuthToken }

      return new Promise((resolve, reject) => {
        Axios.get(url, { params: data, headers: headers })
          .then((response) => {
            resolve(response)
          })
          .catch(function (error) {
            errorNotify(error.message)
          })
      })
    } catch (e) {
      console.log(e)
    }
  },
  async SortMaster_API(context, payload) {
    try {
      var url = context.getters.apiSortMaster
      var name = payload.name
      var mode = payload.mode
      var order_value = payload.order_value
      var order_type = payload.order_type

      if (!payload.items) {
        errorNotify('NoItems')
        return null
      } else {
        var items = payload.items.slice()
      }

      function get_item(item, names) {
        // objectから要素を取得(ピリオド区切りで２階層以上指定できる)
        let name_split = names.split('.')
        for (let name of name_split) {
          item = item[name]
        }
        return item
      }

      function sortItems(items, mode, order_value, order_type) {
        // order_valueでデータ並び替え
        // order_type=asending => 昇順
        // order_type=descending => 降順

        if (mode == 'bulk') {
          if (order_type === 'descending') {
            // 降順ソート
            items.sort((after, before) => {
              return get_item(after, order_value) <
                get_item(before, order_value)
                ? 1
                : -1
            })
          } else {
            // 昇順ソート
            items.sort((after, before) => {
              return get_item(after, order_value) >
                get_item(before, order_value)
                ? 1
                : -1
            })
          }
        } else {
          // sot_numで昇順ソート
          items.sort((after, before) => {
            return parseInt(after['sort_num']) - parseInt(before['sort_num'])
          })
        }
      }

      function updateSortNum(items) {
        // データ並び替え
        // update sort_num
        for (var i = 0; i < items.length; i++) {
          items[i].sort_num = i + 1
        }
      }

      function SortOrderItems(items, name, mode, order_value, order_type) {
        sortItems(items, mode, order_value, order_type)
        if (mode == 'bulk') {
          updateSortNum(items, mode, order_value, order_type)
        }
        if (name !== 'account_title') {
          return items
        }
        // 補助科目もソート
        items.forEach((item) => {
          sortItems(item.subjects, mode, order_value, order_type)
          if (mode == 'bulk') {
            updateSortNum(item.subjects, mode, order_value, order_type)
          }
        })
        return items
      }

      var items_sorted = SortOrderItems(
        items,
        name,
        mode,
        order_value,
        order_type
      )

      return new Promise((resolve, reject) => {
        if (!items_sorted) {
          errorNotify('NoItems')
          return false
        }
        var data = {
          name: name,
          items: items_sorted,
          user_organization_id: context.getters.getUserOrganizationID,
          organization_id: context.getters.getOrganizationID,
        }

        var params = {
          headers: { Authorization: context.getters.getAuthToken },
        }

        Axios.post(url, data, params)
          .then(function (response) {
            if (response.data.error) {
              errorNotify(response.data.error)
            } else {
              response.data.items = items_sorted
            }
            resolve(response)
          })
          .catch(function (error) {
            errorNotify(error.message)
          })
      })
    } catch (e) {
      console.log(e)
    }
  },
  ExportMaster_API(context, payload) {
    try {
      var url = context.getters.apiExportMaster
      var name = payload.name
      var mode = payload.mode
      var file_name = name + '_' + mode + '.csv'

      $.ajax({
        url: url,
        type: 'GET',
        data: {
          name: name,
          mode: mode,
          organization_id: context.getters.getOrganizationID,
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', context.getters.getAuthToken)
        },
        xhrFields: {
          responseType: 'blob',
        },
        success: function (response) {
          var a = document.createElement('a')
          var url = window.URL.createObjectURL(response)
          a.href = url
          a.download = file_name
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        }.bind(this),
      })
    } catch (e) {
      console.log(e)
    }
  },
  checkImportMasterData(context, data) {
    return new Promise((resolve, reject) => {
      Axios.post(context.getters.apiCheckImport, data, {
        headers: {
          Authorization: context.getters.getAuthToken,
        },
      })
        .then((response) => {
          if (response.data.status == 'success') {
            resolve(response)
          } else {
            throw new Error(response.data.error)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  importMasterData(context, payload) {
    var url = context.getters.apiImportMaster
    return new Promise((resolve, reject) => {
      Axios.put(
        url,
        {
          items: payload.data.items,
          name: payload.data.name,
          delete_names: payload.data.delete_names,
        },
        {
          headers: { Authorization: context.getters.getAuthToken },
        }
      )
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
            resolve(response)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  importCSV(context, payload) {
    return new Promise((resolve, reject) => {
      try {
        var request = new XMLHttpRequest()
        request.open('GET', payload.data.base64, false)
        request.overrideMimeType('text/plain; charset=Shift_JIS')
        request.send(null)
        var csvData = new Array()
        var jsonObject = request.responseText.split(/\r?\n|\r/)
        var promisedData = []
        for (var i = 0; i < jsonObject.length; i++) {
          if (jsonObject[i].length > 0) {
            csvData.push(splitLine(jsonObject[i]))
          }
        }
        for (let g = 1; g < csvData.length; g++) {
          let toData = {
            code: csvData[g][0],
            name: csvData[g][1],
          }
          promisedData.push(toData)
        }
        resolve(promisedData)
      } catch (e) {
        console.log(e)
        errorNotify('Something went wrong!')
      }
    })
  },
  importCSV_Accounting(context, payload) {
    return new Promise((resolve, reject) => {
      try {
        var request = new XMLHttpRequest()
        request.open('GET', payload.data.base64, false)
        request.overrideMimeType('text/plain; charset=Shift_JIS')
        request.send(null)
        var csvData = new Array()
        var jsonObject = request.responseText.split(/\r?\n|\r/)
        var promisedData = []
        for (var i = 0; i < jsonObject.length; i++) {
          if (jsonObject[i].length > 0) {
            csvData.push(splitLine(jsonObject[i]))
          }
        }
        for (let g = 1; g < csvData.length; g++) {
          let toData = {
            account_code: csvData[g][0],
            account_name: csvData[g][1],
            sub_code: csvData[g][2],
            sub_name: csvData[g][3],
            tax_classification_code: csvData[g][4],
          }
          promisedData.push(toData)
        }
        resolve(promisedData)
      } catch (e) {
        console.log(e)
        errorNotify('Something went wrong!')
      }
    })
  },
  importCSV_TaxClass(context, payload) {
    return new Promise((resolve, reject) => {
      try {
        var request = new XMLHttpRequest()
        request.open('GET', payload.data.base64, false)
        request.overrideMimeType('text/plain; charset=Shift_JIS')
        request.send(null)
        var csvData = new Array()
        var jsonObject = request.responseText.split(/\r?\n|\r/)
        var promisedData = []
        for (var i = 0; i < jsonObject.length; i++) {
          if (jsonObject[i].length > 0) {
            csvData.push(splitLine(jsonObject[i]))
          }
        }
        for (let g = 1; g < csvData.length; g++) {
          let toData = {
            code: csvData[g][0],
            name: csvData[g][1],
            rate: csvData[g][2],
            reduced_tax: csvData[g][3],
            export_code: csvData[g][4],
          }
          promisedData.push(toData)
        }
        resolve(promisedData)
      } catch (e) {
        console.log(e)
        errorNotify('Something went wrong!')
      }
    })
  },
  importCSV_ResportProject(context, payload) {
    return new Promise((resolve, reject) => {
      try {
        var request = new XMLHttpRequest()
        request.open('GET', payload.data.base64, false)
        request.overrideMimeType('text/plain; charset=Shift_JIS')
        request.send(null)
        var csvData = new Array()
        var jsonObject = request.responseText.split(/\r?\n|\r/)
        var promisedData = []
        for (var i = 0; i < jsonObject.length; i++) {
          if (jsonObject[i].includes(',')) {
            csvData.push(splitLine(jsonObject[i]))
          }
        }
        for (let g = 1; g < csvData.length; g++) {
          let toData = {
            code: csvData[g][0],
            name: csvData[g][1],
            local_bank_code: csvData[g][2],
            bank_master_name: csvData[g][3],
            bank_code: csvData[g][4],
            bank_name: csvData[g][5],
            bank_branch_code: csvData[g][6],
            bank_branch_name: csvData[g][7],
            bank_account_type: csvData[g][8],
            bank_account_number: csvData[g][9],
            bank_account_name: csvData[g][10],
          }

          promisedData.push(toData)
        }
        resolve(promisedData)
      } catch (e) {
        console.log(e)
        errorNotify('Something went wrong!')
      }
    })
  },
}

const mutations = {
  setAccountTitleList(state, data) {
    state.accountTitleList = data
    state.loadedCount++
  },
  setDepartmentList(state, data) {
    state.departmentList = data
    state.loadedCount++
  },
  setVendorList(state, data) {
    state.vendorList = data
    state.loadedCount++
  },
  setProjectList(state, data) {
    state.projectList = data
    state.loadedCount++
  },
  setSegmentList(state, data) {
    state.segmentList = data
    state.loadedCount++
  },
  setWalletableList(state, data) {
    state.walletableList = data
    state.loadedCount++
  },
  setItemList(state, data) {
    state.itemList = data
    state.loadedCount++
  },
  setTagList(state, data) {
    state.tagList = data
    state.loadedCount++
  },
  setTaxList(state, data) {
    state.taxList = data
    state.loadedCount++
  },
  initLoadedCount(state) {
    state.loadedCount = 0
  },
  setMasterList(state, payload) {
    state.loadedCount++
    const propertyName = payload.propertyName
    const masterList = payload.data
    state[propertyName].length = 0
    masterList.forEach((value, index) => {
      Vue.set(state[propertyName], index, value)
    })
  },
  setMasterData(state, payload) {
    state.loadedCount++
    const propertyName = payload.propertyName
    const masterData = payload.masterData
    const id = masterData.id
    const masterList = state[propertyName]
    const index = masterList.findIndex((v) => {
      return v.id === id
    })
    Vue.set(masterList, index, masterData)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
