import Vue from 'vue'
import Vuex from 'vuex'

// modules
import auth from './modules/auth'
import invoice from './modules/invoice'
import invoice_upload from './modules/invoice_upload'
import payment from './modules/payment'
import settings from './modules/settings'
import ecommerce from './modules/ecommerce'
import mail from './modules/mail'
import inbox from './modules/inbox'
import sidebar from './modules/sidebar'
import api from './modules/api'
import tax from './modules/tax'
import department from './modules/department'
import user from './modules/user'
import account from './modules/account'
import session from './modules/session'
import company from './modules/company'
import custom_invoice from './modules/custom_invoice'
import ip_restriction from './modules/ip_restriction'
import project from './modules/project'
import segment from './modules/segment'
import vendor from './modules/vendor'
import master from './modules/master'
import exportformat from './modules/exportformat'
import organization from './modules/organization'
import bank from './modules/bank'
import freee from './modules/freee'
import role from './modules/role'
import recurring_invoice_schedule from './modules/recurring_invoice_schedule'
import report from './modules/report'
import transfer_fee from './modules/transfer_fee'
import initial_settings from './modules/initial_settings'
import email from './modules/email'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    auth,
    invoice,
    invoice_upload,
    payment,
    settings,
    ecommerce,
    mail,
    inbox,
    sidebar,
    api,
    tax,
    department,
    user,
    account,
    session,
    company,
    custom_invoice,
    ip_restriction,
    project,
    segment,
    vendor,
    master,
    exportformat,
    organization,
    bank,
    freee,
    role,
    recurring_invoice_schedule,
    report,
    transfer_fee,
    initial_settings,
    email,
  },
})
