<template functional>
  <span
    class="
      tw-inline-block
      tw-rounded
      tw-items-center
      tw-whitespace-nowrap
      tw-px-1.5
      tw-py-px
      tw-text-xs
      tw-font-semibold
      tw-border-transparent
      tw-text-white
    "
    :class="[
      data.staticClass,
      data.class,
      `tw-bg-${props.color ? props.color : 'gray-500'}`,
      `tw-text-${props.textColor ? props.textColor : 'current'}`,
    ]"
  >
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: null,
    },
    textColor: {
      type: String,
      default: null,
    },
  },
}
</script>
