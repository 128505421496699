import { store } from '../../store/store'

export function getAbilityLevel(ability) {
  try {
    const userAbilities = store.getters.userAbilities
    const level = userAbilities[ability]
    if (typeof level === 'number') {
      return level
    } else {
      return 0
    }
  } catch (err) {
    console.log(err)
    return 0
  }
}

export function checkPrivilege(ability) {
  try {
    const level = getAbilityLevel(ability)
    const hasPrivilege = level ? true : false
    return hasPrivilege
  } catch (err) {
    console.log(err)
    return false
  }
}
