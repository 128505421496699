import Vue from 'vue'
import axios from 'axios'
import { customRequest } from 'Helpers/api/request'
import Snackbar from 'Helpers/snackbar/index'

function errorNotify(msg) {
  Snackbar.error(msg)
}

const state = {}

const getters = {}

const actions = {
  deleteIpRestriction(context, payload) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          url: context.getters.apiIpRestrictionInstanceUrl,
          method: 'DELETE',
          args: {
            id: payload.data.id,
          },
        }
        customRequest(data)
          .then((response) => {
            if ('error' in response) {
              errorNotify(response.error)
            } else {
              resolve(response)
            }
          })
          .catch(function (error) {
            errorNotify(error.message)
          })
      } catch (e) {
        console.log(e)
        reject(new Error(e))
      }
    })
  },
  editIpRestriction(context, payload) {
    return new Promise((resolve, reject) => {
      const data = {
        url: context.getters.apiIpRestrictionInstanceUrl,
        method: 'PUT',
        args: {
          id: payload.data.id,
        },
        data: {
          ip_address: payload.data.ip_address,
          description: payload.data.description,
          organization_id: context.getters.getOrganizationID,
        },
      }
      customRequest(data)
        .then((response) => {
          if ('error' in response) {
            errorNotify(response.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
  addIpRestriction(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          context.getters.apiIpRestrictionsUrl,
          {
            ip_address: payload.data.ip_address,
            description: payload.data.description,
            user_organization_id: context.getters.getUserOrganizationID,
            organization_id: context.getters.getOrganizationID,
          },
          {
            headers: { Authorization: context.getters.getAuthToken },
          }
        )
        .then(function (response) {
          if ('error' in response.data) {
            errorNotify(response.data.error)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    })
  },
}

export default {
  state,
  getters,
  actions,
}
