const state = {
  baseUrl: process.env.VUE_APP_ROOT_API,
  envType: process.env.VUE_APP_ENV_TYPE,
  apiGetRestrictedIpUrl: 'api/restrictedip/',
  apiInvoiceImageUrl: 'api/invoiceimage/',
  apiCeckUserInviteUrl: 'api/checkuserinvite/',
  apiDefaultJournalUrl: 'api/defaultjournal/',
  apiLoginFunctionString: 'api/authenticate/',
  apiSignUpFunctionString: 'api/signupinstance/',
  apiSignUpCheckCodeString: 'api/signupinstance/',
  apiConfirmSignUpString: 'api/signupinstance/',
  apiForgotPasswordString: 'api/forgotpassword/',
  apiUserSessionString: 'api/usersession/',
  apiCompanyListString: 'api/companylist/',
  apiCustomerEmailString: 'api/customeremail/',
  apiCustomerEmailsString: 'customer-emails',
  apiCustomerEmailExamString: 'customer-emails-exam',
  apiTempUploadString: 'api/tempupload/',
  apiTempUploadInfoString: 'api/tempuploadinfo/',
  apiPasswordInstanceString: 'api/passwordinstance/',
  apiUserListString: 'api/userlist/',
  apiUserInviteString: 'api/userinviteinstance/',
  apiUserInviteAccceptString: 'api/userinviteaccept/',

  apiCustomersString: 'customers',
  apiCustomerCsvString: 'customer-csv',
  apiCustomerTableString: 'customer-table',
  apiInboxImageString: 'inbox-image',
  apiInboxItemsString: 'api/inboxitems/',
  apiInboxUploadString: 'api/inboxupload/',

  apiInvoicelistString: 'api/invoicesummarylist/',
  apiInvoiceExportString: 'api/invoiceexport/',
  apiInvoiceCheckExportString: 'api/invoicecheckexport/',
  apiInvoiceInstanceString: 'api/invoiceinstance/',
  apiInvoiceRestoreString: 'api/invoicerestore/',
  apiInvoiceCopyString: 'api/invoicecopy/',
  apiInvoiceConfirmString: 'api/invoiceconfirm/',
  apiInvoiceApproveString: 'api/invoiceapprove/',
  apiInvoiceCancelApproveUrl: 'api/invoicecancelapprove/',
  apiInvoicePayConfirmString: 'api/invoicepayconfirm/',
  apiInvoicePayApproveString: 'api/invoicepayapprove/',
  apiInvoicePayCancelApproveUrl: 'api/invoicepaycancelapprove/',
  apiInvoiceConvertComanyName: 'api/invoiceconvetcompany/',

  apiInvoiceMultiApproveString: 'api/invoicemultiapprove/',
  apiInvoiceMultiDeleteString: 'api/invoicemultidelete/',
  apiInvoiceMultiChangeDateString: 'api/invoicemultichangedate/',
  apiInoiceEditUserString: 'api/invoiceedituser/',
  apiTimeStampPDFString: 'api/timestamppdf/',
  apiFilterPatternsString: 'api/filterpatterns/',
  apiHiddenFilterPatternsString: 'api/hiddenfilterpatterns/',
  apiRecentUseListString: 'api/recentuselist/',

  apiPaymentImageUrl: 'api/paymentimage/',
  apiPaymentlistString: 'api/paymentlist/',
  apiPaymentExportString: 'api/paymentexport/',
  apiPaymentCheckExportString: 'api/paymentcheckexport/',
  apiPaymentZenginExportString: 'api/paymentzenginexport/',
  apiPaymentCheckZenginExportString: 'api/paymentcheckzenginexport/',
  apiPaymentDetailString: 'api/paymentdetail/',
  apiPaymentCheckCreateString: 'api/paymentcheckcreate/',
  apiPaymentConfirmString: 'api/paymentconfirm/',
  apiPaymentInvoiceConfirmString: 'api/paymentinvoiceconfirm/',
  apiPaymentApproveString: 'api/paymentapprove/',
  apiPaymentCancelApproveUrl: 'api/paymentcancelapprove/',
  apiPaymentDetailsListString: 'api/paymentdetailslist/',
  apiExportTypeList: 'api/exporttypelist/',
  apiExportFormatFieldList: 'api/exportformatfieldlist/',
  apiExportFormatInstance: 'api/exportformatinstance/',
  apiExportFormatList: 'api/exportformatlist/',
  apiExportSampleData: 'api/exportsampledata/',
  apiExportDateFormatList: 'api/dateformatlist/',

  apiBankBranchNameList: 'api/bankbranchnameList/',
  apiGetIpString: 'api/getip/',

  apiSavePayDataOfInvoice: 'api/savepaydataofinvoice',
  apiLineAuth: 'api/linetest',

  apiBoardMessages: 'api/board-messages',
  apiBoardMessageInstance: 'api/board-messages/<id>',

  urlHost: process.env.VUE_APP_REDIRECT_URL,
}

const path = (v) => state.baseUrl + v

const getters = {
  getEnvType: (state) => {
    return state.envType
  },
  getBaseUrl: (state) => {
    return state.baseUrl
  },
  apiFreeeAuthorizationUrl: (state) => state.baseUrl + 'freee/authorization',
  apiFreeeDealsUrl: (state) => state.baseUrl + 'api/freee/deals',
  apiFreeeMasterUrl: (state) => state.baseUrl + 'api/freee/masters',
  apiFreeeCompaniesUrl: (state) => state.baseUrl + 'freee/companies',
  apiFreeeTokenUrl: (state) => state.baseUrl + 'freee/token',
  apiFreeeCompanyUrl: (state) => state.baseUrl + 'freee/companies/select',
  apiInvoiceSearchUrl: (state) => {
    return state.baseUrl + 'api/invoicesearch/'
  },
  apiInvoiceSummaryJournalUrl: (state) => {
    return state.baseUrl + 'api/invoicesummaryjournal/'
  },
  apiOrganizationEmailUrl: (state) => {
    return state.baseUrl + 'api/organizationemail/'
  },
  apiOrganizationJournalFieldsUrl: (state) => {
    return state.baseUrl + 'api/organizationjournalfields/'
  },
  apiResportSettingUrl: (state) => {
    return state.baseUrl + 'api/resportsetting/'
  },
  apiDefaultJournalUrl: (state) => {
    return state.baseUrl + state.apiDefaultJournalUrl
  },
  apiUserBankInstance: (state) => {
    return state.baseUrl + 'api/userbankinstance/'
  },
  apiEmailUploadSetting: (state) => {
    return state.baseUrl + 'api/emailuploadsetting/'
  },
  apiTagImportCSVUrl: (state) => {
    return state.baseUrl + 'api/tagimportcsv/'
  },
  apiTagSampleCSVSheetUrl: (state) => {
    return state.baseUrl + 'api/tagsamplecsvsheet/'
  },
  apiGetRestrictedIpUrl: (state) => {
    return state.baseUrl + state.apiGetRestrictedIpUrl
  },
  apiCheckUserInviteUrl: (state) => {
    return state.baseUrl + 'api/checkuserinvite/'
  },
  apiInvoiceMultiChangeDateFunction: (state) => {
    const url = state.baseUrl + state.apiInvoiceMultiChangeDateString
    return url
  },
  apiInvoiceMultiDeleteFunction: (state) => {
    const url = state.baseUrl + state.apiInvoiceMultiDeleteString
    return url
  },
  apiInvoiceMultiApproveFunction: (state) => {
    const url = state.baseUrl + state.apiInvoiceMultiApproveString
    return url
  },
  apiInvoiceCancelApproveUrl: (state) => {
    const url = state.baseUrl + state.apiInvoiceCancelApproveUrl
    return url
  },
  apiInvoicePayCancelApproveUrl: (state) => {
    const url = state.baseUrl + state.apiInvoicePayCancelApproveUrl
    return url
  },
  apiInvoiceConvertName: (state) => {
    const url = state.baseUrl + state.apiInvoiceConvertComanyName
    return url
  },
  apiLoginFunction: (state) => {
    const url = state.baseUrl + state.apiLoginFunctionString
    return url
  },
  apiSignUpFunction: (state) => {
    const url = state.baseUrl + state.apiSignUpFunctionString
    return url
  },
  apiSignUpCheckCodeFunction: (state) => {
    const url = state.baseUrl + state.apiSignUpCheckCodeString
    return url
  },
  apiConfirmSignUpFunction: (state) => {
    const url = state.baseUrl + state.apiConfirmSignUpString
    return url
  },
  apiEmailResetFunction: (state) => {
    const url = state.baseUrl + 'api/emailresetinstanse/'
    return url
  },
  apiForgotPasswordFunction: (state) => {
    const url = state.baseUrl + state.apiForgotPasswordString
    return url
  },
  apiUserInviteFunction: (state) => {
    const url = state.baseUrl + state.apiUserInviteString
    return url
  },
  apiUserListFunction: (state) => {
    const url = state.baseUrl + state.apiUserListString
    return url
  },
  apiUserInviteAccceptFunction: (state) => {
    const url = state.baseUrl + state.apiUserInviteAccceptString
    return url
  },
  apiUserSessionFunction: (state) => {
    return state.baseUrl + 'api/usersession/'
  },
  apiUserDeleteFunction: (state) => {
    return state.baseUrl + 'api/userdelete/'
  },
  apiPasswordInstanceFunction: (state) => {
    const url = state.baseUrl + state.apiPasswordInstanceString
    return url
  },
  apiCompanyListFunction: (state) => {
    const url = state.baseUrl + state.apiCompanyListString
    return url
  },
  apiCompanyExportFunction: (state) => {
    return state.baseUrl + 'api/companyexport/'
  },
  apiCompanyEmailFunction: (state) => {
    return state.baseUrl + state.apiCustomerEmailString
  },
  apiCustomerEmailFunction: (state) => {
    return state.baseUrl + state.apiCustomerEmailString
  },
  apiCustomerEmailsFunction: (state) => {
    return state.baseUrl + state.apiCustomerEmailsString
  },
  apiCustomerEmailExamFunction: (state) => {
    return state.baseUrl + state.apiCustomerEmailExamString
  },
  apiTempUploadFunction: (state) => {
    return state.baseUrl + state.apiTempUploadString
  },
  apiTempUploadInfoFunction: (state) => {
    return state.baseUrl + state.apiTempUploadInfoString
  },
  apiPasswordInstanceFunction: (state) => {
    const url = state.baseUrl + state.apiPasswordInstanceString
    return url
  },
  apiUserInstanceFunction: (state) => {
    const url = state.baseUrl + state.apiUserInstanceString
    return url
  },
  apiCustomersFunction: (state) => {
    const url = state.baseUrl + state.apiCustomersString
    return url
  },
  apiCustomersCsvFunction: (state) => {
    const url = state.baseUrl + state.apiCustomerCsvString
    return url
  },
  apiCustomerTableFunction: (state) => {
    const url = state.baseUrl + state.apiCustomerTableString
    return url
  },
  apiInboxImageFunction: (state) => {
    const url = state.baseUrl + state.apiInboxImageString
    return url
  },
  apiInboxItemsFunction: (state) => {
    const url = state.baseUrl + state.apiInboxItemsString
    return url
  },
  apiInboxUploadFunction: (state) => {
    const url = state.baseUrl + state.apiInboxUploadString
    return url
  },
  apiInvoiceListFunction: (state) => {
    const url = state.baseUrl + state.apiInvoicelistString
    return url
  },
  apiInvoiceExportFunction: (state) => {
    const url = state.baseUrl + state.apiInvoiceExportString
    return url
  },
  apiCheckExportResportFunction: (state) => {
    return state.baseUrl + 'api/checkexportresport/'
  },
  apiExportResportFunction: (state) => {
    return state.baseUrl + 'api/exportresport/'
  },
  apiInvoiceInstanceFunction: (state) => {
    const url = state.baseUrl + state.apiInvoiceInstanceString
    return url
  },
  apiInvoiceRestoreFunction: (state) => {
    const url = state.baseUrl + state.apiInvoiceRestoreString
    return url
  },
  apiInvoiceCopyFunction: (state) => {
    const url = state.baseUrl + state.apiInvoiceCopyString
    return url
  },
  apiInvoiceStatusUrl: (state) => {
    return state.baseUrl + 'api/invoicestatus/'
  },
  apiInvoiceViewedUrl: (state) => {
    const url = state.baseUrl + 'api/invoiceviewed/'
    return url
  },
  apiInvoiceConfirmFunction: (state) => {
    const url = state.baseUrl + state.apiInvoiceConfirmString
    return url
  },
  apiInvoiceApproveFunction: (state) => {
    const url = state.baseUrl + state.apiInvoiceApproveString
    return url
  },
  apiInvoiceNoteUrl: (state) => {
    return state.baseUrl + 'api/invoice-note/'
  },
  apiInvoicePayConfirmFunction: (state) => {
    const url = state.baseUrl + state.apiInvoicePayConfirmString
    return url
  },
  apiInvoicePayApproveFunction: (state) => {
    const url = state.baseUrl + state.apiInvoicePayApproveString
    return url
  },
  apiInvoicePaid: (state) => {
    return state.baseUrl + 'api/invoicepaid/'
  },
  apiInvoiceCheckExportFunction: (state) => {
    const url = state.baseUrl + state.apiInvoiceCheckExportString
    return url
  },
  apiInvoicePayJournalBulkCreate: (state) => {
    return state.baseUrl + 'api/invoicepayjournalbulkcreate/'
  },
  getCheckHost: (state) => {
    return state.urlHost
  },
  apiInvoiceImageUrl: (state) => {
    return state.baseUrl + state.apiInvoiceImageUrl
  },
  apiExportFormatFieldListUrl: (state) => {
    return state.baseUrl + state.apiExportFormatFieldList
  },
  apiExportTypeListUrl: (state) => {
    return state.baseUrl + state.apiExportTypeList
  },
  apiExportFormatInstanceUrl: (state) => {
    return state.baseUrl + state.apiExportFormatInstance
  },
  apiExportFormatListUrl: (state) => {
    return state.baseUrl + state.apiExportFormatList
  },
  apiExportDateFormatListUrl: (state) => {
    return state.baseUrl + state.apiExportDateFormatList
  },
  apiExportSampleDataUrl: (state) => {
    return state.baseUrl + state.apiExportSampleData
  },
  apiHiddenExportFormatUrl: () => path('api/export/hidden-format/'),
  apiMuitipleUpdateInvoiceUrl: (state) => {
    return state.baseUrl + 'api/multipleupdateinvoice/'
  },
  apiInvoiceEditUser: (state) => {
    return state.baseUrl + state.apiInoiceEditUserString
  },
  apiTimeStampPDF: (state) => {
    return state.baseUrl + state.apiTimeStampPDFString
  },
  apiFilterPatternsUrl: (state) => {
    return state.baseUrl + state.apiFilterPatternsString
  },
  apiHiddenFilterPatternsUrl: (state) => {
    return state.baseUrl + state.apiHiddenFilterPatternsString
  },
  apiRecentUseListUrl: (state) => {
    return state.baseUrl + state.apiRecentUseListString
  },
  oauth2AuthorizeUrl: (state) => {
    return state.baseUrl + 'oauth2/authorize'
  },
  oauth2TokenUrl: (state) => {
    return state.baseUrl + 'oauth2/token/'
  },
  apiInvoicePayMultiApproveFunction: (state) => {
    return state.baseUrl + 'api/invoicepaymultiapprove/'
  },
  apiPaymentStatusUrl: (state) => {
    return state.baseUrl + 'api/paymentstatus/'
  },
  apiPaymentListFunction: (state) => {
    return state.baseUrl + state.apiPaymentlistString
  },
  apiPaymentDetailFunction: (state) => {
    return state.baseUrl + state.apiPaymentDetailString
  },
  apiPaymentImageUrl: (state) => {
    return state.baseUrl + state.apiPaymentImageUrl
  },
  apiPaymentCheckExportFunction: (state) => {
    return state.baseUrl + state.apiPaymentCheckExportString
  },
  apiPaymentCheckZenginExportFunction: (state) => {
    return state.baseUrl + state.apiPaymentCheckZenginExportString
  },
  apiPaymentExportFunction: (state) => {
    return state.baseUrl + state.apiPaymentExportString
  },
  apiPaymentZenginExportFunction: (state) => {
    return state.baseUrl + state.apiPaymentZenginExportString
  },
  apiPaymentCheckCreateFunction: (state) => {
    return state.baseUrl + state.apiPaymentCheckCreateString
  },
  apiPaymentConfirmFunction: (state) => {
    return state.baseUrl + state.apiPaymentConfirmString
  },
  apiMultiUpdatePayDate: (state) => {
    return state.baseUrl + 'api/multiupdatepaydate/'
  },
  apiMultiUpdatePaid: (state) => {
    return state.baseUrl + 'api/multiupdatepaid/'
  },
  apiMultiCancelPayApprove: (state) => {
    return state.baseUrl + 'api/multicancelpayapprove/'
  },
  apiGetPaymentDetailsList: (state) => {
    return state.baseUrl + state.apiPaymentDetailsListString
  },
  apiWalletableImportCSVUrl: (state) => {
    return state.baseUrl + 'api/walletableimportcsv/'
  },
  apiWalletableSampleCSVSheetUrl: (state) => {
    return state.baseUrl + 'api/walletablesamplecsvsheet/'
  },
  apiGetApplicationInstanceUrl: (state) => {
    return state.baseUrl + 'api/applicationinstance/'
  },
  apiApplicationListUrl: (state) => {
    return state.baseUrl + 'api/applicationlist/'
  },
  apiGetBankBranchNameList: (state) => {
    return state.baseUrl + state.apiBankBranchNameList
  },
  apiBankData: (state) => {
    return state.baseUrl + 'api/bankdata/'
  },
  apiJournalItems: (state) => {
    return state.baseUrl + 'api/journalitems/'
  },
  apiSavePayDataOfInvoice: (state) => {
    return state.baseUrl + state.apiSavePayDataOfInvoice
  },
  apiCheckImport: (state) => {
    return state.baseUrl + 'api/checkimport/'
  },
  apiImportMaster: (state) => {
    return state.baseUrl + 'api/importmaster/'
  },
  apiExportMaster: (state) => {
    return state.baseUrl + 'api/exportmaster/'
  },
  apiSortMaster: (state) => {
    return state.baseUrl + 'api/sortmaster/'
  },
  apiGetMaster: (state) => {
    return state.baseUrl + 'api/getmaster/'
  },
  apiGetUserOrganizations: (state) => {
    return state.baseUrl + 'api/getuserorganizations/'
  },
  apiSignInAnother: (state) => {
    return state.baseUrl + 'api/signinanother/'
  },
  apiConfirmSignInAnother: (state) => {
    return state.baseUrl + 'api/confirmsigninanother/'
  },
  apiSignOut: (state) => {
    return state.baseUrl + 'api/signout/'
  },
  apiLineAuth: (state) => {
    return state.baseUrl + 'api/linecheck'
  },
  apiSlackAuth: (state) => {
    return state.baseUrl + 'api/slackcheck'
  },
  apiSlackChannels: (state) => {
    return state.baseUrl + 'api/slackchannels'
  },
  apiChatworkAuth: (state) => {
    return state.baseUrl + 'api/chatworkcheck'
  },
  apiGdriveJWT: (state) => {
    return state.baseUrl + 'gdrive/jwt'
  },
  apiIssueTaxFormUrl: (state) => {
    return state.baseUrl + 'api/issuetaxform'
  },
  apiInvoiceUploadUrl: (state) => {
    return state.baseUrl + 'api/invoiceupload/'
  },
  apiInvoiceUploadHistoryUrl: (state) => {
    return state.baseUrl + 'api/invoiceuploadhistory/'
  },
  apiNotificationUrl: (state) => {
    return state.baseUrl + 'api/notification/'
  },
  apiNotificationDropUrl: (state) => {
    return state.baseUrl + 'api/notification-drop'
  },
  apiIpRestrictionLogInstanceUrl: (state) => {
    return state.baseUrl + 'api/iprestrictionloginstance/'
  },
  apiSettingCompanyItem: (state) => {
    return state.baseUrl + 'api/set-companyitem'
  },
  apiInvitationBulkCreate: (state) => {
    return state.baseUrl + 'api/userinvitationbulkcreate/'
  },
  apiInvoicePDFUrl: (state) => {
    return state.baseUrl + 'api/pdfurl'
  },
  FileInvoicePDFUrl: (state) => {
    return state.baseUrl + 'pdf/'
  },
  apiGetIpUrl: (state) => {
    return state.baseUrl + 'api/getip/'
  },
  apiBoardMessages: (state) => {
    return state.baseUrl + state.apiBoardMessages
  },
  apiBoardMessageInstance: (state) => {
    return state.baseUrl + state.apiBoardMessageInstance
  },

  apiRolesUrl: () => path('api/roles'),
  apiNewRoleUrl: () => path('api/roles/new'),
  apiCopyRoleUrl: () => path('api/roles/copy'),
  apiRoleUrl: () => path('api/roles/<id>'),
  apiAbilitiesUrl: () => path('api/roles/<id>/abilities'),
  apiInvoiceDepartment: () => path('api/invoicedepartment'),
  apiActionLogsUrl: () => path('api/actionlogs'),
  apiInboxDepartmentsUrl: () => path('inbox-departments'),
  apiInboxEmailTemplatesUrl: () => path('api/inboxemailtemplates/'),
  apiNextApprovalRequestsUrl: () =>
    path('inbox-items/<inbox_id>/next-approval'),
  apiCustomersSortsUrl: () => path('customers/<customer_id>/sorts'),

  //master
  apiAccountTitleUrl: () => path('api/accounttitle'),
  apiSupplementaryUrl: () => path('api/supplementary'),
  apiDepartmentUrl: () => path('api/department'),
  apiProjectUrl: () => path('api/project'),
  apiSegmentUrl: () => path('api/segment'),
  apiVendorUrl: () => path('api/vendor'),
  apiItemUrl: () => path('api/item'),
  apiTagUrl: () => path('api/tag'),
  apiWalletableUrl: () => path('api/walletable'),
  apiTaxclassUrl: () => path('api/taxclass'),

  apiAccountTitlesUrl: () => path('api/accounttitles'),
  apiSupplementariesUrl: () => path('api/supplementaries'),
  apiDepartmentsUrl: () => path('api/departments'),
  apiProjectsUrl: () => path('api/projects'),
  apiSegmentsUrl: () => path('api/segments'),
  apiVendorsUrl: () => path('api/vendors'),
  apiItemsUrl: () => path('api/items'),
  apiTagsUrl: () => path('api/tags'),
  apiWalletablesUrl: () => path('api/walletables'),
  apiTaxclassesUrl: () => path('api/taxclasses'),

  apiTimeStampValidationsUrl: () => path('api/time-stamp-validations'),
  apiNextApprovalUrl: () => path('next-approval-email'),
  apiIpRestrictionsUrl: () => path('ip-restrictions'),
  apiIpRestrictionInstanceUrl: () => path('ip-restrictions/<id>'),
}

export default {
  state,
  getters,
}
